import * as requestParamTypes from '../../../../consts/app/requestParamTypes';
import DeviceGroupingTypes from '../../../../consts/devices/deviceGroupingTypes.ts';
import DeviceTypes from '../../../../consts/devices/devicesTypes.ts';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import {groupingTypeLocalizations} from '../../../../consts/localization/groupingTypeLocalizations';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../consts/modals/modalTypes';
import buildingsService from '../../../../services/buildings/buildingsService';
import deviceService from '../../../../services/device/deviceService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import {cleanDeviceModels} from '../../../../state/ducks/device';
import {makeSelectBuildingDataCollectors, makeSelectRooms} from '../../../../state/selectors/buildings';
import {
    makeSelectDeviceInfo,
    makeSelectDeviceModels,
    makeSelectDeviceProfileTypes,
    makeSelectDevices,
    makeSelectDeviceTypes,
} from '../../../../state/selectors/device';
import {dispatch, getState} from '../../../../state/store';
import helpers from '../../../../utils/helpers';

const getFormContent = (modalType, buildingID, propRoomID, isEditing) => {
    const deviceInfo = makeSelectDeviceInfo()(getState());

    const devices = makeSelectDevices()(getState());

    const isAddDeviceModalType = modalTypes.ADD_DEVICE_MODAL_TYPE == modalType;

    const {
        manualID,
        factoryID,
        waterDeviceID,
        groups,
        groupID,
        groupingType,
        parentID,
        serviceDate,
        uspdID,
        deviceID,
        modelID,
        roomName,
        uspdName,
        parentName,
        roomID,
        models,
    } = deviceInfo || {};

    const localizedStrings = getLocalizedStrings();

    dispatch(cleanDeviceModels());

    const title = isAddDeviceModalType
        ? localizedStrings[localizationKeys.INDICATOR_MODAL_ADD_FORM_TITLE]
        : localizedStrings[localizationKeys.INDICATOR_MODAL_EDIT_FORM_TITLE];

    const submitButton = isAddDeviceModalType
        ? localizedStrings[localizationKeys.INDICATOR_MODAL_ADD_FORM_SUBMIT_BUTTON]
        : localizedStrings[localizationKeys.INDICATOR_MODAL_EDIT_FORM_SUBMIT_BUTTON];

    const cancelButton = localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_CANCEL_BUTTON];

    const groupingTypes = [
        {
            value: groupingTypeLocalizations[DeviceGroupingTypes[DeviceGroupingTypes.Grouped]],
            key: DeviceGroupingTypes.Grouped,
        },
        {
            value: groupingTypeLocalizations[DeviceGroupingTypes[DeviceGroupingTypes.Common]],
            key: DeviceGroupingTypes.Common,
        },
        {
            value: groupingTypeLocalizations[DeviceGroupingTypes[DeviceGroupingTypes.Distributed]],
            key: DeviceGroupingTypes.Distributed,
        },
    ];

    const getInitialValue = (arr, findKey) => arr?.find((item) => item?.key == findKey)?.value;

    const initialDeviceTypeGroupsValue = getInitialValue(groups, groupID);
    const initialGroupingTypeValue = getInitialValue(groupingTypes, groupingType);

    const modelName = models?.find((e) => e.key === modelID)?.value;

    const roomId = roomID || propRoomID;

    const initialValues = {
        [formInputNames.BUILDING_ID]: buildingID,
        [formInputNames.MANUAL_ID]: manualID,
        [formInputNames.FACTORY_ID]: factoryID,
        [formInputNames.WATER_DEVICE_ID]: waterDeviceID,
        [formInputNames.DEVICE_TYPE_ID]: groupID &&
            initialDeviceTypeGroupsValue && {
                value: initialDeviceTypeGroupsValue,
                id: groupID,
            },
        [formInputNames.GROUPING_TYPE]:
            propRoomID && !isEditing
                ? {
                      value: getInitialValue(groupingTypes, DeviceGroupingTypes.Distributed),
                      id: DeviceGroupingTypes.Distributed,
                  }
                : groupingType && {
                      value: initialGroupingTypeValue,
                      id: groupingType,
                  },
        [formInputNames.PARENT_DEVICE_ID]: parentID && {
            value: parentName,
            id: parentID,
        },
        [formInputNames.SERVICE_DATE]: serviceDate,
        [formInputNames.ROOM_ID]:
            propRoomID && !isEditing
                ? roomId
                : roomId && {
                      value: roomName,
                      id: roomId,
                  },
        [formInputNames.PZPD_ID]: uspdID && {
            value: uspdName || '',
            id: uspdID,
        },
        [formInputNames.DEVICE_MODEL_ID]: modelID &&
            modelName && {
                value: modelName,
                id: modelID,
            },
        deviceID,
    };

    const requireDeps = formInputNames.GROUPING_TYPE;

    const formFields = [
        {
            key: helpers.guid(),
            name: formInputNames.BUILDING_ID,
            required: false,
            hidden: true,
        },
        {
            key: helpers.guid(),
            name: formInputNames.MANUAL_ID,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_PLACEHOLDER],
            required: true,
            pattern: /^[0-9]{8}$/,
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            feedbackContent: [localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_FEEDBACK_RULE_1]],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SERVICE_DATE,
            withDisabledDate: false,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_SERVICE_DATE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_SERVICE_DATE_FIELD_PLACEHOLDER],
            formItemType: formItemTypes.DATE_PICKER,
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_TYPE_ID,
            fieldLabel: 'value',
            fieldValue: 'key',
            formItemType: formItemTypes.SELECT,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_DEVICE_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_DEVICE_TYPE_FIELD_PLACEHOLDER],
            required: true,
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: isAddDeviceModalType ? makeSelectDeviceTypes : makeSelectDeviceProfileTypes,
                getRequestData: () => deviceService.getDeviceTypesGroups(),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_MODEL_ID,
            fieldLabel: 'value',
            fieldValue: 'key',
            formItemType: formItemTypes.SELECT,
            required: true,
            disabled: (_, {typeId} = {}) => !typeId,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_DEVICE_MODEL_ID_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_DEVICE_MODEL_ID_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectDeviceModels,
                getRequestData: () => deviceService.getDevicesModelsByGroupID(groupID),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.GROUPING_TYPE,
            fieldLabel: 'value',
            fieldValue: 'key',
            disabled: propRoomID && !isEditing,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_GROUPING_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_GROUPING_TYPE_FIELD_PLACEHOLDER],
            formItemType: formItemTypes.SELECT,
            required: true,
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            requestOptions: {
                options: groupingTypes,
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.PZPD_ID,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            fieldValue: 'value',
            itemDeps: formInputNames.GROUPING_TYPE,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_PZPD_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_PZPD_FIELD_PLACEHOLDER],
            required: true,
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectBuildingDataCollectors,
                getRequestData: () => buildingsService.getDataCollectorByBuildingId(buildingID),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.PARENT_DEVICE_ID,
            allowClear: true,
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_PARENT_DEVICE_ID_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_PARENT_DEVICE_ID_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            formItemType: formItemTypes.SELECT,
            itemDeps: formInputNames.PZPD_ID,
            fieldValue: 'value',
            fieldLabel: 'value',
            disabled: (groupingType, dataCollector) => {
                const isCommonDevice = groupingType?.id === DeviceGroupingTypes.Common;
                const shouldDisable =
                    !dataCollector || (isCommonDevice && (isAddDeviceModalType || isEditing || !parentID));

                return shouldDisable;
            },
            requestOptions: {
                options: devices?.items.filter((device) => device.key !== parentID),
                selectRequestData: makeSelectDevices,
                getRequestData: () =>
                    deviceService.getDevices({buildingId: buildingID, dataCollectorId: uspdID, groupingType}),
            },
        },
        propRoomID && !isEditing
            ? {
                  key: helpers.guid(),
                  name: formInputNames.ROOM_ID,
                  required: false,
                  hidden: true,
              }
            : {
                  key: helpers.guid(),
                  name: formInputNames.ROOM_ID,
                  required: (_, groupingType) => groupingType?.id === DeviceGroupingTypes.Distributed,
                  fieldLabel: 'value',
                  fieldValue: 'value',
                  withSearch: true,
                  allowClear: true,
                  formItemType: formItemTypes.SELECT,
                  itemDeps: formInputNames.GROUPING_TYPE,
                  disabled: (_, groupingType) => groupingType?.id !== DeviceGroupingTypes.Distributed,
                  label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_ROOM_ID_FIELD_LABEL],
                  placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_ROOM_ID_FIELD_PLACEHOLDER],
                  errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
                  requestOptions: {
                      paramsType: requestParamTypes.ROOMS,
                      selectRequestData: makeSelectRooms,
                      getRequestData: () => buildingsService.getRoomsByBuildingId(buildingID),
                  },
              },
        {
            key: helpers.guid(),
            name: formInputNames.FACTORY_ID,
            itemDeps: formInputNames.DEVICE_TYPE_ID,
            hidden: (_, deviceType) =>
                deviceType?.id !== DeviceTypes.HotWater &&
                deviceType?.id !== DeviceTypes.ColdWater &&
                deviceType?.id !== DeviceTypes.Water &&
                deviceType?.id !== DeviceTypes.Sewage,

            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_FACTORY_ID_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_FACTORY_ID_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.WATER_DEVICE_ID,
            itemDeps: formInputNames.DEVICE_TYPE_ID,
            hidden: (_, deviceType) => {
                return (
                    deviceType?.id !== DeviceTypes.HotWater &&
                    deviceType?.id !== DeviceTypes.ColdWater &&
                    deviceType?.id !== DeviceTypes.Water &&
                    deviceType?.id !== DeviceTypes.Sewage
                );
            },
            label: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_ADDITIONAL_MANUAL_ID_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.INDICATOR_MODAL_FORM_ADDITIONAL_MANUAL_ID_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
    ];

    return {formFields, title, submitButton, cancelButton, initialValues, requireDeps};
};

export default {
    getFormContent,
};
