import {useSelector} from 'react-redux';

import * as requestParamTypes from '../../../../consts/app/requestParamTypes.js';
import dataCollectorHandlers from '../../../../consts/dataCollector/dataCollectorHandlers.ts';
import dataCollectorStatuses from '../../../../consts/dataCollector/dataCollectorStatuses.ts';
import * as formInputNames from '../../../../consts/form/formInputNames.js';
import * as formItemTypes from '../../../../consts/form/formItemTypes.js';
import * as localizationKeys from '../../../../consts/localization/localizationKeys.js';
import * as modalTypes from '../../../../consts/modals/modalTypes.js';
import buildingsService from '../../../../services/buildings/buildingsService.js';
import {getLocalizedStrings} from '../../../../services/localization/localizationService.js';
import organizationsService from '../../../../services/organizations/organizationsService.js';
import settingsService from '../../../../services/settings/settingsService.js';
import {makeSelectBuildingProfile, makeSelectBuildingsData} from '../../../../state/selectors/buildings.js';
import {makeSelectCurrentDataCollector} from '../../../../state/selectors/dataCollectors.js';
import {makeSelectCities, makeSelectOrganizationsNames} from '../../../../state/selectors/organizations.js';
import {makeSelectDataCollectorModels} from '../../../../state/selectors/settings.js';
import {getState} from '../../../../state/store.js';
import helpers from '../../../../utils/helpers.js';
import regexUtils from '../../../../utils/regexUtils.js';

const getFormContent = (modalType) => {
    const localizedStrings = getLocalizedStrings();
    const currentDataCollector = useSelector(makeSelectCurrentDataCollector());

    const buildingProfile = useSelector(makeSelectBuildingProfile()) || {};
    const {modems} = buildingProfile;
    const {items} = modems || {};

    const {
        protocolSettings,
        smartSettings,
        typeId,
        buildingID,
        buildingAddress,
        isDataTransferAllowed,
        cityID,
        city,
        organizationID,
        organization,
    } = currentDataCollector || {};

    const isEdit = modalType === modalTypes.EDIT_MODEMS_TAB_MODAL;

    const submitButton = localizedStrings[localizationKeys.ROOM_FORM_EDIT_SUBMIT_BTN];

    const cancelButton = localizedStrings[localizationKeys.ROOM_FORM_CANCEL_BTN];

    const title = localizedStrings[localizationKeys.DC_PAGE_FORM_TITLE_EDIT];

    let typeValue = '';

    if (items && typeId !== undefined) {
        const matchedItem = items.find((item) => item.modelID === typeId);

        if (matchedItem) {
            typeValue = matchedItem.model;
        }
    }

    let initialValues = {};

    switch (modalType) {
        case modalTypes.COPY_MODEMS_TAB_MODAL:
        case modalTypes.EDIT_MODEMS_TAB_MODAL: {
            initialValues = {
                ...currentDataCollector,
                ...protocolSettings,
                ...smartSettings,
                isDataTransferAllowed: isDataTransferAllowed?.toString(),
                typeId: {
                    key: typeId,
                    value: typeValue,
                },
                buildingId: {
                    key: buildingID,
                    value: buildingAddress,
                },
                city: {
                    key: cityID,
                    value: city,
                },
                organization: {
                    key: organizationID,
                    value: organization,
                },
                format: {
                    key: 1,
                    value: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_FORMAT],
                },
            };
            break;
        }

        case modalTypes.ADD_DATA_COLLECTOR_TO_BUILDING_MODAL: {
            const buildingProfile = makeSelectBuildingProfile()(getState());

            initialValues = {
                buildingId: {
                    key: buildingProfile.buildingID,
                    value: buildingProfile.address,
                },
                organization: {
                    key: buildingProfile.organizationID,
                    value: buildingProfile.organizationName,
                },
            };
            break;
        }
    }

    const formFields = [
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.NAME,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_NAME],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_NAME],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.DEVICE_NUMBER,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_NUMBER],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_NUMBER],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            hidden: !isEdit,
            formItemType: formItemTypes.RADIO,
            name: formInputNames.STATUS,
            label: localizedStrings[localizationKeys.DC_PAGE_STATUS],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_RADIO_REQUIRED_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_STATUS_ACTIVE],
                    value: dataCollectorStatuses.Active,
                },
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_STATUS_BLOCKED],
                    value: dataCollectorStatuses.Blocked,
                },
            ],
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            name: formInputNames.CITY,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_CITY],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_CITY],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'value',
            fieldValue: 'value',
            name: formInputNames.ORGANIZATION,
            itemDeps: formInputNames.CITY,
            disabled: (_, cityId) => !cityId,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_COMPANY],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_COMPANY],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.BUILDING_ID,
            itemDeps: formInputNames.ORGANIZATION,
            disabled: (_, organizationID) => !organizationID,
            fieldValue: 'value',
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_BUILDING],
            withSearch: true,
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_BUILDING],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
            formItemType: formItemTypes.SELECT,
            requestOptions: {
                paramsType: requestParamTypes.BUILDINGS,
                selectRequestData: makeSelectBuildingsData,
                getRequestData: () => buildingsService.getBuildingsListByOrganizationId(organizationID),
            },
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.SELECT,
            name: formInputNames.DC_TYPE_ID,
            fieldLabel: 'value',
            fieldValue: 'value',
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_DC_TYPE],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_DC_TYPE],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectDataCollectorModels,
                getRequestData: () => settingsService.getDataCollectorModels(),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.IP_ADDRESS,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_IP_ADDRESS],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_IP_ADDRESS],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SIM_NUMBER,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_SIM_NUMBER],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_SIM_NUMBER],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_SIM_NUMBER_ERROR],
            pattern: regexUtils.PHONE_REGEX_PLUS,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.IS_DATA_TRANSFER_ALLOWED,
            formItemType: formItemTypes.RADIO,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_DATA_TRANSFER],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_RADIO_REQUIRED_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_VALUE_DATA_TRANSFER_ENABLED],
                    value: 'true',
                },
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_VALUE_DATA_TRANSFER_DISABLED],
                    value: 'false',
                },
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.HANDLER,
            formItemType: formItemTypes.RADIO,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_HANDLER_TYPE],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_RADIO_REQUIRED_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_HANDLER_TYPE_SMART],
                    value: 1,
                },
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_HANDLER_TYPE_PROTOCOL],
                    value: 2,
                },
            ],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE,
            formItemType: formItemTypes.RADIO,
            itemDeps: formInputNames.HANDLER,
            disabled: (_, handlerId) => handlerId !== dataCollectorHandlers.Smart,
            required: (_, handlerId) => handlerId == dataCollectorHandlers.Smart,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_PROTOCOL],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_RADIO_REQUIRED_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_FORM_PROTOCOL_FTP],
                    value: 1,
                },
                {
                    name: localizedStrings[localizationKeys.DC_PAGE_FORM_PROTOCOL_EMAIL],
                    value: 2,
                },
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.SERVER,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_FTP_ADDRESS],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_FTP_ADDRESS],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.FOLDER,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_FOLDER_PATH],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_FOLDER_PATH],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LOGIN,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_LOGIN],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_LOGIN],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.PASSWORD,
            name: formInputNames.PASSWORD,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_PASSWORD],
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_PASSWORD],
            errorMessage: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.FORMAT,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            fieldValue: 'key',
            hidden: true,
            label: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_LABEL_FORMAT],
            disabled: true,
            placeholder: localizedStrings[localizationKeys.DC_PAGE_FORM_FIELD_PLACEHOLDER_FORMAT],
        },
    ];

    return {formFields, title, submitButton, cancelButton, initialValues};
};

export default {
    getFormContent,
};
