import * as apiMethods from '../../consts/api/apiMethods';
import * as requestParamTypes from '../../consts/app/requestParamTypes';
import {
    setArsBuildingsList,
    setArsOrganizationsData,
    setArsOrganizationSettings,
    setArsOrganizationStatistics,
    setArsStatus,
    setArsUnappliedOrganizationsNames,
} from '../../state/ducks/services';
import {makeSelectRequestParams} from '../../state/selectors/requestParams';
import {dispatch, getState} from '../../state/store';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {responsibilitiesListRequestDataMapping} from '../mappings/responsibilitiesMapping';
import {
    addOrganizationToArsRequestPostMapping,
    arsUnappliedOrganizationNamesRequestDataMapping,
    editOrganizationToArsRequestPostMapping,
} from '../mappings/servicesMapping.js/serviceArsMappings';

const getArsUnappliedOrganizationsNames = async (cityId) => {
    try {
        const options = {
            methodName: apiMethods.SERVICES_ARS_BUILDINGS_LIST_UNAPPLIED,
            action: setArsUnappliedOrganizationsNames,
            mapper: arsUnappliedOrganizationNamesRequestDataMapping,
        };

        if (cityId) {
            options.requestConfig = {
                params: {CityID: cityId},
            };
        }

        return await new ApiClient().callGet(options);
    } catch (e) {
        log.error(`getOrganizationsNames: ${e}; removing organizationsNames from state`);
        dispatch(setArsUnappliedOrganizationsNames(null));
    }
};

const getArsBuildingsListByOrganizationId = async (organizationID, onRequestSuccess) => {
    try {
        return await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_ARS_BUILDINGS_ORGANIZATION_ID,
            action: setArsBuildingsList,
            requestConfig: {
                args: organizationID,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getArsBuildingsListByOrganizationId: ${e}`);
        // helpers.runFunction(onRequestError);
    }
};

const editArsBuildingsSettingsByOrganizationId = async ({id, data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.SERVICES_ARS_BUILDINGS_ORGANIZATION_ID,
            requestConfig: {
                args: id,
                data,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`putArsSettingsByOrganizationId: ${e}`);
    }
};

const editArsOrganizationSettingsById = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.SERVICES_ARS,
            requestConfig: {
                data: editOrganizationToArsRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`putArsSettingsByOrganizationId: ${e}`);
    }
};
const getArsOrganizationStatisticsById = async ({id, onRequestSuccess}) => {
    try {
        return await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_ARS_ORGANIZATION_STATISTICS_BY_ID,
            action: setArsOrganizationStatistics,
            requestConfig: {
                args: id,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getArsSettingsByOrganizationId: ${e}`);
        // helpers.runFunction(onRequestError);
    }
};

const getArsOrganizationSettingsById = async ({id, onRequestSuccess}) => {
    try {
        return await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_ARS_ORGANIZATION_SETTINGS_BY_ID,
            action: setArsOrganizationSettings,
            requestConfig: {
                args: id,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getArsSettingsByOrganizationId: ${e}`);
        // helpers.runFunction(onRequestError);
    }
};

const getArsStatus = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_ARS_STATUS,
            action: setArsStatus,
        });
    } catch (e) {
        log.error(`getKteStatus: ${e}`);
    }
};

const turnOffArs = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_ARS_OFF,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOffArs: ${e}`);
    }
};

const turnOnArs = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_ARS_ON,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOnArs: ${e}`);
    }
};

const addOrganizationToArs = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_ARS,
            requestConfig: {
                data: addOrganizationToArsRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`addOrganizationToArs: ${e}`);
    }
};

const getArsOrganizationsList = async (data, onRequestSuccess) => {
    const requestParams = makeSelectRequestParams(requestParamTypes.ARS_ORGANIZATIONS)(getState());

    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_ARS_LIST,
            action: setArsOrganizationsData,
            mapper: responsibilitiesListRequestDataMapping,

            requestConfig: {
                data: requestParams,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getArsOrganizationsList: ${e}`);
    }
};

const turnOffArsOrganization = async ({organizationID, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_ARS_ORGANIZATION_OFF,
            requestConfig: {
                data: {organizationID},
                args: organizationID,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOffArsOrganization: ${e}`);
    }
};

const turnOnArsOrganization = async ({organizationID, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_ARS_ORGANIZATION_ON,
            requestConfig: {
                data: {organizationID},
                args: organizationID,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOnArsOrganization: ${e}`);
    }
};

const deleteArsOrganization = async (organizationID, onRequestSuccess) => {
    try {
        await new ApiClient().callDelete({
            methodName: apiMethods.SERVICES_ARS_DELATE_ORGANIZATION,
            requestConfig: {
                data: {organizationID},
                args: organizationID,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`deleteArsOrganization: ${e}`);
        // helpers.runFunction(onRequestError);
    }
};

export default {
    getArsUnappliedOrganizationsNames,
    turnOffArs,
    turnOnArs,
    addOrganizationToArs,
    getArsOrganizationsList,
    getArsBuildingsListByOrganizationId,
    getArsOrganizationSettingsById,
    turnOffArsOrganization,
    turnOnArsOrganization,
    deleteArsOrganization,
    editArsOrganizationSettingsById,
    editArsBuildingsSettingsByOrganizationId,
    getArsOrganizationStatisticsById,
    getArsStatus,
};
