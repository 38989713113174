import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import AntButton from '../../../components/AntButton/AntButton';
import AntDivider from '../../../components/Divider/Divider';
import ObjectImageCard from '../../../components/DrawerComponents/DrawerObjectHeader/ObjectImageCard';
import DrawerSectionCoverageProgress from '../../../components/DrawerComponents/DrawerSectionCoverageProgress/DrawerSectionCoverageProgress';
import DrawerSectionDataCollector from '../../../components/DrawerComponents/DrawerSectionDataCollector/DrawerSectionDataCollector';
import ObjectUtilitiesDrawerSection from '../../../components/DrawerComponents/DrawerSectionObjectUtilities/ObjectUtilitiesDrawerSection';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import appRouterService from '../../../services/app/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {setBuildingProfile} from '../../../state/ducks/buildings';
import {makeSelectBuildingProfile, makeSelectBuildingProfileRoomsCount} from '../../../state/selectors/buildings';
import {dispatch} from '../../../state/store';

const mapStateToProps = createStructuredSelector({
    buildingProfile: makeSelectBuildingProfile(),
    roomsCount: makeSelectBuildingProfileRoomsCount(),
});

const MapBuildingDrawerContent = ({buildingProfile}) => {
    const {uspds = [], roomsCount = 0, successPercentage, failurePercentage} = buildingProfile || {};

    const efficiencyPercentage = +successPercentage + +failurePercentage;

    const localizedStrings = getLocalizedStrings();

    const forwardToCurrentOrganizationPage = () => {
        appRouterService.forwardToBuildingProfilePage(buildingProfile.buildingID);
    };

    useEffect(() => {
        return () => {
            dispatch(setBuildingProfile(null));
        };
    }, []);

    return (
        <>
            <ObjectImageCard roomsCount={roomsCount} objectData={buildingProfile} />
            <AntDivider />
            <ObjectUtilitiesDrawerSection />
            <AntDivider />
            <DrawerSectionDataCollector uspds={uspds} />
            <AntDivider />
            <DrawerSectionCoverageProgress
                averageStatusPercentage={efficiencyPercentage}
                successPercentage={successPercentage}
            />
            <AntButton
                className={`w-full py-3.5 font-medium text-14 leading-[12px]`}
                onClick={forwardToCurrentOrganizationPage}
                primary
            >
                {localizedStrings[localizationKeys.BUTTON_TEXT_MORE]}
            </AntButton>
        </>
    );
};

export default connect(mapStateToProps)(MapBuildingDrawerContent);
