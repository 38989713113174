import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../components/Form/Form';
import * as formInputNames from '../../../../consts/form/formInputNames';
import buildingsService from '../../../../services/buildings/buildingsService';
import log from '../../../../services/logger/log';
import organizationsService from '../../../../services/organizations/organizationsService';
import {setBuildings, setBuildingsOrganizationId} from '../../../../state/ducks/buildings';
import {setOrganizationsNames} from '../../../../state/ducks/organizations';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import modemTabTableModalService from './modemTabTableModalService';

const ModemTabTableModal = ({modalType, onCancel, onOk}) => {
    const [form] = AntForm.useForm();

    const {formFields, title, submitButton, cancelButton, initialValues} = modemTabTableModalService.getFormContent(
        modalType,
        form
    );

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            form.resetFields();
            dispatch(setBuildings(null));
            dispatch(setOrganizationsNames(null));
        };
    }, []);

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.info('Validate Failed:', error);
        }
    };

    const handleRadioChange = (value, name) => {
        form.setFieldsValue({[name]: value});

        switch (name) {
            case formInputNames.HANDLER: {
                resetFormField([formInputNames.SOURCE]);
                break;
            }
            default:
                break;
        }
    };
    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {key, value}});

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);

        switch (name) {
            case formInputNames.CITY: {
                resetFormField([formInputNames.ORGANIZATION, formInputNames.BUILDING_ID]);
                await organizationsService.getOrganizationsNames(value);
                break;
            }
            case formInputNames.ORGANIZATION: {
                resetFormField([formInputNames.BUILDING_ID]);
                dispatch(setBuildingsOrganizationId(option?.key));

                await buildingsService.getBuildingsListByOrganizationId(option?.key);
                break;
            }
            default:
                break;
        }
    };

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formFields}
            cancelButton={cancelButton}
            formInstance={form}
        />
    );
};

export default React.memo(ModemTabTableModal);
