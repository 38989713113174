import qs from 'qs';

import * as apiMethods from '../../consts/api/apiMethods';
import DeviceGroupingTypes from '../../consts/devices/deviceGroupingTypes.ts';
import {setDeviceTypeGroups} from '../../state/ducks/buildings.js';
import {setDeviceChart, setDeviceInfo, setDeviceModels, setDevices, setDeviceTypes} from '../../state/ducks/device';
import {dispatch} from '../../state/store';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {
    createDeviceRequestDataMapping,
    deviceChartRequestDataMapping,
    editDeviceRequestDataMapping,
} from '../mappings/deviceMappings';

const getDeviceModels = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.DEVICE_MODELS,
        action: setDeviceModels,
    });
};

const getDevicesModelsByGroupID = async (groupID) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.DEVICES_MODELS_BY_GROUP_ID,
            action: setDeviceModels,
            requestConfig: {
                args: groupID,
            },
        });
    } catch (e) {
        dispatch(setDeviceModels([]));
        log.error(`getDevicesModelsByGroupID: ${e}`);
    }
};

const getDeviceTypesGroups = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.DEVICE_TYPES_GROUPS,
        action: setDeviceTypes,
    });
};

const getDeviceTypesGroupsByBuildingId = async (buildingID) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.DEVICE_TYPES_GROUPS_BY_BUILDING_ID,
            action: setDeviceTypeGroups,
            requestConfig: {
                args: buildingID,
            },
        });
    } catch (e) {
        dispatch(setDeviceTypes([]));
        log.error(`getDeviceTypesGroupsByBuildingId: ${e}`);
    }
};

const getDevices = async ({buildingId, groupID, groupingType, dataCollectorId}) => {
    const groupingTypeArray = [];

    const commonDeviceType = DeviceGroupingTypes.Common;
    const groupedDeviceType = DeviceGroupingTypes.Grouped;

    if (groupingType == groupedDeviceType) {
        groupingTypeArray.push(commonDeviceType);
    }

    if (groupingType == DeviceGroupingTypes.Distributed) {
        groupingTypeArray.push(commonDeviceType, groupedDeviceType);
    }

    const queryParams = {
        buildingID: buildingId,
        groupID,
        groupingTypes: groupingTypeArray,
        uspdID: dataCollectorId,
    };

    const queryString = qs.stringify(queryParams, {arrayFormat: 'repeat', encode: false});

    const apiUrl = `${apiMethods.DEVICES}?${queryString}`;

    await new ApiClient().callGet({
        methodName: apiUrl,
        action: setDevices,
    });
};

const createDevice = async (formData, onRequestSuccess) => {
    await new ApiClient().callPost({
        methodName: apiMethods.DEVICES,
        requestConfig: {
            data: createDeviceRequestDataMapping(formData),
        },
        onResponse: onRequestSuccess,
    });
};

const editDevice = async (formData, onRequestSuccess) => {
    await new ApiClient().callPut({
        methodName: apiMethods.DEVICES,
        requestConfig: {
            data: editDeviceRequestDataMapping(formData),
        },
        onResponse: onRequestSuccess,
    });
};

const deleteDevice = async (deviceID, onRequestSuccess) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.DEVICES,
        requestConfig: {
            data: {deviceID},
        },
        onResponse: onRequestSuccess,
    });
};

const getDeviceProfile = async (deviceId, onRequestSuccess) => {
    await new ApiClient().callGet({
        methodName: apiMethods.DEVICE_PROFILE,
        requestConfig: {
            args: deviceId,
        },
        action: setDeviceInfo,
        onResponse: onRequestSuccess,
    });
};

const getDeviceCharts = async ({deviceID, config}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.DEVICE_CHART,
        action: setDeviceChart,
        mapper: deviceChartRequestDataMapping,
        requestConfig: {
            args: deviceID,
            data: config,
        },
    });
};

export default {
    getDeviceModels,
    getDeviceTypesGroups,
    getDevices,
    createDevice,
    editDevice,
    deleteDevice,
    getDeviceProfile,
    getDeviceCharts,
    getDevicesModelsByGroupID,
    getDeviceTypesGroupsByBuildingId,
};
