import {useSelector} from 'react-redux';

import * as requestParamTypes from '../../../../consts/app/requestParamTypes';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import * as userRolesId from '../../../../consts/user/userRolesId';
import buildingsService from '../../../../services/buildings/buildingsService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {
    makeSelectBuildingProfile,
    makeSelectBuildingsData,
    makeSelectRooms,
} from '../../../../state/selectors/buildings';
import {makeSelectCities, makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {
    makeSelectEditedUserStatuses,
    makeSelectUserInfo,
    makeSelectUserResponsibilitiesData,
    makeSelectUserRoles,
    makeSelectUserStatuses,
} from '../../../../state/selectors/users';
import {getState} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import regexUtils from '../../../../utils/regexUtils';

const isUserRole = (roleId) => roleId === userRolesId.USER;
const isManagerRole = (roleId) => roleId === userRolesId.MANAGER;

const getEditUserModalContent = () => {
    const localizedStrings = getLocalizedStrings();

    const userInfo = makeSelectUserInfo()(getState());

    if (!userInfo) return;

    const {items: userRoles} = makeSelectUserRoles()(getState());
    const {items: userStatuses} = makeSelectUserStatuses()(getState());

    const {userResponsibilities, name, surname, phone, status, login, isNotified, roleId, id} = userInfo || {};
    const [currentResponsibility] = userResponsibilities || [];

    const isOneResponsibility = userResponsibilities?.length === 1;

    const initialRoleValue =
        roleId === userRolesId.SUPER_ADMIN
            ? localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_SUPERADMIN_OPTION_LABEL]
            : userRoles?.find((role) => role.key === roleId)?.value;

    const initialStatusId = userStatuses?.find((userStatus) => userStatus.value === status)?.key;

    const title = localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_MODAL_TITLE];
    const submitButton = localizedStrings[localizationKeys.USERS_PAGE_EDIT_MODAl_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.USERS_PAGE_CANCEL_MODAl_BUTTON];

    const requireDeps = formInputNames.ROLE;

    const initialValues = {
        [formInputNames.USER_ID]: id,
        [formInputNames.NAME]: name,
        [formInputNames.SURNAME]: surname,
        [formInputNames.PHONE]: phone,
        [formInputNames.LOGIN]: login,
        [formInputNames.STATUS]: status && {
            id: initialStatusId,
            value: status,
        },
        [formInputNames.ROLE]: initialRoleValue && {
            id: roleId,
            value: initialRoleValue,
        },
        [formInputNames.NOTIFICATION]: isNotified,
    };

    const formContent = [
        {
            key: helpers.guid(),
            name: 'responsibilityId',
            hidden: true,
        },
        {
            key: helpers.guid(),
            name: formInputNames.USER_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.NAME,
            pattern: regexUtils.FIRST_LAST_NAME_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.REG_FORM_NAME_INPUT_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.REG_FORM_NAME_INPUT_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.REG_FORM_NAME_INPUT_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.SURNAME,
            pattern: regexUtils.FIRST_LAST_NAME_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            type: 'tel',
            name: formInputNames.PHONE,
            pattern: regexUtils.PHONE_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            type: 'email',
            name: formInputNames.LOGIN,
            pattern: regexUtils.EMAIL_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_3],
                localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_4],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: formInputNames.STATUS,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_ERROR_MESSAGE],
            withSearch: false,
            requestOptions: {
                selectRequestData: makeSelectEditedUserStatuses,
            },
        },
        {
            key: helpers.guid(),
            required: false,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: formInputNames.ROLE,
            disabled: roleId === userRolesId.SUPER_ADMIN,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_ERROR_MESSAGE],
            withSearch: false,
            requestOptions: {
                selectRequestData: makeSelectUserRoles,
            },
        },
        {
            key: helpers.guid(),
            required: false,
            formItemType: formItemTypes.RADIO,
            name: formInputNames.NOTIFICATION,
            label: 'Сповіщення',
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_TRUE],
                    value: true,
                },
                {
                    name: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_FALSE],
                    value: false,
                },
            ],
        },
    ];

    isOneResponsibility &&
        formContent.push(
            {
                key: helpers.guid(),
                formItemType: formItemTypes.SELECT,
                required: true,
                fieldLabel: 'name',
                name: formInputNames.CITY,
                label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL],
                placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER],
                errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE],
                requestOptions: {
                    selectRequestData: makeSelectCities,
                    getRequestData: () => organizationsService.getCities(),
                },
            },
            {
                key: helpers.guid(),
                formItemType: formItemTypes.SELECT,
                fieldLabel: 'name',
                hidden: roleId === userRolesId.SUPER_ADMIN,
                name: formInputNames.COMPANY,
                required: (currentRole) => currentRole?.id,
                disabled: (_, cityId) => !cityId,
                itemDeps: formInputNames.CITY,
                label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL],
                placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER],
                errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE],
                requestOptions: {
                    selectRequestData: makeSelectOrganizationsNames,
                    getRequestData: () => organizationsService.getOrganizationsNames(currentResponsibility.cityId),
                },
            },
            {
                key: helpers.guid(),
                formItemType: formItemTypes.SELECT,
                itemDeps: formInputNames.COMPANY,
                hidden: [userRolesId.SUPER_ADMIN, userRolesId.ADMIN]?.includes(roleId),
                required: (currentRole) => isUserRole(currentRole?.id) || isManagerRole(currentRole?.id),
                disabled: (_, companyId) => !companyId,
                fieldLabel: 'value',
                withSearch: true,
                fieldValue: 'value',
                name: formInputNames.BUILDING,
                label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL],
                placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER],
                errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE],
                requestOptions: {
                    paramsType: requestParamTypes.BUILDINGS,
                    selectRequestData: makeSelectBuildingsData,
                    getRequestData: () =>
                        buildingsService.getBuildingsListByOrganizationId(currentResponsibility.companyId),
                },
            },
            {
                key: helpers.guid(),
                formItemType: formItemTypes.SELECT,
                hidden: [userRolesId.SUPER_ADMIN, userRolesId.ADMIN, userRolesId.MANAGER]?.includes(roleId),
                itemDeps: formInputNames.BUILDING,
                required: (currentRole) => isUserRole(currentRole?.id),
                disabled: (_, buildingId) => !buildingId,
                withSearch: true,
                fieldLabel: 'value',
                fieldValue: 'value',
                itemPlacement: 'topLeft',
                name: formInputNames.APARTMENT,
                label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL],
                placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER],
                errorMessage:
                    localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE],
                requestOptions: {
                    paramsType: requestParamTypes.ROOMS,
                    selectRequestData: makeSelectRooms,
                    getRequestData: () =>
                        buildingsService.getCurrentRoomsByBuildingId(currentResponsibility.buildingId),
                },
            }
        );

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        initialValues,
        requireDeps,
    };
};

const getAddUserObjectsModalContent = () => {
    const localizedStrings = getLocalizedStrings();

    const userInfo = makeSelectUserInfo()(getState());

    if (!userInfo) return;

    const {roleId, id} = userInfo || {};

    const initialValues = {
        [formInputNames.USER_ID]: id,
    };

    const title = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_ADD_USER_MODAL_OBJECTS_TITLE];
    const submitButton = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_ADD_MODAl_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_CANCEL_MODAl_BUTTON];

    const requireDeps = formInputNames.ROLE;

    const formContent = [
        {
            key: helpers.guid(),
            name: formInputNames.USER_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            hidden: roleId === userRolesId.SUPER_ADMIN,
            required: true,
            fieldLabel: 'name',
            name: formInputNames.CITY,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            name: formInputNames.COMPANY,
            required: true,
            disabled: (_, cityId) => !cityId,
            itemDeps: formInputNames.CITY,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
            },
        },
        {
            key: helpers.guid(),
            required: isManagerRole(roleId),
            name: formInputNames.ALL_BUILDINGS,
            itemDeps: formInputNames.COMPANY,
            disabled: (_, companyId) => !companyId,
            hidden: !isManagerRole(roleId),
            formItemType: formItemTypes.RADIO,
            label: localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_FORM_FIELD_LABEL_ADD_ALL_BUILDINGS],
            errorMessage: localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_FORM_RADIO_REQUIRED_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_FORM_FIELD_VALUE_ADD_ALL_BUILDINGS_TRUE],
                    value: 'true',
                },
                {
                    name: localizedStrings[
                        localizationKeys.USERS_OBJECTS_PAGE_FORM_FIELD_VALUE_ADD_ALL_BUILDINGS_FALSE
                    ],
                    value: 'false',
                },
            ],
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            itemDeps: isManagerRole(roleId) ? formInputNames.ALL_BUILDINGS : formInputNames.COMPANY,
            required: (_, deps) => isUserRole(roleId) || (isManagerRole(roleId) && deps === 'false'),
            disabled: (_, deps) => (isManagerRole(roleId) ? deps !== 'false' : !deps),
            fieldLabel: 'value',
            hidden: [userRolesId.ADMIN]?.includes(roleId),
            withSearch: true,
            fieldValue: 'value',
            name: formInputNames.BUILDING,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.BUILDINGS,
                selectRequestData: makeSelectBuildingsData,
                getRequestData: () => buildingsService.getBuildingsListByOrganizationId(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            itemDeps: formInputNames.BUILDING,
            hidden: [userRolesId.SUPER_ADMIN, userRolesId.ADMIN, userRolesId.MANAGER]?.includes(roleId),
            required: isUserRole(roleId),
            disabled: (_, buildingId) => !buildingId,
            withSearch: true,
            fieldLabel: 'value',
            fieldValue: 'value',
            itemPlacement: 'topLeft',
            name: formInputNames.APARTMENT,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.ROOMS,
                selectRequestData: makeSelectRooms,
                getRequestData: () => buildingsService.getRoomsByBuildingId(),
            },
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        requireDeps,
        initialValues,
    };
};

const getEditUserObjectsModalContent = (responsibilityId) => {
    const localizedStrings = getLocalizedStrings();

    const userResponsibilitiesData = useSelector(makeSelectUserResponsibilitiesData());

    const {items: userResponsibilities = []} = userResponsibilitiesData || {};

    const userInfo = makeSelectUserInfo()(getState());

    if (!userInfo) return;

    const {roleId, id} = userInfo || {};

    const currentResponsibility = userResponsibilities?.find(
        (responsibility) => responsibility.id === responsibilityId
    );

    const title = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_EDIT_USER_MODAL_OBJECTS_TITLE];
    const submitButton = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_EDIT_MODAl_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_CANCEL_MODAl_BUTTON];

    const requireDeps = formInputNames.ROLE;

    const initialValues = {
        [formInputNames.USER_ID]: id,

        [formInputNames.CITY]: currentResponsibility?.city && {
            id: currentResponsibility?.cityID,
            value: currentResponsibility?.city,
        },
        [formInputNames.COMPANY]: currentResponsibility?.companyName && {
            id: currentResponsibility?.companyID,
            value: currentResponsibility?.companyName,
        },
        [formInputNames.BUILDING]: currentResponsibility?.buildingAddress && {
            id: currentResponsibility?.buildingID,
            value: currentResponsibility?.buildingAddress,
        },
        [formInputNames.APARTMENT]: currentResponsibility?.roomID && {
            id: currentResponsibility?.roomID,
            value: currentResponsibility?.roomName,
        },
        responsibilityId,
    };

    const formContent = [
        {
            key: helpers.guid(),
            name: 'responsibilityId',
            hidden: true,
        },
        {
            key: helpers.guid(),
            name: formInputNames.USER_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'name',
            name: formInputNames.CITY,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            name: formInputNames.COMPANY,
            required: true,
            disabled: (_, cityId) => !cityId,
            itemDeps: formInputNames.CITY,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(currentResponsibility.cityID),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            itemDeps: formInputNames.COMPANY,
            hidden: [userRolesId.SUPER_ADMIN, userRolesId.ADMIN]?.includes(roleId),
            required: [userRolesId.MANAGER, userRolesId.USER]?.includes(roleId),
            disabled: (_, companyId) => !companyId,
            fieldLabel: 'value',
            withSearch: true,
            fieldValue: 'value',
            name: formInputNames.BUILDING,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.BUILDINGS,
                selectRequestData: makeSelectBuildingsData,
                getRequestData: () =>
                    buildingsService.getBuildingsListByOrganizationId(currentResponsibility.companyID),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            itemDeps: formInputNames.BUILDING,
            hidden: [userRolesId.SUPER_ADMIN, userRolesId.ADMIN, userRolesId.MANAGER]?.includes(roleId),
            required: [userRolesId.USER]?.includes(roleId),
            disabled: (_, buildingId) => !buildingId,
            withSearch: true,
            fieldLabel: 'value',
            fieldValue: 'value',
            itemPlacement: 'topLeft',
            name: formInputNames.APARTMENT,
            label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.ROOMS,
                selectRequestData: makeSelectRooms,
                getRequestData: () => buildingsService.getCurrentRoomsByBuildingId(currentResponsibility.buildingID),
            },
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        initialValues,
        requireDeps,
    };
};

const getAddUserModalContent = () => {
    const localizedStrings = getLocalizedStrings();

    const title = localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_MODAL_TITLE];
    const submitButton = localizedStrings[localizationKeys.USERS_PAGE_ADD_MODAl_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.USERS_PAGE_CANCEL_MODAl_BUTTON];
    const requireDeps = formInputNames.ROLE;

    const formContent = [
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.NAME,
            pattern: regexUtils.FIRST_LAST_NAME_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.SURNAME,
            pattern: regexUtils.FIRST_LAST_NAME_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_PLACEHOLDER],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            type: 'tel',
            name: formInputNames.PHONE,
            pattern: regexUtils.PHONE_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            type: 'email',
            name: formInputNames.EMAIL,
            pattern: regexUtils.EMAIL_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_3],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_4],
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: formInputNames.ROLE,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_ERROR_MESSAGE],
            withSearch: false,
            requestOptions: {
                selectRequestData: makeSelectUserRoles,
            },
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: formInputNames.CITY,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_CITY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_CITY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_CITY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: (currentRole) => currentRole?.id,
            disabled: (_, cityId) => !cityId,
            itemDeps: formInputNames.CITY,
            name: formInputNames.COMPANY,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: (currentRole) => isUserRole(currentRole?.id) || isManagerRole(currentRole?.id),
            disabled: (_, companyId) => !companyId,
            itemDeps: formInputNames.COMPANY || formInputNames.ROLE,
            hidden: (currentRole) => currentRole?.id === userRolesId.ADMIN,
            fieldLabel: 'value',
            fieldValue: 'value',
            withSearch: true,
            name: formInputNames.BUILDING,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.BUILDINGS,
                selectRequestData: makeSelectBuildingsData,
                getRequestData: () => buildingsService.getBuildingsListByOrganizationId(),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.APARTMENT,
            formItemType: formItemTypes.SELECT,
            itemDeps: formInputNames.BUILDING || formInputNames.ROLE,
            required: (currentRole) => isUserRole(currentRole?.id),
            disabled: (_, buildingId) => !buildingId,
            hidden: (currentRole) => currentRole?.id === userRolesId.ADMIN || currentRole?.id === userRolesId.MANAGER,
            withSearch: true,
            fieldLabel: 'value',
            fieldValue: 'value',
            itemPlacement: 'topLeft',
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.ROOMS,
                selectRequestData: makeSelectRooms,
                getRequestData: () => buildingsService.getRoomsByBuildingId(),
            },
        },
    ];

    return {formContent, title, submitButton, cancelButton, requireDeps};
};

const getAddUserToBuildingModalContent = (buildingId, roomId) => {
    const localizedStrings = getLocalizedStrings();

    const title = localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_MODAL_TITLE];
    const submitButton = localizedStrings[localizationKeys.USERS_PAGE_ADD_MODAl_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.USERS_PAGE_CANCEL_MODAl_BUTTON];

    const requireDeps = formInputNames.ROLE;

    const buildingProfile = makeSelectBuildingProfile()(getState());
    const {items: userRoles} = makeSelectUserRoles()(getState());

    const initialValues = {
        [formInputNames.COMPANY]: {
            id: buildingProfile?.organizationID,
            value: buildingProfile?.organizationName,
        },
        [formInputNames.BUILDING]: {
            id: buildingProfile?.buildingID || buildingId,
            value: buildingProfile?.address,
        },

        [formInputNames.ROLE]: roomId && {
            id: userRolesId.USER,
            value: userRoles?.find((role) => role.key === userRolesId.USER)?.value,
        },
        [formInputNames.APARTMENT]: roomId && {
            id: roomId,
            value: 'CurrentRoomNamePlaceholder',
        },
    };

    const formContent = [
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.NAME,
            pattern: regexUtils.FIRST_LAST_NAME_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.SURNAME,
            pattern: regexUtils.FIRST_LAST_NAME_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_PLACEHOLDER],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: false,
            type: 'tel',
            name: formInputNames.PHONE,
            pattern: regexUtils.PHONE_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3],
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            type: 'email',
            name: formInputNames.EMAIL,
            pattern: regexUtils.EMAIL_REGEX,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_3],
                localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_4],
            ],
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: formInputNames.ROLE,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_ERROR_MESSAGE],
            withSearch: false,
            requestOptions: {
                selectRequestData: makeSelectUserRoles,
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.APARTMENT,
            formItemType: formItemTypes.SELECT,
            required: (currentRole) => isUserRole(currentRole?.id),
            withInitialRequest: false,
            itemDeps: formInputNames.BUILDING || formInputNames.ROLE,
            hidden: (currentRole) =>
                currentRole?.id === userRolesId.ADMIN || currentRole?.id === userRolesId.MANAGER || !!roomId,
            withSearch: true,
            fieldLabel: 'value',
            fieldValue: 'value',
            itemPlacement: 'topLeft',
            blockedBy: formInputNames.BUILDING,
            label: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE],
            requestOptions: {
                paramsType: requestParamTypes.ROOMS,
                selectRequestData: makeSelectRooms,
                getRequestData: () =>
                    buildingsService.getCurrentRoomsByBuildingId(buildingProfile?.buildingID || buildingId),
            },
        },
    ];

    return {formContent, title, submitButton, cancelButton, requireDeps, initialValues};
};

export default {
    getEditUserModalContent,
    getAddUserModalContent,
    getAddUserToBuildingModalContent,
    getAddUserObjectsModalContent,
    getEditUserObjectsModalContent,
};
