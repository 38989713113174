import React from 'react';

import Icon from '../Icon/Icon';

const IconSnowFlake = (props) => {
    const fill = props?.fill || '#0187F9';

    return (
        <Icon width='33' height='33' viewBox='0 0 96 96' fill='none' {...props}>
            <path
                clipRule='evenodd'
                d='M83.3112 59.9662C83.4681 60.7796 82.9358 61.5662 82.1223 61.7231L75.3607 63.0269L76.9807 69.0728C77.1951 69.873 76.7203 70.6955 75.9201 70.91C75.1199 71.1244 74.2974 70.6495 74.0829 69.8493L72.046 62.2473C71.9382 61.8451 72.0025 61.4162 72.2234 61.0633C72.4443 60.7103 72.802 60.4651 73.2109 60.3862L81.5543 58.7774C82.3678 58.6205 83.1543 59.1528 83.3112 59.9662Z'
                fill='#43AEFC'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M23.4124 31.7192C23.6074 32.4469 23.2323 33.2064 22.5359 33.4939L16.0461 36.173C15.2804 36.4891 14.4034 36.1246 14.0872 35.3588C13.7711 34.5931 14.1356 33.7161 14.9014 33.4L20.1738 31.2234L18.6344 25.4784C18.42 24.6782 18.8949 23.8557 19.6951 23.6413C20.4953 23.4269 21.3178 23.9017 21.5322 24.7019L23.4124 31.7192Z'
                fill='#43AEFC'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M76.639 23.6217C77.4208 23.8955 77.8327 24.7512 77.5589 25.5331L75.283 32.0323L81.3229 33.6749C82.1223 33.8922 82.5941 34.7165 82.3767 35.5159C82.1593 36.3153 81.335 36.7871 80.5356 36.5697L72.9413 34.5045C72.5395 34.3952 72.2012 34.1238 72.0074 33.7553C71.8135 33.3868 71.7816 32.9543 71.9192 32.5613L74.7275 24.5416C75.0013 23.7597 75.8571 23.3479 76.639 23.6217Z'
                fill='#43AEFC'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M22.0868 61.1692C22.8139 61.3669 23.2814 62.0732 23.1794 62.8197L22.2287 69.7761C22.1165 70.5969 21.3602 71.1713 20.5394 71.0592C19.7186 70.947 19.1442 70.1907 19.2563 69.3699L20.0287 63.7184L14.2894 62.1576C13.49 61.9402 13.0182 61.116 13.2356 60.3166C13.453 59.5172 14.2773 59.0454 15.0767 59.2628L22.0868 61.1692Z'
                fill='#43AEFC'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M41.6019 11.1473C42.2279 10.6047 43.1752 10.6724 43.7178 11.2984L48.2278 16.5022L52.6537 12.0763C53.2395 11.4905 54.1892 11.4905 54.775 12.0763C55.3608 12.6621 55.3608 13.6118 54.775 14.1976L49.21 19.7626C48.9155 20.0571 48.512 20.2159 48.0958 20.201C47.6797 20.1862 47.2885 19.999 47.0158 19.6844L41.4507 13.2632C40.9082 12.6371 40.9758 11.6898 41.6019 11.1473Z'
                fill='#43AEFC'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M47.0886 77.1444C47.6214 76.6117 48.4667 76.5568 49.0639 77.0162L54.6289 81.297C55.2855 81.8021 55.4084 82.7438 54.9033 83.4005C54.3982 84.0571 53.4564 84.1799 52.7998 83.6748L48.2787 80.197L44.073 84.4027C43.4872 84.9885 42.5375 84.9885 41.9517 84.4027C41.3659 83.8169 41.3659 82.8672 41.9517 82.2814L47.0886 77.1444Z'
                fill='#43AEFC'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M84.4948 68.2102C83.9425 69.1668 82.7193 69.4945 81.7627 68.9423L13.178 29.3448C12.2214 28.7925 11.8936 27.5694 12.4459 26.6128C12.9982 25.6562 14.2214 25.3284 15.178 25.8807L83.7627 65.4781C84.7193 66.0304 85.047 67.2536 84.4948 68.2102Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M81.0503 53.1089C81.4098 54.1534 80.8545 55.2915 79.81 55.6509L69.0006 59.371L70.3435 70.5167C70.4756 71.6133 69.6937 72.6094 68.5971 72.7416C67.5004 72.8737 66.5043 72.0918 66.3722 70.9952L64.836 58.2449C64.7233 57.309 65.2794 56.4214 66.1709 56.1146L78.5083 51.8686C79.5528 51.5092 80.6909 52.0645 81.0503 53.1089Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M32.1 36.5355C32.2273 37.4364 31.7307 38.3096 30.8914 38.6606L20.4649 43.0213C19.4458 43.4475 18.2742 42.9669 17.848 41.9479C17.4219 40.9288 17.9025 39.7572 18.9215 39.331L27.9232 35.5663L26.3892 24.716C26.2346 23.6223 26.9958 22.6104 28.0895 22.4557C29.1832 22.3011 30.1952 23.0624 30.3498 24.1561L32.1 36.5355Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M84.3593 26.7465C84.908 27.7051 84.5757 28.9271 83.6171 29.4758L14.8853 68.8174C13.9266 69.3661 12.7047 69.0338 12.156 68.0752C11.6073 67.1166 11.9396 65.8946 12.8982 65.3459L81.63 26.0043C82.5886 25.4556 83.8106 25.7879 84.3593 26.7465Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M69.5767 22.1244C70.6602 22.3394 71.3642 23.3919 71.1493 24.4754L68.9244 35.6884L79.2318 40.1368C80.246 40.5745 80.7133 41.7514 80.2756 42.7655C79.838 43.7797 78.661 44.247 77.6469 43.8094L65.8557 38.7206C64.9901 38.3471 64.5029 37.4198 64.6864 36.4951L67.2257 23.6969C67.4407 22.6135 68.4933 21.9094 69.5767 22.1244Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M30.6219 56.0852C31.4644 56.4284 31.9692 57.2971 31.8501 58.199L30.3716 69.4035C30.2271 70.4986 29.2222 71.2692 28.1271 71.1247C27.032 70.9802 26.2614 69.9753 26.4059 68.8802L27.6825 59.2069L17.5342 55.0724C16.5113 54.6556 16.0198 53.4885 16.4366 52.4656C16.8534 51.4426 18.0204 50.9512 19.0434 51.368L30.6219 56.0852Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M48.1493 6C49.2539 6 50.1493 6.89543 50.1493 8V87.1948C50.1493 88.2994 49.2539 89.1948 48.1493 89.1948C47.0447 89.1948 46.1493 88.2994 46.1493 87.1948V8C46.1493 6.89543 47.0447 6 48.1493 6Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M36.7941 16.5336C37.5189 15.7001 38.7822 15.612 39.6157 16.3368L48.242 23.8379L57.2231 17.1021C58.1068 16.4394 59.3604 16.6185 60.0231 17.5021C60.6859 18.3858 60.5068 19.6394 59.6231 20.3021L49.3492 28.0076C48.595 28.5732 47.5482 28.5354 46.8368 27.9168L36.991 19.3552C36.1575 18.6304 36.0693 17.3671 36.7941 16.5336Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M46.9166 67.2123C47.633 66.6516 48.6376 66.6451 49.3613 67.1965L58.351 74.0458C59.2296 74.7152 59.3991 75.9701 58.7297 76.8487C58.0603 77.7273 56.8054 77.8969 55.9268 77.2275L48.1656 71.3142L39.536 78.0678C38.6661 78.7486 37.4091 78.5953 36.7283 77.7254C36.0476 76.8556 36.2009 75.5985 37.0707 74.9178L46.9166 67.2123Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M36.3904 27.7061C36.9308 27.3697 37.599 27.3107 38.19 27.5471L48.2471 31.5699L59.1392 28.3426C59.6864 28.1805 60.2768 28.2598 60.7617 28.5607C61.2467 28.8616 61.58 29.3553 61.6778 29.9175L63.2762 39.1085L70.4984 45.9294C70.9053 46.3137 71.1325 46.8508 71.125 47.4104C71.1174 47.9699 70.8758 48.5008 70.4587 48.8739L62.783 55.7417L59.9354 67.1321C59.7928 67.7025 59.4065 68.1812 58.8791 68.441C58.3517 68.7009 57.7368 68.7156 57.1976 68.4812L48.042 64.5005L38.0568 67.6957C37.4742 67.8821 36.8384 67.7917 36.3309 67.4503C35.8234 67.1089 35.5 66.5541 35.4531 65.9443L34.6744 55.8214L25.2571 49.9356C24.6979 49.5861 24.3469 48.9833 24.3189 48.3245C24.291 47.6656 24.5896 47.0353 25.1171 46.6396L34.6664 39.4776L35.4531 29.2507C35.5019 28.616 35.85 28.0425 36.3904 27.7061ZM39.2325 32.2722L38.5852 40.6875C38.541 41.2616 38.2516 41.7887 37.7911 42.1341L29.8506 48.0895L37.6511 52.9648C38.1897 53.3015 38.5365 53.874 38.5852 54.5074L39.2473 63.1149L47.5397 60.4614C48.0017 60.3135 48.5018 60.3386 48.9467 60.5321L56.6274 63.8715L59.0513 54.1757C59.1484 53.7874 59.3598 53.4372 59.658 53.1703L66.1702 47.3436L60.0465 41.5601C59.7327 41.2638 59.5232 40.8739 59.4493 40.4487L58.1218 32.816L48.7174 35.6025C48.286 35.7303 47.8242 35.7089 47.4064 35.5418L39.2325 32.2722Z'
                fill='#0187F9'
                fillRule='evenodd'
            />
            <path
                clipRule='evenodd'
                d='M48.1141 32.1852C48.6822 32.1719 49.2091 32.4807 49.475 32.983L52.8584 39.3738L60.8496 38.6128C61.404 38.56 61.9421 38.8187 62.247 39.2848C62.5519 39.7509 62.5735 40.3476 62.303 40.8345L58.4664 47.7404L62.2398 53.4006C62.5467 53.8609 62.5753 54.4527 62.3143 54.9405C62.0532 55.4282 61.545 55.7327 60.9918 55.7327H52.9513L49.505 63.0083C49.2565 63.5328 48.7278 63.8669 48.1475 63.8662C47.5671 63.8654 47.0392 63.53 46.7921 63.0049L43.7249 56.487L36.8536 55.7235C36.3333 55.6657 35.8808 55.3408 35.6596 54.8662C35.4385 54.3917 35.4808 53.8362 35.7712 53.4006L39.4973 47.8115L34.915 40.9381C34.5933 40.4554 34.5786 39.8304 34.8775 39.3332C35.1764 38.836 35.7351 38.5557 36.3124 38.6135L43.819 39.3641L46.7921 33.0461C47.0341 32.5319 47.5459 32.1986 48.1141 32.1852ZM48.2283 37.0398L46.0819 41.6009C45.8114 42.1759 45.2078 42.518 44.5755 42.4547L39.1714 41.9143L42.5482 46.9794C42.8841 47.4833 42.8841 48.1397 42.5482 48.6435L39.6345 53.014L44.8904 53.598C45.4087 53.6556 45.8599 53.9783 46.0819 54.4501L48.1538 58.8528L50.6465 53.5905C50.8946 53.0667 51.4224 52.7327 52.0021 52.7327H58.189L55.4629 48.6435C55.1513 48.1762 55.127 47.574 55.3997 47.083L58.2961 41.8695L52.1443 42.4554C51.5399 42.513 50.9605 42.2006 50.6764 41.664L48.2283 37.0398Z'
                fill='#75C4FE'
                fillRule='evenodd'
            />
        </Icon>
    );
};

export default IconSnowFlake;
