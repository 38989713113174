import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import Form from '../../../../../components/Form/Form';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import log from '../../../../../services/logger/log';
import {makeSelectDeviceTypesUnassigned} from '../../../../../state/selectors/device';
import {makeSelectorDeviceGroupProfile} from '../../../../../state/selectors/settings';
import {getState} from '../../../../../state/store';
import helpers from '../../../../../utils/helpers';
import deviceGroupsModalService from './deviceGroupsModalService';

const DeviceGroupsModal = (props) => {
    const {onOk, onCancel, modalType, groupID} = props;
    const [form] = AntForm.useForm();

    const deviceGroupProfile = useSelector(makeSelectorDeviceGroupProfile()) || {};
    const modalData = deviceGroupsModalService.getDeviceGroupsModalContent(modalType, deviceGroupProfile);

    const {formContent, title, submitButton, cancelButton, initialValues} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            values.groupID = groupID;

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.error(`DeviceGroupsModal - validate failed: ${error}`);
        }
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
        return () => {
            form.resetFields();
        };
    }, []);
    useEffect(() => {
        (async () => {
            const {requestOptions} =
                formContent.find((field) => field.name === formInputNames.DEVICE_GROUPS_TYPES) || {};

            if (requestOptions.options) {
                const {items: unassignedOptions} = makeSelectDeviceTypesUnassigned()(getState());

                requestOptions.options = [...requestOptions.options, ...unassignedOptions];
            }
        })();
    }, []);

    const handleChange = (value, name) => {
        switch (name) {
            case formInputNames.DEVICE_GROUPS_TYPES:
                const isValueArrayEmpty = !value?.length;

                if (isValueArrayEmpty) {
                    form.setFieldsValue({[name]: undefined});
                }
                break;
            default: {
                form.setFieldsValue({[name]: value});
            }
        }
    };

    return (
        <Form
            handleChange={handleChange}
            closeModal={onCancel}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            initialValues={initialValues}
        />
    );
};

export default React.memo(DeviceGroupsModal);
