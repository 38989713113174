import {createSelector} from 'reselect';

const selectUser = (state) => state.currentUser;

const makeSelectCurrentUserInfo = () => createSelector(selectUser, (userState) => userState.currentUserInfo);
const makeSelectCurrentUserRoles = () => createSelector(selectUser, (userState) => userState.currentUserRoles);
const makeSelectCurrentUserStatuses = () => createSelector(selectUser, (userState) => userState.currentUserStatuses);
const makeSelectCurrentUserNewImage = () => createSelector(selectUser, (userState) => userState.newImage);

const makeSelectCurrentUserResponsibilitiesData = () =>
    createSelector(selectUser, (userState) => userState.currentUserResponsibilitiesData);

export {
    makeSelectCurrentUserInfo,
    makeSelectCurrentUserRoles,
    makeSelectCurrentUserStatuses,
    makeSelectCurrentUserNewImage,
    makeSelectCurrentUserResponsibilitiesData,
};
