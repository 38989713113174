import {useSelector} from 'react-redux';

import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {makeSelectKyivEnergoBuildingsData} from '../../../../../state/selectors/services';
import helpers from '../../../../../utils/helpers';
import regexUtils from '../../../../../utils/regexUtils';

const getAddUlcModalContent = (modalType, initialData) => {
    const kyivEnergoBuildingsData = useSelector(makeSelectKyivEnergoBuildingsData()) || {};

    const appliedBuildings = kyivEnergoBuildingsData?.appliedBuildings || [];
    const unappliedBuildings = kyivEnergoBuildingsData?.unappliedBuildings || [];

    const kyivEnergoBuildings = [...appliedBuildings, ...unappliedBuildings];

    const buildingData = kyivEnergoBuildings.find((building) => building.buildingID === initialData?.buildingID) || {};

    const ulcs = buildingData?.settings?.ulc?.join(', ');

    const localizedStrings = getLocalizedStrings();
    const isAddUlcModalType = modalType === modalTypes.SERVICES_ADD_ULC;

    const submitButton = isAddUlcModalType
        ? localizedStrings[localizationKeys.SETTINGS_DC_MODEL_ADD_FORM_SUBMIT_BUTTON]
        : localizedStrings[localizationKeys.SETTINGS_DC_MODEL_EDIT_FORM_SUBMIT_BUTTON];

    const title = isAddUlcModalType
        ? localizedStrings[localizationKeys.SERVICE_ADD_ULCS_TO_BUILDING_TITLE]
        : localizedStrings[localizationKeys.SERVICE_EDIT_ULCS_TO_BUILDING_TITLE];

    const cancelButton = localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_CANCEL_BUTTON];

    const initialValues = {
        buildingID: initialData?.buildingID,
        ulcs,
    };

    const formContent = [
        {
            key: helpers.guid(),
            required: true,
            name: 'buildingID',
            hidden: true,
        },
        {
            key: helpers.guid(),
            required: true,
            row: true,
            name: 'ulcs',
            label: localizedStrings[localizationKeys.SERVICE_ADD_ULCS_TO_BUILDING_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ADD_ULCS_TO_BUILDING_PLACEHOLDER],
            pattern: regexUtils.ULC_REGEX,
            errorMessage: localizedStrings[localizationKeys.SERVICE_ADD_ULCS_TO_BUILDING_ERROR],
            requestOptions: {selectRequestData: makeSelectKyivEnergoBuildingsData},
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        initialValues,
        formContent,
    };
};

export default {getAddUlcModalContent};
