import * as apiMethods from '../../consts/api/apiMethods';
import {
    setUserInfo,
    setUserResponsibilities,
    setUserRoles,
    setUsersData,
    setUserStatuses,
} from '../../state/ducks/users';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {
    addUserRequestPostMapping,
    editUserRequestPostMapping,
    userRolesRequestDataMapping,
    usersListRequestDataMapping,
    userStatusesRequestDataMapping,
} from '../mappings/userMappings';

const getUsersList = async ({options, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_GET_USERS_LIST,
            requestConfig: {
                data: options,
            },
            mapper: usersListRequestDataMapping,
            action: setUsersData,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUsersList: ${e}`);
    }
};

const approveUser = async (obj) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_APPROVE_USER,
            requestConfig: {
                data: {
                    users: Array.isArray(obj.userIds) ? obj.userIds : [obj.userIds],
                },
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`approveUser: ${e}`);
    }
};

const deleteUser = async (obj) => {
    try {
        await new ApiClient().callDelete({
            methodName: apiMethods.USERS_DELETE_USER,
            requestConfig: {
                data: {
                    users: Array.isArray(obj.userIds) ? obj.userIds : [obj.userIds],
                },
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`deleteUser: ${e}`);
    }
};

const addNewUser = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_ADD_NEW_USER,
            requestConfig: {
                data: addUserRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`addNewUser: ${e}`);
    }
};

const editUser = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.USERS_EDIT_USER,
            requestConfig: {
                data: editUserRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`editUser: ${e}`);
    }
};

const switchOnUserNotifications = async (obj) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_NOTIFICATIONS_ON,
            requestConfig: {
                data: {
                    users: Array.isArray(obj.userIds) ? obj.userIds : [obj.userIds],
                },
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`switchOnUserNotifications: ${e}`);
    }
};

const switchOffUserNotifications = async (obj) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_NOTIFICATIONS_OFF,
            requestConfig: {
                data: {
                    users: Array.isArray(obj.userIds) ? obj.userIds : [obj.userIds],
                },
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`switchOffUserNotifications: ${e}`);
    }
};

const blockUser = async (obj) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_BLOCK,
            requestConfig: {
                data: {
                    users: Array.isArray(obj.userIds) ? obj.userIds : [obj.userIds],
                },
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`blockUser: ${e}`);
    }
};

const unblockUser = async (obj) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_UNBLOCK,
            requestConfig: {
                data: {
                    users: Array.isArray(obj.userIds) ? obj.userIds : [obj.userIds],
                },
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`unblockUser: ${e}`);
    }
};

const switchUserStatus = async ({userId, onRequestSuccess}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.USERS_SWITCH_BLOCKING,
            requestConfig: {
                params: {
                    userId,
                },
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`switchUserStatus: ${e}`);
    }
};

const resetUserPassword = async ({userId, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_RESET_USER_PASSWORD,
            requestConfig: {
                data: {
                    userId,
                },
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`resetUserPassword: ${e}`);
    }
};

const getUserRoles = async (onRequestSuccess) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.USERS_GET_USER_ROLES,
            mapper: userRolesRequestDataMapping,
            action: setUserRoles,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUserRoles: ${e}`);
    }
};

const getUserStatuses = async (onRequestSuccess) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.USERS_GET_USER_STATUSES,
            action: setUserStatuses,
            mapper: userStatusesRequestDataMapping,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUserStatuses: ${e}`);
    }
};

const getUserInfoById = async ({id, onRequestSuccess}) =>
    await new ApiClient().callGet({
        methodName: apiMethods.USERS_GET_USER_INFO_BY_ID,
        requestConfig: {
            args: id,
        },
        onResponse: (data) => {
            dispatch(setUserInfo({...data, id}));
            helpers.runFunction(onRequestSuccess);
        },
    });

const getUserResponsibilities = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.USERS_USER_RESPONSIBILITIES,
            action: setUserResponsibilities,
        });
    } catch (e) {
        log.error(`getUserResponsibilities: ${e}`);
    }
};

export default {
    getUsersList,
    approveUser,
    editUser,
    deleteUser,
    switchOnUserNotifications,
    switchOffUserNotifications,
    blockUser,
    unblockUser,
    switchUserStatus,
    getUserStatuses,
    resetUserPassword,
    addNewUser,
    getUserRoles,
    getUserInfoById,
    getUserResponsibilities,
};
