import React from 'react';
import {useSelector} from 'react-redux';

import {IconBellOff, IconBellOn} from '../../../components/Icons';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {ADMIN, MANAGER, SUPER_ADMIN} from '../../../consts/user/userRolesId';
import * as userStatuses from '../../../consts/user/userStatuses';
import appRouterService from '../../../services/app/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {makeSelectCurrentUserInfo} from '../../../state/selectors/currentUser';
import helpers from '../../../utils/helpers';
import {IconApprove, IconEdit, IconLock, IconLockOpen, IconSwap, IconTrashCan} from '../../Icons';
import IconPlusMinus from '../../Icons/IconPlusMinus';
import OptionsMenu from '../OptionsMenu';

const UsersOptionsMenu = ({handlers, record, dataSource, isForHeader, isDisabled}) => {
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());
    const {roleId: currentRoleId} = currentUserInfo || {};
    const userRoleId = record?.roleId;

    const isSuperAdmin = userRoleId === SUPER_ADMIN;
    const isAdminAndUserAdmin = currentRoleId === ADMIN && userRoleId === ADMIN;
    const isManagerAndUserManagerOrAdmin =
        currentRoleId === MANAGER && (userRoleId === MANAGER || userRoleId === ADMIN);

    const isVisible = !isSuperAdmin && !isAdminAndUserAdmin && !isManagerAndUserManagerOrAdmin;

    const isActiveStatus = record?.status == userStatuses.ACTIVE;
    const isNotActiveStatus = record?.status == userStatuses.NOT_ACTIVE;

    const isApproveItemHidden = isActiveStatus || isNotActiveStatus;

    const localizedStrings = getLocalizedStrings();

    const menu = isForHeader
        ? [
              {
                  key: helpers.guid(),
                  icon: <IconApprove />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_APPROVE_MENU_OPTION],
                  onClick: () => handlers.handleApprove(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconBellOn />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_TRUE],
                  onClick: () => handlers.handleNotificationsOnStatus(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconBellOff />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_FALSE],
                  onClick: () => handlers.handleNotificationsOffStatus(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconLockOpen />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_UNBLOCK_MENU_OPTION],
                  onClick: () => handlers.handleUnblockUser(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconLock />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_BLOCK_MENU_OPTION],
                  onClick: () => handlers.handleBlockUser(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconTrashCan />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_DELETE_MENU_OPTION],
                  onClick: () => handlers.handleDelete(record),
                  isActionConfirmRequired: true,
              },
          ]
        : [
              {
                  key: helpers.guid(),
                  icon: <IconEdit />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_EDIT_MENU_OPTION],
                  isVisible: true,
                  onClick: () => handlers.handleEdit(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconPlusMinus />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_OBJECTS_MENU_OPTION],
                  isVisible,
                  onClick: () => appRouterService.forwardToUsersObjectsPage(record.id),
              },
              {
                  key: helpers.guid(),
                  icon: <IconApprove />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_APPROVE_MENU_OPTION],
                  isVisible: !isApproveItemHidden,
                  onClick: () => handlers.handleApprove([record.id]),
              },
              {
                  key: helpers.guid(),
                  icon: <IconLockOpen />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_UNBLOCK_MENU_OPTION],
                  isVisible: isNotActiveStatus,
                  onClick: () => handlers.handleUnblockUser([record.id]),
              },
              {
                  key: helpers.guid(),
                  icon: <IconLock />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_BLOCK_MENU_OPTION],
                  isVisible: isActiveStatus,
                  onClick: () => handlers.handleBlockUser([record.id]),
              },
              {
                  key: helpers.guid(),
                  icon: <IconSwap />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_RESET_PASSWORD_MENU_OPTION],
                  isVisible: true,
                  onClick: () => handlers.handleResetPass(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconTrashCan />,
                  label: localizedStrings[localizationKeys.USERS_PAGE_DELETE_MENU_OPTION],
                  onClick: () => handlers.handleDelete([record.id]),
                  isVisible: true,
                  isActionConfirmRequired: true,
              },
          ].filter((item) => item.isVisible);

    return <OptionsMenu menuItems={menu} record={record} dataSource={dataSource} disabled={isDisabled} />;
};

export default UsersOptionsMenu;
