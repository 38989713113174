import dataCollectorHandlers from '../../consts/dataCollector/dataCollectorHandlers.ts';
import * as dataCollectorSettingsTypes from '../../consts/dataCollector/dataCollectorSettingsTypes';
import {makeSelectCurrentDataCollector} from '../../state/selectors/dataCollectors';
import {getState} from '../../state/store';
import stringUtils from '../../utils/stringUtils';

export const currentDataCollectorMapping = (data) => {
    const settings = {...data?.smartSettings, ...data.protocolSettings};

    return {...data, ...settings};
};

export const dataCollectorListMapping = (data) => {
    const list = data?.items;

    const mappedList = list.map((item) => ({
        key: item.id,
        id: item.id,
        ...item,
    }));

    const modelIDs = data?.filters?.ModelID;

    const dataCollectorTypes = Object.keys(modelIDs).map((key) => ({key, value: modelIDs[key]}));

    return {
        ...data,
        dataCollectorTypes,
        items: [...mappedList],
    };
};
export const createDataCollectorRequestMapping = (values) => {
    const {server, folder, login, password, source, format, handler, isDataTransferAllowed} = values || {};
    const isSmart = handler === dataCollectorHandlers.Smart;

    const settingsType = isSmart
        ? dataCollectorSettingsTypes.SMART_SETTINGS
        : dataCollectorSettingsTypes.PROTOCOL_SETTINGS;

    const mappedValues = {
        name: values.name,
        deviceNumber: values.deviceNumber,
        ipAddress: values.ipAddress,
        simNumber: values.simNumber,
        isDataTransferAllowed: stringUtils.getBooleanFromString(isDataTransferAllowed),
        handler: values.handler,
        status: values.status,
        [settingsType]: {
            server,
            folder,
            login,
            password,
            format: 1,
        },
        uspdTypeId: values.typeId.key,
        buildingId: values.buildingId.key,
    };

    if (source) {
        mappedValues[settingsType].source = source;
    }

    if (format) {
        mappedValues[settingsType].format = format.key;
    }

    return mappedValues;
};
export const editDataCollectorRequestMapping = (values) => {
    const currentDataCollector = makeSelectCurrentDataCollector()(getState());
    const {server, folder, login, password, source, format, handler} = values || {};
    const isSmart = handler === dataCollectorHandlers.Smart;

    const settingsType = isSmart
        ? dataCollectorSettingsTypes.SMART_SETTINGS
        : dataCollectorSettingsTypes.PROTOCOL_SETTINGS;

    const isDataTransferAllowed = stringUtils.getBooleanFromString(values['isDataTransferAllowed']);

    const mappedValues = {
        name: values.name,
        deviceNumber: values.deviceNumber,
        ipAddress: values.ipAddress,
        simNumber: values.simNumber,
        isDataTransferAllowed,
        handler: values.handler,
        status: values.status,
        [settingsType]: {
            server,
            folder,
            login,
            password,
            format: 1,
        },
        uspdTypeId: values.typeId.key,
        buildingId: values.buildingId.key,
    };

    if (currentDataCollector?.uspdID) {
        mappedValues.uspdID = currentDataCollector?.uspdID;
    }

    if (source) {
        mappedValues[settingsType].source = source;
    }

    if (format) {
        mappedValues[settingsType].format = format.key;
    }

    return mappedValues;
};
