export const SET_KYIVENERGO_BUILDINGS_DATA = 'app/services/SET_KYIVENERGO_BUILDINGS_DATA';
export const SET_KYIVENERGO_SETTINGS_DATA = 'app/services/SET_KYIVENERGO_SETTINGS_DATA';
export const SET_KYIVENERGO_STATISTICS_DATA = 'app/services/SET_KYIVENERGO_STATISTICS_DATA';
export const SET_KTE_STATUS = 'app/services/SET_KTE_STATUS';
export const SET_LVK_BUILDINGS_DATA = 'app/services/SET_LVK_BUILDINGS_DATA';
export const SET_LVK_STATUS = 'app/services/SET_LVK_STATUS';
export const SET_LVK_SETTINGS_DATA = 'app/services/SET_LVK_SETTINGS_DATA';
export const SET_LVK_STATISTICS_DATA = 'app/services/SET_LVK_STATISTICS_DATA';
export const SET_KVK_BUILDINGS_DATA = 'app/services/SET_KVK_BUILDINGS_DATA';
export const SET_KVK_STATUS = 'app/services/SET_KVK_STATUS';
export const SET_KVK_SETTINGS_DATA = 'app/services/SET_KVK_SETTINGS_DATA';
export const SET_KVK_STATISTICS_DATA = 'app/services/SET_KVK_STATISTICS_DATA';
export const SET_ARS_ORGANIZATIONS_DATA = 'app/services/SET_ARS_ORGANIZATIONS_DATA';
export const SET_ARS_BUILDINGS_LIST = 'app/services/SET_ARS_BUILDINGS_LIST';
export const SET_ARS_ORGANIZATION_SETTINGS = 'app/services/SET_ORGANIZATION_SETTINGS';
export const SET_ARS_ORGANIZATION_STATISTICS = 'app/services/SET_ARS_ORGANIZATION_STATISTICS';
export const SET_ARS_UNAPPLIED_ORGANIZATIONS_NAMES = 'app/services/SET_ARS_UNAPPLIED_ORGANIZATIONS_NAMES';
export const SET_ARS_STATUS = 'app/services/SET_ARS_STATUS';

const initialState = {
    kyivEnergo: {
        buildings: null,
        settings: null,
        statistics: null,
        status: null,
    },
    lvk: {
        buildings: null,
        status: null,
        settings: null,
        statistics: null,
    },
    kvk: {
        buildings: null,
        status: null,
        settings: null,
        statistics: null,
    },
    arsOrganizationsData: null,
    arsBuildingsList: null,
    organizationSettings: null,
    arsUnappliedOrganizationsNames: null,
    arsOrganizationStatistics: null,
    arsStatus: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_KYIVENERGO_BUILDINGS_DATA: {
            return {
                ...state,
                kyivEnergo: {
                    ...state.kyivEnergo,
                    buildings: action.payload,
                },
            };
        }

        case SET_KYIVENERGO_SETTINGS_DATA: {
            return {
                ...state,
                kyivEnergo: {
                    ...state.kyivEnergo,
                    settings: action.payload,
                },
            };
        }

        case SET_KYIVENERGO_STATISTICS_DATA: {
            return {
                ...state,
                kyivEnergo: {
                    ...state.kyivEnergo,
                    statistics: action.payload,
                },
            };
        }

        case SET_KTE_STATUS: {
            return {
                ...state,
                kyivEnergo: {
                    ...state.kyivEnergo,
                    status: action.payload,
                },
            };
        }

        case SET_LVK_BUILDINGS_DATA: {
            return {
                ...state,
                lvk: {
                    ...state.lvk,
                    buildings: action.payload,
                },
            };
        }

        case SET_LVK_STATUS: {
            return {
                ...state,
                lvk: {
                    ...state.lvk,
                    status: action.payload,
                },
            };
        }

        case SET_LVK_STATISTICS_DATA: {
            return {
                ...state,
                lvk: {
                    ...state.lvk,
                    statistics: action.payload,
                },
            };
        }

        case SET_LVK_SETTINGS_DATA: {
            return {
                ...state,
                lvk: {
                    ...state.lvk,
                    settings: action.payload,
                },
            };
        }

        case SET_KVK_BUILDINGS_DATA: {
            return {
                ...state,
                kvk: {
                    ...state.kvk,
                    buildings: action.payload,
                },
            };
        }

        case SET_KVK_STATUS: {
            return {
                ...state,
                kvk: {
                    ...state.kvk,
                    status: action.payload,
                },
            };
        }

        case SET_KVK_SETTINGS_DATA: {
            return {
                ...state,
                kvk: {
                    ...state.kvk,
                    settings: action.payload,
                },
            };
        }

        case SET_KVK_STATISTICS_DATA: {
            return {
                ...state,
                kvk: {
                    ...state.kvk,
                    statistics: action.payload,
                },
            };
        }

        case SET_ARS_ORGANIZATIONS_DATA: {
            return {
                ...state,
                arsOrganizationsData: action.payload,
            };
        }

        case SET_ARS_BUILDINGS_LIST: {
            return {
                ...state,
                arsBuildingsList: action.payload,
            };
        }

        case SET_ARS_ORGANIZATION_STATISTICS: {
            return {
                ...state,
                arsOrganizationStatistics: action.payload,
            };
        }

        case SET_ARS_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                organizationSettings: action.payload,
            };
        }
        case SET_ARS_UNAPPLIED_ORGANIZATIONS_NAMES: {
            return {
                ...state,
                arsUnappliedOrganizationsNames: action.payload,
            };
        }
        case SET_ARS_STATUS: {
            return {
                ...state,
                arsStatus: action.payload,
            };
        }

        default:
            return state;
    }
}

export const setKyivEnergoData = (payload) => ({
    type: SET_KYIVENERGO_BUILDINGS_DATA,
    payload,
});

export const setKyivEnergoSettingsData = (payload) => ({
    type: SET_KYIVENERGO_SETTINGS_DATA,
    payload,
});

export const setKyivEnergoStatisticsData = (payload) => ({
    type: SET_KYIVENERGO_STATISTICS_DATA,
    payload,
});

export const setKteStatus = (payload) => ({
    type: SET_KTE_STATUS,
    payload,
});

export const setLvkData = (payload) => ({
    type: SET_LVK_BUILDINGS_DATA,
    payload,
});

export const setLvkStatus = (payload) => ({
    type: SET_LVK_STATUS,
    payload,
});

export const setLvkSettingsData = (payload) => ({
    type: SET_LVK_SETTINGS_DATA,
    payload,
});

export const setLvkStatisticsData = (payload) => ({
    type: SET_LVK_STATISTICS_DATA,
    payload,
});

export const setKvkData = (payload) => ({
    type: SET_KVK_BUILDINGS_DATA,
    payload,
});

export const setKvkStatus = (payload) => ({
    type: SET_KVK_STATUS,
    payload,
});

export const setKvkSettingsData = (payload) => ({
    type: SET_KVK_SETTINGS_DATA,
    payload,
});

export const setKvkStatisticsData = (payload) => ({
    type: SET_KVK_STATISTICS_DATA,
    payload,
});

export const setArsOrganizationsData = (payload) => ({
    type: SET_ARS_ORGANIZATIONS_DATA,
    payload,
});

export const setArsBuildingsList = (payload) => ({
    type: SET_ARS_BUILDINGS_LIST,
    payload,
});

export const setArsOrganizationStatistics = (payload) => ({
    type: SET_ARS_ORGANIZATION_STATISTICS,
    payload,
});

export const setArsOrganizationSettings = (payload) => ({
    type: SET_ARS_ORGANIZATION_SETTINGS,
    payload,
});

export const setArsUnappliedOrganizationsNames = (payload) => ({
    type: SET_ARS_UNAPPLIED_ORGANIZATIONS_NAMES,
    payload,
});

export const setArsStatus = (payload) => ({
    type: SET_ARS_STATUS,
    payload,
});
