import React from 'react';

import dataCollectorStatuses from '../../../consts/dataCollector/dataCollectorStatuses.ts';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import helpers from '../../../utils/helpers';
import {IconCopy, IconEdit, IconLock, IconLockOpen, IconTrashCan} from '../../Icons';
import OptionsMenu from '../OptionsMenu';

const DataCollectorOptionsMenu = ({record, dataSource, handlers, isForHeader, isDisabled}) => {
    const isBlocked = dataCollectorStatuses.Blocked === record?.status;
    const localizedStrings = getLocalizedStrings();

    const menu = isForHeader
        ? [
              {
                  key: helpers.guid(),
                  icon: <IconLockOpen />,
                  label: localizedStrings[localizationKeys.DC_OPTIONS_MENU_UNBLOCK],
                  onClick: () => handlers.handleUnlockStatus(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconLock />,
                  label: localizedStrings[localizationKeys.DC_OPTIONS_MENU_BLOCK],
                  onClick: () => handlers.handleLockStatus(record),
              },
          ]
        : [
              {
                  key: helpers.guid(),
                  icon: <IconEdit />,
                  label: localizedStrings[localizationKeys.DC_OPTIONS_MENU_EDIT],
                  isVisible: true,
                  onClick: () => handlers.handleEdit(record),
              },
              {
                  key: helpers.guid(),
                  icon: isBlocked ? <IconLockOpen /> : <IconLock />,
                  label: isBlocked
                      ? localizedStrings[localizationKeys.DC_OPTIONS_MENU_UNBLOCK]
                      : localizedStrings[localizationKeys.DC_OPTIONS_MENU_BLOCK],
                  isVisible: true,
                  onClick: () =>
                      isBlocked ? handlers.handleUnlockStatus([record.id]) : handlers.handleLockStatus([record.id]),
              },
              {
                  key: helpers.guid(),
                  icon: <IconCopy />,
                  label: localizedStrings[localizationKeys.DC_OPTIONS_MENU_COPY],
                  isVisible: true,
                  onClick: () => handlers.handleCopy(record),
              },
              {
                  key: helpers.guid(),
                  icon: <IconTrashCan />,
                  label: localizedStrings[localizationKeys.DC_OPTIONS_MENU_DELETE],
                  isVisible: true,
                  onClick: () => handlers.handleDelete(record),
                  isActionConfirmRequired: true,
              },
          ].filter((item) => item.isVisible);

    return <OptionsMenu menuItems={menu} record={record} dataSource={dataSource} disabled={isDisabled} />;
};

export default DataCollectorOptionsMenu;
