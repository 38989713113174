export const LOGOUT_BUTTON = 'LOGOUT_BUTTON';
export const MENU_ITEM_MAP = 'MENU_ITEM_MAP';
export const MENU_ITEM_MODULES = 'MENU_ITEM_MODULES';
export const MENU_ITEM_REPORTS = 'MENU_ITEM_REPORTS';
export const MENU_ITEM_OBJECTS = 'MENU_ITEM_OBJECTS';
export const MENU_ITEM_USER_OBJECTS = 'MENU_ITEM_USER_OBJECTS';
export const MENU_ITEM_USERS = 'MENU_ITEM_USERS';
export const MENU_ITEM_SETTINGS = 'MENU_ITEM_SETTINGS';
export const MENU_ITEM_MONITORING = 'MENU_ITEM_MONITORING';
export const MENU_ITEM_MODEMS = 'MENU_ITEM_MODEMS';
export const DRAWER_OBJECT_TEXT_YEAR = 'DRAWER_OBJECT_TEXT_YEAR';
export const DRAWER_OBJECT_TEXT_APARTMENTS = 'DRAWER_OBJECT_TEXT_APARTMENTS';
export const DCTD_TEXT = 'DCTD_TEXT';
export const ROOMS_TEXT = 'ROOMS_TEXT';
export const USERS_TEXT = 'USERS_TEXT';
export const MODULES_TEXT = 'MODULES_TEXT';
export const BUTTON_TEXT_MORE = 'BUTTON_TEXT_MORE';
export const BUTTON_TEXT_DETAILS = 'BUTTON_TEXT_DETAILS';
export const BUTTON_TEXT_ADD = 'BUTTON_TEXT_ADD';
export const GLOBAL_LOADER_TEXT = 'GLOBAL_LOADER_TEXT';
export const IP_TEXT = 'IP_TEXT';
export const USER_MENU_PROFILE_ITEM = 'USER_MENU_PROFILE_ITEM';
export const USER_MENU_SETTING_ITEM = 'USER_MENU_SETTING_ITEM';
export const USER_MENU_LOGOUT_ITEM = 'USER_MENU_LOGOUT_ITEM';
export const AUTH_LOGIN_TITLE = 'AUTH_LOGIN_TITLE';
export const AUTH_REGISTRATION_TITLE = 'AUTH_REGISTRATION_TITLE';
export const AUTH_FORGOT_PASSWORD_TITLE = 'AUTH_FORGOT_PASSWORD_TITLE';
export const DATA_COLLECTOR_STATUS_ALL_DEVICES_WITHOUT_ERRORS = 'DATA_COLLECTOR_STATUS_ALL_DEVICES_WITHOUT_ERRORS';
export const DATA_COLLECTOR_STATUS_NO_DATA_RETRIEVED = 'DATA_COLLECTOR_STATUS_NO_DATA_RETRIEVED';
export const DATA_COLLECTOR_STATUS_ALL_DEVICES_WITH_ERRORS = 'DATA_COLLECTOR_STATUS_ALL_DEVICES_WITH_ERRORS';
export const DATA_COLLECTOR_STATUS_SOME_DEVICES_WITH_ERRORS = 'DATA_COLLECTOR_STATUS_SOME_DEVICES_WITH_ERRORS';
export const DATA_COLLECTOR_STATUS_TIMEPOINTS_DO_NOT_MATH_WITH_CURRENT_DATE =
    'DATA_COLLECTOR_STATUS_TIMEPOINTS_DO_NOT_MATH_WITH_CURRENT_DATE';
export const DATA_COLLECTOR_STATUS_DATA_TRANSFER_DISABLED = 'DATA_COLLECTOR_STATUS_DATA_TRANSFER_DISABLED';
export const DATA_COVERAGE_TEXT = 'DATA_COVERAGE_TEXT';
export const MAP_FILTER_BUTTON_TEXT = 'MAP_FILTER_BUTTON_TEXT';
export const MAP_ADDRESS_SEARCH_FAILED = 'MAP_ADDRESS_SEARCH_FAILED';
export const NOTIFICATION_FAILED_FORWARD_TO_ROOM_PAGE = 'NOTIFICATION_FAILED_FORWARD_TO_ROOM_PAGE';
export const LOGIN_FORM_LOGIN_INPUT_NAME = 'LOGIN_FORM_LOGIN_INPUT_NAME';
export const LOGIN_FORM_LOGIN_INPUT_LABEL = 'LOGIN_FORM_LOGIN_INPUT_LABEL';
export const LOGIN_FORM_LOGIN_INPUT_PLACEHOLDER = 'LOGIN_FORM_LOGIN_INPUT_PLACEHOLDER';
export const LOGIN_FORM_LOGIN_INPUT_ERROR = 'LOGIN_FORM_LOGIN_INPUT_ERROR';
export const LOGIN_FORM_PASSWORD_INPUT_NAME = 'LOGIN_FORM_PASSWORD_INPUT_NAME';
export const LOGIN_FORM_PASSWORD_INPUT_LABEL = 'LOGIN_FORM_PASSWORD_INPUT_LABEL';
export const LOGIN_FORM_PASSWORD_INPUT_PLACEHOLDER = 'LOGIN_FORM_PASSWORD_INPUT_PLACEHOLDER';
export const LOGIN_FORM_PASSWORD_INPUT_ERROR = 'LOGIN_FORM_PASSWORD_INPUT_ERROR';
export const LOGIN_FORM_REMEMBER_ME = 'LOGIN_FORM_REMEMBER_ME';
export const LOGIN_FORM_FORGOT_PASSWORD = 'LOGIN_FORM_FORGOT_PASSWORD';
export const LOGIN_FORM_ENTER_BUTTON = 'LOGIN_FORM_ENTER_BUTTON';
export const LOGIN_FORM_REG_BUTTON = 'LOGIN_FORM_REG_BUTTON';
export const LOGIN_FORM_DEMO_BUTTON = 'LOGIN_FORM_DEMO_BUTTON';
export const REG_FORM_NAME_INPUT_LABEL = 'REG_FORM_NAME_INPUT_LABEL';
export const REG_FORM_NAME_INPUT_PLACEHOLDER = 'REG_FORM_NAME_INPUT_PLACEHOLDER';
export const REG_FORM_NAME_INPUT_ERROR = 'REG_FORM_NAME_INPUT_ERROR';
export const REG_FORM_NAME_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_NAME_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_NAME_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_NAME_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_NAME_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_SURNAME_INPUT_LABEL = 'REG_FORM_SURNAME_INPUT_LABEL';
export const REG_FORM_SURNAME_INPUT_PLACEHOLDER = 'REG_FORM_SURNAME_INPUT_PLACEHOLDER';
export const REG_FORM_SURNAME_INPUT_ERROR = 'REG_FORM_SURNAME_INPUT_ERROR';
export const REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_SURNAME_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_PHONE_INPUT_LABEL = 'REG_FORM_PHONE_INPUT_LABEL';
export const REG_FORM_PHONE_INPUT_PREFIX = 'REG_FORM_PHONE_INPUT_PREFIX';
export const REG_FORM_PHONE_INPUT_PLACEHOLDER = 'REG_FORM_PHONE_INPUT_PLACEHOLDER';
export const REG_FORM_PHONE_INPUT_ERROR = 'REG_FORM_PHONE_INPUT_ERROR';
export const REG_FORM_PHONE_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_PHONE_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_EMAIL_INPUT_LABEL = 'REG_FORM_EMAIL_INPUT_LABEL';
export const REG_FORM_EMAIL_INPUT_PLACEHOLDER = 'REG_FORM_EMAIL_INPUT_PLACEHOLDER';
export const REG_FORM_EMAIL_INPUT_ERROR = 'REG_FORM_EMAIL_INPUT_ERROR';
export const REG_FORM_EMAIL_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_EMAIL_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_EMAIL_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_EMAIL_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_EMAIL_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_EMAIL_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_EMAIL_INPUT_FEEDBACK = 'REG_FORM_EMAIL_INPUT_FEEDBACK';
export const REG_FORM_PASSWORD_INPUT_LABEL = 'REG_FORM_PASSWORD_INPUT_LABEL';
export const REG_FORM_PASSWORD_INPUT_PLACEHOLDER = 'REG_FORM_PASSWORD_INPUT_PLACEHOLDER';
export const REG_FORM_PASSWORD_INPUT_ERROR = 'REG_FORM_PASSWORD_INPUT_ERROR';
export const REG_FORM_PASSWORD_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_PASSWORD_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_PASSWORD_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_PASSWORD_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_PASSWORD_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_PASSWORD_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_APARTMENT_INPUT_LABEL = 'REG_FORM_APARTMENT_INPUT_LABEL';
export const REG_FORM_APARTMENT_INPUT_PLACEHOLDER = 'REG_FORM_APARTMENT_INPUT_PLACEHOLDER';
export const REG_FORM_APARTMENT_INPUT_ERROR = 'REG_FORM_APARTMENT_INPUT_ERROR';
export const REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_COMPANY_INPUT_LABEL = 'REG_FORM_COMPANY_INPUT_LABEL';
export const REG_FORM_COMPANY_INPUT_PLACEHOLDER = 'REG_FORM_COMPANY_INPUT_PLACEHOLDER';
export const REG_FORM_COMPANY_INPUT_ERROR = 'REG_FORM_COMPANY_INPUT_ERROR';
export const REG_FORM_COMPANY_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_COMPANY_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_COMPANY_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_COMPANY_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_COMPANY_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_COMPANY_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_BUILDING_INPUT_LABEL = 'REG_FORM_BUILDING_INPUT_LABEL';
export const REG_FORM_BUILDING_INPUT_PLACEHOLDER = 'REG_FORM_BUILDING_INPUT_PLACEHOLDER';
export const REG_FORM_BUILDING_INPUT_ERROR = 'REG_FORM_BUILDING_INPUT_ERROR';
export const REG_FORM_BUILDING_INPUT_FEEDBACK_RULE_1 = 'REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_1';
export const REG_FORM_BUILDING_INPUT_FEEDBACK_RULE_2 = 'REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_2';
export const REG_FORM_BUILDING_INPUT_FEEDBACK_RULE_3 = 'REG_FORM_APARTMENT_INPUT_FEEDBACK_RULE_3';
export const REG_FORM_SUCCESS_NOTIFICATION = 'REG_FORM_SUCCESS_NOTIFICATION';
export const REG_FORM_CITY_FIELD_ERROR_MESSAGE = 'REG_FORM_CITY_FIELD_ERROR_MESSAGE';
export const REG_FORM_CITY_FIELD_LABEL = 'REG_FORM_CITY_FIELD_LABEL';
export const REG_FORM_CITY_FIELD_PLACEHOLDER = 'REG_FORM_CITY_FIELD_PLACEHOLDER';
export const REG_FORM_NEXT_BUTTON = 'REG_FORM_NEXT_BUTTON';
export const REG_FORM_PREV_BUTTON = 'REG_FORM_PREV_BUTTON';
export const ERROR_NETWORK_MESSAGE = 'ERROR_NETWORK_MESSAGE';
export const TAB_TEXT_TABLE = 'TAB_TEXT_TABLE';
export const TABLE_ZERO_DEVICES = 'TABLE_ZERO_DEVICES';
export const TAB_TEXT_GRID = 'TAB_TEXT_GRID';
export const USERS_PAGE_TITLE = 'USERS_PAGE_TITLE';
export const USERS_PAGE_SEARCH_PLACEHOLDER = 'USERS_PAGE_SEARCH_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_BUTTON = 'USERS_PAGE_ADD_USER_BUTTON';
export const USERS_PAGE_ADD_USER_MODAL_TITLE = 'USERS_PAGE_ADD_USER_MODAL_TITLE';
export const USERS_PAGE_EDIT_USER_MODAL_TITLE = 'USERS_PAGE_EDIT_USER_MODAL_TITLE';
export const USERS_PAGE_ADD_MODAl_BUTTON = 'USERS_PAGE_ADD_MODAl_BUTTON';
export const USERS_PAGE_EDIT_MODAl_BUTTON = 'USERS_PAGE_EDIT_MODAl_BUTTON';
export const USERS_PAGE_CANCEL_MODAl_BUTTON = 'USERS_PAGE_CANCEL_MODAl_BUTTON';
export const BUTTONS_FILTER_BUTTON = 'BUTTONS_FILTER_BUTTON';
export const USERS_PAGE_APPROVE_MENU_OPTION = 'USERS_PAGE_APPROVE_MENU_OPTION';
export const USERS_PAGE_BLOCK_MENU_OPTION = 'USERS_PAGE_BLOCK_MENU_OPTION';
export const USERS_PAGE_DELETE_MENU_OPTION = 'USERS_PAGE_DELETE_MENU_OPTION';
export const USERS_PAGE_EDIT_MENU_OPTION = 'USERS_PAGE_EDIT_MENU_OPTION';
export const USERS_PAGE_OBJECTS_MENU_OPTION = 'USERS_PAGE_OBJECTS_MENU_OPTION';
export const USERS_PAGE_RESET_PASSWORD_MENU_OPTION = 'USERS_PAGE_RESET_PASSWORD_MENU_OPTION';
export const USERS_PAGE_UNBLOCK_MENU_OPTION = 'USERS_PAGE_UNBLOCK_MENU_OPTION';
export const FILTER_DROPDOWN_TITLE = 'FILTER_DROPDOWN_TITLE';
export const FILTER_DROPDOWN_CLEAR_BUTTON = 'FILTER_DROPDOWN_CLEAR_BUTTON';
export const SORT_TOOLTIP_ASCEND = 'SORT_TOOLTIP_ASCEND';
export const SORT_TOOLTIP_CANCEL = 'SORT_TOOLTIP_CANCEL';
export const SORT_TOOLTIP_DESCEND = 'SORT_TOOLTIP_DESCEND';
export const FILTER_ROLE_KEY_VALUE = 'FILTER_ROLE_KEY_VALUE';
export const FILTER_STATUS_KEY_VALUE = 'FILTER_STATUS_KEY_VALUE';
export const FILTER_NOTIFICATION_KEY_VALUE = 'FILTER_NOTIFICATION_KEY_VALUE';
export const FILTER_NOTIFICATION_DISABLED_VALUE = 'FILTER_NOTIFICATION_DISABLED_VALUE';
export const FILTER_NOTIFICATION_ENABLED_VALUE = 'FILTER_NOTIFICATION_ENABLED_VALUE';
export const FILTER_STATUS_ACTIVE_VALUE = 'FILTER_STATUS_ACTIVE_VALUE';
export const FILTER_STATUS_NOT_ACTIVE_VALUE = 'FILTER_STATUS_NOT_ACTIVE_VALUE';
export const FILTER_STATUS_WAITING_VALUE = 'FILTER_STATUS_WAITING_VALUE';
export const ROOMER_OBJECTS_PAGE_TITLE = 'ROOMER_OBJECTS_PAGE_TITLE';
export const USERS_OBJECTS_PAGE_GENERATE_OBJECT_BUTTON_VALUE = 'USERS_OBJECTS_PAGE_GENERATE_OBJECT_BUTTON_VALUE';
export const USERS_OBJECTS_PAGE_ADD_USER_MODAL_OBJECTS_TITLE = 'USERS_OBJECTS_PAGE_ADD_USER_MODAL_OBJECTS_TITLE';
export const USERS_OBJECTS_PAGE_TITLE = 'USERS_OBJECTS_PAGE_TITLE';
export const USERS_OBJECTS_PAGE_ADD_MODAl_BUTTON = 'USERS_OBJECTS_PAGE_ADD_MODAl_BUTTON';
export const USERS_OBJECTS_PAGE_FORM_FIELD_LABEL_ADD_ALL_BUILDINGS =
    'USERS_OBJECTS_PAGE_FORM_FIELD_LABEL_ADD_ALL_BUILDINGS';
export const USERS_OBJECTS_PAGE_FORM_RADIO_REQUIRED_ERROR = 'USERS_OBJECTS_PAGE_FORM_RADIO_REQUIRED_ERROR';
export const USERS_OBJECTS_PAGE_FORM_FIELD_VALUE_ADD_ALL_BUILDINGS_TRUE =
    'USERS_OBJECTS_PAGE_FORM_FIELD_VALUE_ADD_ALL_BUILDINGS_TRUE';
export const USERS_OBJECTS_PAGE_FORM_FIELD_VALUE_ADD_ALL_BUILDINGS_FALSE =
    'USERS_OBJECTS_PAGE_FORM_FIELD_VALUE_ADD_ALL_BUILDINGS_FALSE';
export const USERS_OBJECTS_PAGE_EDIT_USER_MODAL_OBJECTS_TITLE = 'USERS_OBJECTS_PAGE_EDIT_USER_MODAL_OBJECTS_TITLE';
export const USERS_OBJECTS_PAGE_EDIT_MODAl_BUTTON = 'USERS_OBJECTS_PAGE_EDIT_MODAl_BUTTON';
export const USERS_OBJECTS_PAGE_CANCEL_MODAl_BUTTON = 'USERS_OBJECTS_PAGE_CANCEL_MODAl_BUTTON';
export const USERS_OBJECTS_OPTIONS_MENU_EDIT = 'USERS_OBJECTS_OPTIONS_MENU_EDIT';
export const USERS_OBJECTS_OPTIONS_MENU_DELETE = 'USERS_OBJECTS_OPTIONS_MENU_DELETE';
export const USERS_PAGE_ROLE_TABLE_COLUMN_TITLE = 'USERS_PAGE_ROLE_TABLE_COLUMN_TITLE';
export const ROOMER_OBJECTS_PAGE_ORGANIZATION_NAME = 'ROOMER_OBJECTS_PAGE_ORGANIZATION_NAME';
export const ROOMER_OBJECTS_PAGE_CITY_NAME = 'ROOMER_OBJECTS_PAGE_CITY_NAME';
export const ROOMER_OBJECTS_PAGE_BUILDINGS_QUANTITY = 'ROOMER_OBJECTS_PAGE_BUILDINGS_QUANTITY';
export const NOTIFICATION_SUCCESS_ADD_OBJECT_TO_USER = 'NOTIFICATION_SUCCESS_ADD_OBJECT_TO_USER';
export const NOTIFICATION_SUCCESS_EDIT_USER_OBJECT = 'NOTIFICATION_SUCCESS_EDIT_USER_OBJECT';
export const NOTIFICATION_SUCCESS_DELETE_USER_OBJECT = 'NOTIFICATION_SUCCESS_DELETE_USER_OBJECT';
export const USERS_PAGE_FULLNAME_TABLE_COLUMN_TITLE = 'USERS_PAGE_FULLNAME_TABLE_COLUMN_TITLE';
export const USERS_PAGE_LOGIN_TABLE_COLUMN_TITLE = 'USERS_PAGE_LOGIN_TABLE_COLUMN_TITLE';
export const USERS_PAGE_COMPANY_TABLE_COLUMN_TITLE = 'USERS_PAGE_COMPANY_TABLE_COLUMN_TITLE';
export const USERS_PAGE_ADDRESS_TABLE_COLUMN_TITLE = 'USERS_PAGE_ADDRESS_TABLE_COLUMN_TITLE';
export const USERS_PAGE_ADD_USER_FORM_NAME_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_NAME_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_NAME_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_NAME_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_NAME_FIELD_ERROR_MESSAGE = 'USERS_PAGE_ADD_USER_FORM_NAME_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_ADD_USER_FORM_NAME_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_ADD_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_ERROR_MESSAGE = 'USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_ADD_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_ERROR_MESSAGE = 'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_4 =
    'USERS_PAGE_ADD_USER_FORM_EMAIL_FIELD_FEEDBACK_RULE_4';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_ERROR_MESSAGE = 'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_ADMIN_OPTION_LABEL =
    'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_ADMIN_OPTION_LABEL';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_SUPERADMIN_OPTION_LABEL =
    'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_SUPERADMIN_OPTION_LABEL';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_MANAGER_OPTION_LABEL =
    'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_MANAGER_OPTION_LABEL';
export const USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_RESIDENT_OPTION_LABEL =
    'USERS_PAGE_ADD_USER_FORM_ROLE_FIELD_RESIDENT_OPTION_LABEL';
export const USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_ADD_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_PLACEHOLDER =
    'USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_ADD_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_PLACEHOLDER =
    'USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_ADD_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_PLACEHOLDER = 'USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_ERROR_MESSAGE = 'USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_EDIT_USER_FORM_NAME_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_PLACEHOLDER =
    'USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_PLACEHOLDER = 'USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_PLACEHOLDER = 'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_1 =
    'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_1';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_2 =
    'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_2';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_3 =
    'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_3';
export const USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_4 =
    'USERS_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_4';
export const USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_PLACEHOLDER = 'USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_ACTIVE_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_ACTIVE_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_NOT_ACTIVE_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_NOT_ACTIVE_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_WAITING_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_STATUS_FIELD_WAITING_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_PLACEHOLDER = 'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_ERROR_MESSAGE = 'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_ADMIN_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_ADMIN_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_SUPERADMIN_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_SUPERADMIN_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_MANAGER_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_MANAGER_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_RESIDENT_OPTION_LABEL =
    'USERS_PAGE_EDIT_USER_FORM_ROLE_FIELD_RESIDENT_OPTION_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER =
    'USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER =
    'USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER =
    'USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_ADDRESS_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_ADDRESS_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_ADDRESS_FIELD_PLACEHOLDER =
    'USERS_PAGE_EDIT_USER_FORM_ADDRESS_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_ADDRESS_FIELD_ERROR_MESSAGE =
    'USERS_PAGE_EDIT_USER_FORM_ADDRESS_FIELD_ERROR_MESSAGE';
export const NOTIFICATION_FAILED_GLOBAL_CANT_GET_DATA = 'NOTIFICATION_FAILED_GLOBAL_CANT_GET_DATA';
export const USERS_PAGE_ADD_USER_FORM_CITY_FIELD_LABEL = 'USERS_PAGE_ADD_USER_FORM_CITY_FIELD_LABEL';
export const USERS_PAGE_ADD_USER_FORM_CITY_FIELD_PLACEHOLDER = 'USERS_PAGE_ADD_USER_FORM_CITY_FIELD_PLACEHOLDER';
export const USERS_PAGE_ADD_USER_FORM_CITY_FIELD_ERROR_MESSAGE = 'USERS_PAGE_ADD_USER_FORM_CITY_FIELD_ERROR_MESSAGE';
export const NOTIFICATION_SUCCESS_ADD_NEW_OBJECT = 'NOTIFICATION_SUCCESS_ADD_NEW_OBJECT';
export const NOTIFICATION_SUCCESS_RESET_PASSWORD = 'NOTIFICATION_SUCCESS_RESET_PASSWORD';
export const NOTIFICATION_WARNING_RESET_PASSWORD = 'NOTIFICATION_WARNING_RESET_PASSWORD';
export const NOTIFICATION_ERROR_RESET_PASSWORD = 'NOTIFICATION_ERROR_RESET_PASSWORD';
export const NOTIFICATION_FAILED_RESET_PASSWORD = 'NOTIFICATION_FAILED_RESET_PASSWORD';
export const NOTIFICATION_SUCCESS_ON_STATUS = 'NOTIFICATION_SUCCESS_ON_STATUS';
export const NOTIFICATION_SUCCESS_OFF_STATUS = 'NOTIFICATION_SUCCESS_OFF_STATUS';
export const NOTIFICATION_WARNING_ON_STATUS = 'NOTIFICATION_WARNING_ON_STATUS';
export const NOTIFICATION_ERROR_ON_STATUS = 'NOTIFICATION_ERROR_ON_STATUS';
export const NOTIFICATION_WARNING_OFF_STATUS = 'NOTIFICATION_WARNING_OFF_STATUS';
export const NOTIFICATION_ERROR_OFF_STATUS = 'NOTIFICATION_ERROR_OFF_STATUS';
export const NOTIFICATION_SUCCESS_BLOCK_USER = 'NOTIFICATION_SUCCESS_BLOCK_USER';
export const NOTIFICATION_SUCCESS_UNBLOCK_USER = 'NOTIFICATION_SUCCESS_UNBLOCK_USER';
export const NOTIFICATION_WARNING_BLOCK_USER = 'NOTIFICATION_WARNING_BLOCK_USER';
export const NOTIFICATION_ERROR_BLOCK_USER = 'NOTIFICATION_ERROR_BLOCK_USER';
export const NOTIFICATION_WARNING_UNBLOCK_USER = 'NOTIFICATION_WARNING_UNBLOCK_USER';
export const NOTIFICATION_ERROR_UNBLOCK_USER = 'NOTIFICATION_ERROR_UNBLOCK_USER';
export const NOTIFICATION_FAILED_SWITCH_STATUS = 'NOTIFICATION_FAILED_SWITCH_STATUS';
export const NOTIFICATION_SUCCESS_ADD_NEW_USER = 'NOTIFICATION_SUCCESS_ADD_NEW_USER';
export const NOTIFICATION_FAILED_ADD_NEW_USER = 'NOTIFICATION_FAILED_ADD_NEW_USER';
export const NOTIFICATION_SUCCESS_DELETE_USER = 'NOTIFICATION_SUCCESS_DELETE_USER';
export const NOTIFICATION_WARNING_DELETE_USER = 'NOTIFICATION_WARNING_DELETE_USER';
export const NOTIFICATION_ERROR_DELETE_USER = 'NOTIFICATION_ERROR_DELETE_USER';
export const NOTIFICATION_SUCCESS_DELETE_DATA_COLLECTOR = 'NOTIFICATION_SUCCESS_DELETE_DATA_COLLECTOR';
export const NOTIFICATION_WARNING_DELETE_DATA_COLLECTOR = 'NOTIFICATION_WARNING_DELETE_DATA_COLLECTOR';
export const NOTIFICATION_ERROR_DELETE_DATA_COLLECTOR = 'NOTIFICATION_ERROR_DELETE_DATA_COLLECTOR';
export const NOTIFICATION_FAILED_DELETE_USER = 'NOTIFICATION_FAILED_DELETE_USER';
export const NOTIFICATION_SUCCESS_APPROVE_USER = 'NOTIFICATION_SUCCESS_APPROVE_USER';
export const NOTIFICATION_FAILED_APPROVE_USER = 'NOTIFICATION_FAILED_APPROVE_USER';
export const NOTIFICATION_WARNING_APPROVE_USER = 'NOTIFICATION_WARNING_APPROVE_USER';
export const NOTIFICATION_ERROR_APPROVE_USER = 'NOTIFICATION_ERROR_APPROVE_USER';
export const NOTIFICATION_FAILED_GET_USERS_LIST = 'NOTIFICATION_FAILED_GET_USERS_LIST';
export const NOTIFICATION_ERROR_FETCHING_DATA = 'NOTIFICATION_ERROR_FETCHING_DATA';
export const NOTIFICATION_SUCCESS_ADD_CITY = 'NOTIFICATION_SUCCESS_ADD_CITY';
export const TABLE_EMPTY_DATA = 'TABLE_EMPTY_DATA';
export const ORGS_PAGE_COUNT_TITLE = 'ORGS_PAGE_COUNT_TITLE';
export const ORGS_PAGE_SEARCH_PLACEHOLDER = 'ORGS_PAGE_SEARCH_PLACEHOLDER';
export const ORGS_PAGE_TABLE_HEAD_TYPE = 'ORGS_PAGE_TABLE_HEAD_TYPE';
export const ORGS_PAGE_TABLE_HEAD_NAME = 'ORGS_PAGE_TABLE_HEAD_NAME';
export const ORGS_PAGE_TABLE_HEAD_STATUS = 'ORGS_PAGE_TABLE_HEAD_STATUS';
export const ORGS_PAGE_TABLE_HEAD_ADDRESS = 'ORGS_PAGE_TABLE_HEAD_ADDRESS';
export const ORGS_PAGE_TABLE_BUILDINGS_COUNT = 'ORGS_PAGE_TABLE_BUILDINGS_COUNT';
export const ORGS_PAGE_EDIT_MENU_OPTION = 'ORGS_PAGE_EDIT_MENU_OPTION';
export const ORGS_PAGE_COPY_MENU_OPTION = 'ORGS_PAGE_COPY_MENU_OPTION';
export const ORGS_PAGE_DELETE_MENU_OPTION = 'ORGS_PAGE_DELETE_MENU_OPTION';
export const ORGS_PAGE_BLOCK_MENU_OPTION = 'ORGS_PAGE_BLOCK_MENU_OPTION';
export const ORGS_PAGE_UNBLOCK_MENU_OPTION = 'ORGS_PAGE_UNBLOCK_MENU_OPTION';
export const ORGS_PAGE_MODAL_FORM_CREATE_TITLE = 'ORGS_PAGE_MODAL_FORM_CREATE_TITLE';
export const ORGS_PAGE_MODAL_FORM_EDIT_TITLE = 'ORGS_PAGE_MODAL_FORM_EDIT_TITLE';
export const ORGS_PAGE_MODAL_FORM_COPY_TITLE = 'ORGS_PAGE_MODAL_FORM_COPY_TITLE';
export const ORGS_PAGE_MODAL_FORM_CANCEL_BTN_TEXT = 'ORGS_PAGE_MODAL_FORM_CANCEL_BTN_TEXT';
export const ORGS_PAGE_MODAL_FORM_CREATE_BTN_TEXT = 'ORGS_PAGE_MODAL_FORM_CREATE_BTN_TEXT';
export const ORGS_PAGE_MODAL_FORM_EDIT_BTN_TEXT = 'ORGS_PAGE_MODAL_FORM_EDIT_BTN_TEXT';
export const ORGS_PAGE_MODAL_FORM_COPY_BTN_TEXT = 'ORGS_PAGE_MODAL_FORM_COPY_BTN_TEXT';
export const ORGS_PAGE_MODAL_FORM_FIELD_LABEL_NAME = 'ORGS_PAGE_MODAL_FORM_FIELD_LABEL_NAME';
export const ORGS_PAGE_MODAL_FORM_FIELD_LABEL_ADDRESS = 'ORGS_PAGE_MODAL_FORM_FIELD_LABEL_ADDRESS';
export const ORGS_PAGE_MODAL_FORM_FIELD_LABEL_CITY = 'ORGS_PAGE_MODAL_FORM_FIELD_LABEL_CITY';
export const ORGS_PAGE_MODAL_FORM_FIELD_LABEL_TYPE = 'ORGS_PAGE_MODAL_FORM_FIELD_LABEL_TYPE';
export const ORGS_PAGE_MODAL_FORM_FIELD_LABEL_STATUS = 'ORGS_PAGE_MODAL_FORM_FIELD_LABEL_STATUS';
export const ORGS_PAGE_NOTIFICATION_CREATE_SUCCESS = 'ORGS_PAGE_NOTIFICATION_CREATE_SUCCESS';
export const ORGS_PAGE_NOTIFICATION_EDIT_SUCCESS = 'ORGS_PAGE_NOTIFICATION_EDIT_SUCCESS';
export const ORGS_PAGE_NOTIFICATION_COPY_SUCCESS = 'ORGS_PAGE_NOTIFICATION_COPY_SUCCESS';
export const ORGS_PAGE_NOTIFICATION_DELETE_SUCCESS = 'ORGS_PAGE_NOTIFICATION_DELETE_SUCCESS';
export const ORGS_PAGE_NOTIFICATION_CREATE_ERROR = 'ORGS_PAGE_NOTIFICATION_CREATE_ERROR';
export const ORGS_PAGE_NOTIFICATION_EDIT_ERROR = 'ORGS_PAGE_NOTIFICATION_EDIT_ERROR';
export const ORGS_PAGE_NOTIFICATION_COPY_ERROR = 'ORGS_PAGE_NOTIFICATION_COPY_ERROR';
export const ORGS_PAGE_NOTIFICATION_DELETE_ERROR = 'ORGS_PAGE_NOTIFICATION_DELETE_ERROR';
export const ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_NAME = 'ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_NAME';
export const ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_ADDRESS = 'ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_ADDRESS';
export const ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_CITY = 'ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_CITY';
export const ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_TYPE = 'ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_TYPE';
export const ORGS_PAGE_NOTIFICATION_BLOCK_SUCCESS = 'ORGS_PAGE_NOTIFICATION_BLOCK_SUCCESS';
export const ORGS_PAGE_NOTIFICATION_UNBLOCK_SUCCESS = 'ORGS_PAGE_NOTIFICATION_UNBLOCK_SUCCESS';
export const ORGS_PAGE_NOTIFICATION_BLOCK_ERROR = 'ORGS_PAGE_NOTIFICATION_BLOCK_ERROR';
export const ORGS_PAGE_NOTIFICATION_UNBLOCK_ERROR = 'ORGS_PAGE_NOTIFICATION_UNBLOCK_ERROR';
export const ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_1 = 'ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_1';
export const ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_2 = 'ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_2';
export const ORGS_PAGE_ORG_FORM_FIELD_ERROR_MESSAGE = 'ORGS_PAGE_ORG_FORM_FIELD_ERROR_MESSAGE';
export const ORGS_STATUS_TEXT_ACTIVE = 'ORGS_STATUS_TEXT_ACTIVE';
export const ORGS_STATUS_TEXT_BLOCKED = 'ORGS_STATUS_TEXT_BLOCKED';
export const ORGS_PAGE_ORG_FILTER_NAME_TYPE = 'ORGS_PAGE_ORG_FILTER_NAME_TYPE';
export const ORGS_PAGE_ORG_FILTER_NAME_STATUS = 'ORGS_PAGE_ORG_FILTER_NAME_STATUS';
export const SERVICES_MODAL_FORM_BUILDING_PLACEHOLDER = 'SERVICES_MODAL_FORM_BUILDING_PLACEHOLDER';
export const SERVICES_MODAL_FORM_BUILDING_LABEL = 'SERVICES_MODAL_FORM_BUILDING_LABEL';
export const SERVICES_MODAL_FORM_COMPANY_PLACEHOLDER = 'SERVICES_MODAL_FORM_COMPANY_PLACEHOLDER';
export const SERVICES_MODAL_FORM_COMPANY_LABEL = 'SERVICES_MODAL_FORM_COMPANY_LABEL';
export const SERVICES_MODAL_FORM_REPORT_TYPE_LABEL = 'SERVICES_MODAL_FORM_REPORT_TYPE_LABEL';
export const SERVICES_MODAL_FORM_REPORT_TYPE_PLACEHOLDER = 'SERVICES_MODAL_FORM_REPORT_TYPE_PLACEHOLDER';
export const SERVICES_MODAL_FORM_REPORT_DEVICE_TYPE_PLACEHOLDER = 'SERVICES_MODAL_FORM_REPORT_DEVICE_TYPE_PLACEHOLDER';
export const SERVICES_MODAL_FORM_REPORT_DEVICE_TYPE_LABEL = 'SERVICES_MODAL_FORM_REPORT_DEVICE_TYPE_LABEL';
export const SERVICES_MODAL_FORM_REPORT_DATE_LABEL = 'SERVICES_MODAL_FORM_REPORT_DATE_LABEL';
export const SERVICES_MODAL_FORM_REPORT_DATE_PLACEHOLDER = 'SERVICES_MODAL_FORM_REPORT_DATE_PLACEHOLDER';
export const SERVICES_MODAL_FORM_REPORT_EMAIL_LABEL = 'SERVICES_MODAL_FORM_REPORT_EMAIL_LABEL';
export const SERVICES_MODAL_FORM_REPORT_EMAIL_PLACEHOLDER = 'SERVICES_MODAL_FORM_REPORT_EMAIL_PLACEHOLDER';
export const SERVICES_MODAL_FORM_REPORT_EMAIL_VALIDATION_ERROR = 'SERVICES_MODAL_FORM_REPORT_EMAIL_VALIDATION_ERROR';
export const SERVICES_PAGE_TITLE = 'SERVICES_PAGE_TITLE';
export const SERVICE_CARD_STAT_SENT = 'SERVICE_CARD_STAT_SENT';
export const SERVICE_CARD_SENT_ARCHIVED = 'SERVICE_CARD_SENT_ARCHIVED';
export const SERVICE_CARD_SENT_DELETED = 'SERVICE_CARD_SENT_DELETED';
export const SERVICE_CARD_SENT_ERRORS = 'SERVICE_CARD_SENT_ERRORS';
export const SERVICE_GENERATE_REPORTS_TITLE = 'SERVICE_GENERATE_REPORTS_TITLE';
export const SERVICE_GENERATE_REPORTS_DESCRIPTION = 'SERVICE_GENERATE_REPORTS_DESCRIPTION';
export const SERVICE_REPORTS_AUTOSEND_TITLE = 'SERVICE_REPORTS_AUTOSEND_TITLE';
export const SERVICE_REPORTS_AUTOSEND_DESCRIPTION = 'SERVICE_REPORTS_AUTOSEND_DESCRIPTION';
export const SERVICE_REPORTS_COLLECT_DATA_TITLE = 'SERVICE_REPORTS_COLLECT_DATA_TITLE';
export const SERVICE_REPORTS_COLLECT_DATA_DESCRIPTION = 'SERVICE_REPORTS_COLLECT_DATA_DESCRIPTION';
export const SERVICE_REPORTS_NOTIFICATIONS_TITLE = 'SERVICE_REPORTS_NOTIFICATIONS_TITLE';
export const SERVICE_REPORTS_NOTIFICATIONS_DESCRIPTION = 'SERVICE_REPORTS_NOTIFICATIONS_DESCRIPTION';
export const SERVICE_REPORTS_KTE_TITLE = 'SERVICE_REPORTS_KTE_TITLE';
export const SERVICE_REPORTS_KTE_DESCRIPTION = 'SERVICE_REPORTS_KTE_DESCRIPTION';
export const SERVICE_REPORTS_VODOKANAL_TITLE = 'SERVICE_REPORTS_VODOKANAL_TITLE';
export const SERVICE_REPORTS_VODOKANAL_DESCRIPTION = 'SERVICE_REPORTS_VODOKANAL_DESCRIPTION';
export const SERVICE_ADD_ULCS_TO_BUILDING_LABEL = 'SERVICE_ADD_ULCS_TO_BUILDING_LABEL';
export const SERVICE_ADD_ULCS_TO_BUILDING_PLACEHOLDER = 'SERVICE_ADD_ULCS_TO_BUILDING_PLACEHOLDER';
export const SERVICE_ADD_ULCS_TO_BUILDING_ERROR = 'SERVICE_ADD_ULCS_TO_BUILDING_ERROR';
export const SERVICE_ADD_ULCS_TO_BUILDING_TITLE = 'SERVICE_ADD_ULCS_TO_BUILDING_TITLE';
export const SERVICE_EDIT_ULCS_TO_BUILDING_TITLE = 'SERVICE_EDIT_ULCS_TO_BUILDING_TITLE';
export const SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_LABEL = 'SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_ERROR = 'SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_ERROR';
export const SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_LABEL = 'SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_PLACEHOLDER = 'SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_ERROR = 'SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_ERROR';
export const SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_LABEL = 'SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_ERROR = 'SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_ERROR';
export const SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_LABEL = 'SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_ERROR = 'SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_LABEL =
    'SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_ERROR =
    'SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_LABEL =
    'SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_ERROR =
    'SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_LABEL =
    'SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_LABEL';
export const SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_ERROR =
    'SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_ERROR';
export const SERVICE_CONFIGURE_KTE_FORM_CANCEL_BUTTON = 'SERVICE_CONFIGURE_KTE_FORM_CANCEL_BUTTON';
export const SERVICE_CONFIGURE_KTE_FORM_SUBMIT_BUTTON = 'SERVICE_CONFIGURE_KTE_FORM_SUBMIT_BUTTON';
export const SERVICE_CONFIGURE_KTE_FORM_TITLE = 'SERVICE_CONFIGURE_KTE_FORM_TITLE';
export const SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_LABEL =
    'SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_LABEL';
export const SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_ERROR =
    'SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_ERROR';
export const SERVICE_KTE_STATISTIC_DEVICES_COUNT_FIELD_LABEL = 'SERVICE_KTE_STATISTIC_DEVICES_COUNT_FIELD_LABEL';
export const SERVICE_KTE_STATISTIC_SUCCEEDED_FIELD_LABEL = 'SERVICE_KTE_STATISTIC_SUCCEEDED_FIELD_LABEL';
export const SERVICE_KTE_STATISTIC_FAILED_FIELD_LABEL = 'SERVICE_KTE_STATISTIC_FAILED_FIELD_LABEL';
export const SERVICE_KTE_STATISTIC_DATE_FIELD_LABEL = 'SERVICE_KTE_STATISTIC_DATE_FIELD_LABEL';
export const SERVICE_KTE_STATISTIC_RESULT_STATUS_FIELD_LABEL = 'SERVICE_KTE_STATISTIC_RESULT_STATUS_FIELD_LABEL';
export const SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_SUCCESS =
    'SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_SUCCESS';
export const SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_XLSX = 'SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_XLSX';
export const SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_PDF = 'SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_PDF';
export const SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_FAILED =
    'SERVICE_KTE_STATISTIC_RESULT_STATUS_DOWNLOAD_FAILED';
export const SERVICE_KTE_STATUS_TEXT_FIELD_LABEL = 'SERVICE_KTE_STATUS_TEXT_FIELD_LABEL';
export const SERVICE_KTE_NO_ACCESS = 'SERVICE_KTE_NO_ACCESS';
export const SERVICE_KTE_STATUS_MESSAGE_1 = 'SERVICE_KTE_STATUS_MESSAGE_1';
export const SERVICE_KTE_STATUS_MESSAGE_2 = 'SERVICE_KTE_STATUS_MESSAGE_2';
export const SERVICE_KTE_STATUS_MESSAGE_3 = 'SERVICE_KTE_STATUS_MESSAGE_3';
export const SERVICE_KTE_STATUS_MESSAGE_4 = 'SERVICE_KTE_STATUS_MESSAGE_4';
export const SERVICE_KTE_STATUS_MESSAGE_5 = 'SERVICE_KTE_STATUS_MESSAGE_5';
export const SERVICE_KTE_STATUS_MESSAGE_6 = 'SERVICE_KTE_STATUS_MESSAGE_6';
export const SERVICE_KTE_STATUS_MESSAGE_7 = 'SERVICE_KTE_STATUS_MESSAGE_7';
export const SERVICE_KTE_STATUS_MESSAGE_8 = 'SERVICE_KTE_STATUS_MESSAGE_8';
export const SERVICE_KTE_STATUS_MESSAGE_9 = 'SERVICE_KTE_STATUS_MESSAGE_9';
export const SERVICE_KTE_STATUS_MESSAGE_10 = 'SERVICE_KTE_STATUS_MESSAGE_10';
export const SERVICE_KTE_PAGE_OPTIONS_MENU_EDIT_ULC = 'SERVICE_KTE_PAGE_OPTIONS_MENU_EDIT_ULC';
export const SERVICE_KTE_PAGE_EDIT_ULC_SUCCESS = 'SERVICE_KTE_PAGE_EDIT_ULC_SUCCESS';
export const SERVICE_KTE_PAGE_ADD_ULC_SUCCESS = 'SERVICE_KTE_PAGE_ADD_ULC_SUCCESS';
export const SERVICE_KTE_PAGE_DELETE_ULC_SUCCESS = 'SERVICE_KTE_PAGE_DELETE_ULC_SUCCESS';
export const SERVICE_KTE_PAGE_UNAPPLIED_BILLING_COLUMN_TITLE = 'SERVICE_KTE_PAGE_UNAPPLIED_BILLING_COLUMN_TITLE';
export const SERVICE_KTE_PAGE_APPLIED_BILLING_COLUMN_TITLE = 'SERVICE_KTE_PAGE_APPLIED_BILLING_COLUMN_TITLE';
export const SERVICE_KVK_PAGE_UNAPPLIED_BILLING_COLUMN_TITLE = 'SERVICE_KVK_PAGE_UNAPPLIED_BILLING_COLUMN_TITLE';
export const SERVICE_KVK_PAGE_APPLIED_BILLING_COLUMN_TITLE = 'SERVICE_KVK_PAGE_APPLIED_BILLING_COLUMN_TITLE';
export const SERVICE_KTE_OPTIONS_MENU_EDIT = 'SERVICE_KTE_OPTIONS_MENU_EDIT';
export const SERVICE_KTE_OPTIONS_MENU_ON = 'SERVICE_KTE_OPTIONS_MENU_ON';
export const SERVICE_KTE_OPTIONS_MENU_OFF = 'SERVICE_KTE_OPTIONS_MENU_OFF';
export const SERVICE_KTE_STATUS_IDLE = 'SERVICE_KTE_STATUS_IDLE';
export const SERVICE_KTE_STATUS_INPROGRESS = 'SERVICE_KTE_STATUS_INPROGRESS';
export const SERVICE_KTE_STATUS_DISABLED = 'SERVICE_KTE_STATUS_DISABLED';
export const SERVICE_KTE_STATUS_NO_ACCESS = 'SERVICE_KTE_STATUS_NO_ACCESS';
export const CONFIRM_MODAL_TITLE_KTE_ON = 'CONFIRM_MODAL_TITLE_KTE_ON';
export const CONFIRM_MODAL_TITLE_KTE_OFF = 'CONFIRM_MODAL_TITLE_KTE_OFF';
export const NOTIFICATION_SUCCESS_SERVICE_KTE_OFF = 'NOTIFICATION_SUCCESS_SERVICE_KTE_OFF';
export const NOTIFICATION_SUCCESS_SERVICE_KTE_ON = 'NOTIFICATION_SUCCESS_SERVICE_KTE_ON';
export const SERVICE_ARS_ORGANIZATIONS_PAGE_TITLE = 'SERVICE_ARS_ORGANIZATIONS_PAGE_TITLE';
export const SERVICE_ARS_ORGANIZATION_PAGE_TITLE = 'SERVICE_ARS_ORGANIZATION_PAGE_TITLE';
export const SERVICE_LVK_STATUS_MESSAGE_1 = 'SERVICE_LVK_STATUS_MESSAGE_1';
export const SERVICE_LVK_STATUS_MESSAGE_2 = 'SERVICE_LVK_STATUS_MESSAGE_2';
export const SERVICE_LVK_STATUS_MESSAGE_3 = 'SERVICE_LVK_STATUS_MESSAGE_3';
export const SERVICE_LVK_STATUS_MESSAGE_4 = 'SERVICE_LVK_STATUS_MESSAGE_4';
export const SERVICE_LVK_STATUS_MESSAGE_5 = 'SERVICE_LVK_STATUS_MESSAGE_5';
export const SERVICE_LVK_STATUS_MESSAGE_6 = 'SERVICE_LVK_STATUS_MESSAGE_6';
export const SERVICE_LVK_STATUS_MESSAGE_7 = 'SERVICE_LVK_STATUS_MESSAGE_7';
export const SERVICE_LVK_PAGE_ADD_SUCCESS = 'SERVICE_LVK_PAGE_ADD_SUCCESS';
export const SERVICE_LVK_PAGE_REMOVE_SUCCESS = 'SERVICE_LVK_PAGE_REMOVE_SUCCESS';
export const SERVICE_LVK_TITLE = 'SERVICE_LVK_TITLE';
export const SERVICE_LVK_DESCRIPTION = 'SERVICE_LVK_DESCRIPTION';
export const NOTIFICATION_SUCCESS_SERVICE_LVK_OFF = 'NOTIFICATION_SUCCESS_SERVICE_LVK_OFF';
export const NOTIFICATION_SUCCESS_SERVICE_LVK_ON = 'NOTIFICATION_SUCCESS_SERVICE_LVK_ON';
export const CONFIRM_MODAL_TITLE_LVK_ON = 'CONFIRM_MODAL_TITLE_LVK_ON';
export const CONFIRM_MODAL_TITLE_LVK_OFF = 'CONFIRM_MODAL_TITLE_LVK_OFF';
export const CONFIRM_MODAL_TITLE_LVK_MOVE = 'CONFIRM_MODAL_TITLE_LVK_MOVE';
export const SERVICE_LVK_OPTIONS_MENU_ON = 'SERVICE_LVK_OPTIONS_MENU_ON';
export const SERVICE_LVK_OPTIONS_MENU_OFF = 'SERVICE_LVK_OPTIONS_MENU_OFF';
export const SERVICE_LVK_STATUS_IDLE = 'SERVICE_LVK_STATUS_IDLE';
export const SERVICE_LVK_STATUS_INPROGRESS = 'SERVICE_LVK_STATUS_INPROGRESS';
export const SERVICE_LVK_STATUS_DISABLED = 'SERVICE_LVK_STATUS_DISABLED';
export const SERVICE_LVK_NO_ACCESS = 'SERVICE_LVK_NO_ACCESS';
export const SERVICE_LVK_OPTIONS_MENU_EDIT = 'SERVICE_LVK_OPTIONS_MENU_EDIT';
export const SERVICE_CONFIGURE_LVK_FORM_COMPANY_FIELD_LABEL = 'SERVICE_CONFIGURE_LVK_FORM_COMPANY_FIELD_LABEL';
export const SERVICE_CONFIGURE_LVK_FORM_COMPANY_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_LVK_FORM_COMPANY_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_LVK_FORM_COMPANY_FIELD_ERROR = 'SERVICE_CONFIGURE_LVK_FORM_COMPANY_FIELD_ERROR';
export const SERVICE_CONFIGURE_LVK_FORM_APP_FIELD_LABEL = 'SERVICE_CONFIGURE_LVK_FORM_APP_FIELD_LABEL';
export const SERVICE_CONFIGURE_LVK_FORM_APP_FIELD_PLACEHOLDER = 'SERVICE_CONFIGURE_LVK_FORM_APP_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_LVK_FORM_APP_FIELD_ERROR = 'SERVICE_CONFIGURE_LVK_FORM_APP_FIELD_ERROR';
export const SERVICE_CONFIGURE_LVK_FORM_BASE_URL_FIELD_LABEL = 'SERVICE_CONFIGURE_LVK_FORM_BASE_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_LVK_FORM_BASE_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_LVK_FORM_BASE_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_LVK_FORM_BASE_URL_FIELD_ERROR = 'SERVICE_CONFIGURE_LVK_FORM_BASE_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_LVK_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_LABEL =
    'SERVICE_CONFIGURE_LVK_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_LVK_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_LVK_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_LVK_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_ERROR =
    'SERVICE_CONFIGURE_LVK_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_LVK_FORM_CANCEL_BUTTON = 'SERVICE_CONFIGURE_LVK_FORM_CANCEL_BUTTON';
export const SERVICE_CONFIGURE_LVK_FORM_SUBMIT_BUTTON = 'SERVICE_CONFIGURE_LVK_FORM_SUBMIT_BUTTON';
export const SERVICE_CONFIGURE_LVK_FORM_TITLE = 'SERVICE_CONFIGURE_LVK_FORM_TITLE';
export const SERVICE_LVK_STATISTIC_DEVICES_COUNT_FIELD_LABEL = 'SERVICE_LVK_STATISTIC_DEVICES_COUNT_FIELD_LABEL';
export const SERVICE_LVK_STATISTIC_SUCCEEDED_FIELD_LABEL = 'SERVICE_LVK_STATISTIC_SUCCEEDED_FIELD_LABEL';
export const SERVICE_LVK_STATISTIC_FAILED_FIELD_LABEL = 'SERVICE_LVK_STATISTIC_FAILED_FIELD_LABEL';
export const SERVICE_LVK_STATISTIC_DATE_FIELD_LABEL = 'SERVICE_LVK_STATISTIC_DATE_FIELD_LABEL';
export const SERVICE_LVK_STATISTIC_RESULT_STATUS_FIELD_LABEL = 'SERVICE_LVK_STATISTIC_RESULT_STATUS_FIELD_LABEL';
export const SERVICE_LVK_STATUS_TEXT_FIELD_LABEL = 'SERVICE_LVK_STATUS_TEXT_FIELD_LABEL';
export const SERVICE_KVK_STATUS_MESSAGE_1 = 'SERVICE_KVK_STATUS_MESSAGE_1';
export const SERVICE_KVK_STATUS_MESSAGE_2 = 'SERVICE_KVK_STATUS_MESSAGE_2';
export const SERVICE_KVK_STATUS_MESSAGE_3 = 'SERVICE_KVK_STATUS_MESSAGE_3';
export const SERVICE_KVK_STATUS_MESSAGE_4 = 'SERVICE_KVK_STATUS_MESSAGE_4';
export const SERVICE_KVK_STATUS_MESSAGE_5 = 'SERVICE_KVK_STATUS_MESSAGE_5';
export const SERVICE_KVK_STATUS_MESSAGE_6 = 'SERVICE_KVK_STATUS_MESSAGE_6';
export const SERVICE_KVK_STATUS_MESSAGE_7 = 'SERVICE_KVK_STATUS_MESSAGE_7';
export const SERVICE_KVK_STATUS_MESSAGE_8 = 'SERVICE_KVK_STATUS_MESSAGE_8';
export const SERVICE_KVK_TITLE = 'SERVICE_KVK_TITLE';
export const SERVICE_CONFIGURE_KVK_FORM_CANCEL_BUTTON = 'SERVICE_CONFIGURE_KVK_FORM_CANCEL_BUTTON';
export const SERVICE_CONFIGURE_KVK_FORM_SUBMIT_BUTTON = 'SERVICE_CONFIGURE_KVK_FORM_SUBMIT_BUTTON';
export const SERVICE_CONFIGURE_KVK_FORM_TITLE = 'SERVICE_CONFIGURE_KVK_FORM_TITLE';
export const SERVICE_KVK_OPTIONS_MENU_EDIT = 'SERVICE_KVK_OPTIONS_MENU_EDIT';
export const NOTIFICATION_SUCCESS_SERVICE_KVK_OFF = 'NOTIFICATION_SUCCESS_SERVICE_KVK_OFF';
export const NOTIFICATION_SUCCESS_SERVICE_KVK_ON = 'NOTIFICATION_SUCCESS_SERVICE_KVK_ON';
export const CONFIRM_MODAL_TITLE_KVK_ON = 'CONFIRM_MODAL_TITLE_KVK_ON';
export const CONFIRM_MODAL_TITLE_KVK_OFF = 'CONFIRM_MODAL_TITLE_KVK_OFF';
export const CONFIRM_MODAL_TITLE_KVK_MOVE = 'CONFIRM_MODAL_TITLE_KVK_MOVE';
export const SERVICE_KVK_OPTIONS_MENU_ON = 'SERVICE_KVK_OPTIONS_MENU_ON';
export const SERVICE_KVK_PAGE_ADD_SUCCESS = 'SERVICE_KVK_PAGE_ADD_SUCCESS';
export const SERVICE_KVK_PAGE_REMOVE_SUCCESS = 'SERVICE_KVK_PAGE_REMOVE_SUCCESS';
export const SERVICE_KVK_OPTIONS_MENU_OFF = 'SERVICE_KVK_OPTIONS_MENU_OFF';
export const SERVICE_KVK_NO_ACCESS = 'SERVICE_KVK_NO_ACCESS';
export const SERVICE_KVK_DESCRIPTION = 'SERVICE_KVK_DESCRIPTION';
export const SERVICE_KVK_STATISTIC_DEVICES_COUNT_FIELD_LABEL = 'SERVICE_KVK_STATISTIC_DEVICES_COUNT_FIELD_LABEL';
export const SERVICE_KVK_STATISTIC_SUCCEEDED_FIELD_LABEL = 'SERVICE_KVK_STATISTIC_SUCCEEDED_FIELD_LABEL';
export const SERVICE_KVK_STATISTIC_FAILED_FIELD_LABEL = 'SERVICE_KVK_STATISTIC_FAILED_FIELD_LABEL';
export const SERVICE_KVK_STATISTIC_DATE_FIELD_LABEL = 'SERVICE_KVK_STATISTIC_DATE_FIELD_LABEL';
export const SERVICE_KVK_STATISTIC_RESULT_STATUS_FIELD_LABEL = 'SERVICE_KVK_STATISTIC_RESULT_STATUS_FIELD_LABEL';
export const SERVICE_KVK_STATUS_TEXT_FIELD_LABEL = 'SERVICE_KVK_STATUS_TEXT_FIELD_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_LABEL = 'SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_ERROR = 'SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_ERROR';
export const SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_LABEL = 'SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_PLACEHOLDER =
    'SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_PLACEHOLDER';
export const SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_LABEL =
    'SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_ERROR =
    'SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_LABEL =
    'SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_ERROR =
    'SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_LABEL = 'SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_ERROR = 'SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_ERROR';
export const SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_LABEL =
    'SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_LABEL';
export const SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER =
    'SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER';
export const SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_ERROR =
    'SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_ERROR';
export const SERVICE_CONFIGURE_KVK_FORM_NOTIFICATION_EDIT_SUCCESS =
    'SERVICE_CONFIGURE_KVK_FORM_NOTIFICATION_EDIT_SUCCESS';
export const CONFIRM_MODAL_TITLE_ARS_OFF = 'CONFIRM_MODAL_TITLE_ARS_OFF';
export const CONFIRM_MODAL_TITLE_ARS_ON = 'CONFIRM_MODAL_TITLE_ARS_ON';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_OFF = 'NOTIFICATION_SUCCESS_SERVICE_ARS_OFF';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_ON = 'NOTIFICATION_SUCCESS_SERVICE_ARS_ON';
export const SERVICE_ARS_OPTIONS_MENU_ON = 'SERVICE_ARS_OPTIONS_MENU_ON';
export const SERVICE_ARS_OPTIONS_MENU_OFF = 'SERVICE_ARS_OPTIONS_MENU_OFF';
export const SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_ON = 'SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_ON';
export const SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_OFF = 'SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_OFF';
export const SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_EDIT = 'SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_EDIT';
export const SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_DELETE = 'SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_DELETE';
export const SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_STATUS = 'SERVICE_ARS_ORGANIZATION_OPTIONS_MENU_STATUS';
export const CONFIRM_MODAL_TITLE_ARS_ORGANIZATION_ON = 'CONFIRM_MODAL_TITLE_ARS_ORGANIZATION_ON';
export const CONFIRM_MODAL_TITLE_ARS_ORGANIZATION_OFF = 'CONFIRM_MODAL_TITLE_ARS_ORGANIZATION_OFF';
export const SERVICE_ARS_ORGANIZATION_EDIT_FORM_TITLE = 'SERVICE_ARS_ORGANIZATION_EDIT_FORM_TITLE';
export const SERVICE_ARS_ORGANIZATION_ADD_FORM_TITLE = 'SERVICE_ARS_ORGANIZATION_ADD_FORM_TITLE';
export const SERVICE_ARS_BUILDINGS_SETTINGS_MODAL_TITLE = 'SERVICE_ARS_BUILDINGS_SETTINGS_MODAL_TITLE';
export const SERVICE_ARS_BUILDINGS_SETTINGS_CANCEL_MODAl_BUTTON = 'SERVICE_ARS_BUILDINGS_SETTINGS_CANCEL_MODAl_BUTTON';
export const SERVICE_ARS_BUILDINGS_SETTINGS_SUBMIT_MODAl_BUTTON = 'SERVICE_ARS_BUILDINGS_SETTINGS_SUBMIT_MODAl_BUTTON';
export const SERVICE_ARS_ORGANIZATION_EDIT_FORM_SUBMIT_BUTTON = 'SERVICE_ARS_ORGANIZATION_EDIT_FORM_SUBMIT_BUTTON';
export const SERVICE_ARS_ORGANIZATION_ADD_FORM_SUBMIT_BUTTON = 'SERVICE_ARS_ORGANIZATION_ADD_FORM_SUBMIT_BUTTON';
export const SERVICE_ARS_ORGANIZATION_FORM_CANCEL_BUTTON = 'SERVICE_ARS_ORGANIZATION_FORM_CANCEL_BUTTON';
export const SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_LABEL = 'SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_PLACEHOLDER';
export const SERVICE_ARS_STATISTICS_ORGANIZATION_DATE_COLUMN_TITLE =
    'SERVICE_ARS_STATISTICS_ORGANIZATION_DATE_COLUMN_TITLE';
export const ARS_INFO_MODAL_TITLE_TEXT = 'ARS_INFO_MODAL_TITLE_TEXT';
export const SERVICE_ARS_STATUS_TEXT_FIELD_LABEL = 'SERVICE_ARS_STATUS_TEXT_FIELD_LABEL';
export const SERVICE_ARS_STATISTICS_ORGANIZATION_BUILDING_COLUMN_TITLE =
    'SERVICE_ARS_STATISTICS_ORGANIZATION_BUILDING_COLUMN_TITLE';
export const SERVICE_ARS_STATISTICS_ORGANIZATION_STATUS_COLUMN_TITLE =
    'SERVICE_ARS_STATISTICS_ORGANIZATION_STATUS_COLUMN_TITLE';
export const SERVICE_PAGE_BREADCRUMBS_TITLE = 'SERVICE_PAGE_BREADCRUMBS_TITLE';
export const SERVICE_ARS_ORGANIZATIONS_PAGE_BREADCRUMBS_TITLE = 'SERVICE_ARS_ORGANIZATIONS_PAGE_BREADCRUMBS_TITLE';
export const SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_ERROR_MESSAGE =
    'SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_ERROR_MESSAGE';
export const SERVICE_ARS_ORGANIZATION_FORM_COMPANY_FIELD_LABEL = 'SERVICE_ARS_ORGANIZATION_FORM_COMPANY_FIELD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_ERROR_MESSAGE =
    'SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_ERROR_MESSAGE';
export const SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_REPORT_FORM_TYPE_FIELD_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_REPORT_FORM_TYPE_FIELD_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_LABEL = 'SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_ERROR = 'SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_ADD_ALL_BUILDINGS_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_ADD_ALL_BUILDINGS_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_VALUE_ADD_ALL_BUILDINGS_TRUER =
    'SERVICE_ARS_ORGANIZATION_FORM_VALUE_ADD_ALL_BUILDINGS_TRUER';
export const SERVICE_ARS_ORGANIZATION_FORM_VALUE_ADD_ALL_BUILDINGS_FALSE =
    'SERVICE_ARS_ORGANIZATION_FORM_VALUE_ADD_ALL_BUILDINGS_FALSE';
export const SERVICE_ARS_ORGANIZATION_FORM_ADD_ALL_BUILDINGS_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_ADD_ALL_BUILDINGS_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_FTP = 'SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_FTP';
export const SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_EMAIL =
    'SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_EMAIL';
export const SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_LABEL = 'SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_ERROR = 'SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PLACEHOLDER_FOLDER_PATH_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PLACEHOLDER_FOLDER_PATH_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_ERROR =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_ERROR';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_LABEL =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_LABEL';
export const SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER =
    'SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER';
export const SERVICE_ARS_ORGANIZATION_FORM_NOTIFICATION_EDIT_SUCCESS =
    'SERVICE_ARS_ORGANIZATION_FORM_NOTIFICATION_EDIT_SUCCESS';
export const SERVICE_ARS_ORGANIZATION_FORM_NOTIFICATION_ADD_SUCCESS =
    'SERVICE_ARS_ORGANIZATION_FORM_NOTIFICATION_ADD_SUCCESS';
export const SERVICE_ARS_ORGANIZATION_PAGE_TABLE_BUILDINGS_COUNT =
    'SERVICE_ARS_ORGANIZATION_PAGE_TABLE_BUILDINGS_COUNT';
export const SERVICE_ARS_ORGANIZATION_PAGE_TABLE_BUILDINGS_COUNT_TITLE =
    'SERVICE_ARS_ORGANIZATION_PAGE_TABLE_BUILDINGS_COUNT_TITLE';
export const SERVICE_ARS_ORGANIZATION_PAGE_TABLE_HEAD_TYPE = 'SERVICE_ARS_ORGANIZATION_PAGE_TABLE_HEAD_TYPE';
export const SERVICE_ARS_ORGANIZATION_PAGE_TABLE_HEAD_NAME = 'SERVICE_ARS_ORGANIZATION_PAGE_TABLE_HEAD_NAME';
export const SERVICE_ARS_ORGANIZATION_PAGE_TABLE_HEAD_ADDRESS = 'SERVICE_ARS_ORGANIZATION_PAGE_TABLE_HEAD_ADDRESS';
export const NOTIFICATION_SERVICE_FORM_FIELD_ERROR = 'NOTIFICATION_SERVICE_FORM_FIELD_ERROR';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_ORGANIZATION_OFF = 'NOTIFICATION_SUCCESS_SERVICE_ARS_ORGANIZATION_OFF';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_ORGANIZATION_ON = 'NOTIFICATION_SUCCESS_SERVICE_ARS_ORGANIZATION_ON';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_BUILDINGS_ADD_SETTINGS =
    'NOTIFICATION_SUCCESS_SERVICE_ARS_BUILDINGS_ADD_SETTINGS';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_BUILDINGS_EDIT_SETTINGS =
    'NOTIFICATION_SUCCESS_SERVICE_ARS_BUILDINGS_EDIT_SETTINGS';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_BUILDINGS_RESET_SETTINGS =
    'NOTIFICATION_SUCCESS_SERVICE_ARS_BUILDINGS_RESET_SETTINGS';
export const NOTIFICATION_SUCCESS_SERVICE_ARS_ORGANIZATION_DELETE_SUCCESS =
    'NOTIFICATION_SUCCESS_SERVICE_ARS_ORGANIZATION_DELETE_SUCCESS';
export const SERVICE_ARS_BUILDINGS_UNAPPLIED_BILLING_COLUMN_TITLE =
    'SERVICE_ARS_BUILDINGS_UNAPPLIED_BILLING_COLUMN_TITLE';
export const SERVICE_ARS_BUILDINGS_APPLIED_BILLING_COLUMN_TITLE = 'SERVICE_ARS_BUILDINGS_APPLIED_BILLING_COLUMN_TITLE';
export const SERVICE_ARS_STATUS_MESSAGE_1 = 'SERVICE_ARS_STATUS_MESSAGE_1';
export const SERVICE_ARS_STATUS_MESSAGE_2 = 'SERVICE_ARS_STATUS_MESSAGE_2';
export const SERVICE_ARS_STATUS_MESSAGE_3 = 'SERVICE_ARS_STATUS_MESSAGE_3';
export const SERVICE_ARS_STATUS_MESSAGE_4 = 'SERVICE_ARS_STATUS_MESSAGE_4';
export const SERVICE_ARS_STATUS_MESSAGE_5 = 'SERVICE_ARS_STATUS_MESSAGE_5';
export const REPORTS_PAGE_TITLE = 'REPORTS_PAGE_TITLE';
export const REPORTS_PAGE_GENERATE_REPORT_BUTTON_VALUE = 'REPORTS_PAGE_GENERATE_REPORT_BUTTON_VALUE';
export const REPORTS_PAGE_TYPE_TABLE_COLUMN_TITLE = 'REPORTS_PAGE_TYPE_TABLE_COLUMN_TITLE';
export const REPORTS_PAGE_NAME_TABLE_COLUMN_TITLE = 'REPORTS_PAGE_NAME_TABLE_COLUMN_TITLE';
export const REPORTS_PAGE_CREATE_TS_TABLE_COLUMN_TITLE = 'REPORTS_PAGE_CREATE_TS_TABLE_COLUMN_TITLE';
export const REPORTS_PAGE_DELETE_MENU_OPTION = 'REPORTS_PAGE_DELETE_MENU_OPTION';
export const REPORTS_PAGE_ARCHIVE_MENU_OPTION = 'REPORTS_PAGE_ARCHIVE_MENU_OPTION';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TYPE_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_KIND_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_START_DATE_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_END_DATE_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COMPANY_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_COUNTER_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_UNIT_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_UNIT_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_UNIT_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_UNIT_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_UNIT_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_UNIT_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TITLE = 'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_TITLE';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CANCEL_BUTTON =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CANCEL_BUTTON';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_SUBMIT_BUTTON =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_SUBMIT_BUTTON';
export const MANAGER_RESPONSIBILITY_TABLE_ORGANIZATION_BUILDING_COLUMN_TITLE =
    'MANAGER_RESPONSIBILITY_TABLE_ORGANIZATION_BUILDING_COLUMN_TITLE';
export const USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER = 'USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER';
export const USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE = 'USERS_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE';
export const USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_LABEL = 'USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_LABEL';
export const USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_TRUE = 'USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_TRUE';
export const USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_FALSE = 'USERS_PAGE_EDIT_USER_FORM_NOTIFICATION_FIELD_FALSE';
export const NOTIFICATION_SUCCESS_EDIT_USER = 'NOTIFICATION_SUCCESS_EDIT_USER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_BUILDING_FIELD_PLACEHOLDER';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_ERROR =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_ERROR';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_LABEL =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_LABEL';
export const REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_PLACEHOLDER =
    'REPORTS_PAGE_GENERATE_MODAL_REPORT_FORM_CITY_FIELD_PLACEHOLDER';

export const NOTIFICATION_SUCCESS_GENERATE_REPORT = 'NOTIFICATION_SUCCESS_GENERATE_REPORT';
export const NOTIFICATION_WARNING_GENERATE_REPORT = 'NOTIFICATION_WARNING_GENERATE_REPORT';
export const NOTIFICATION_ERROR_GENERATE_REPORT = 'NOTIFICATION_ERROR_GENERATE_REPORT';
export const NOTIFICATION_SUCCESS_ARCHIVE_REPORT = 'NOTIFICATION_SUCCESS_ARCHIVE_REPORT';
export const NOTIFICATION_WARNING_ARCHIVE_REPORT = 'NOTIFICATION_WARNING_ARCHIVE_REPORT';
export const NOTIFICATION_ERROR_ARCHIVE_REPORT = 'NOTIFICATION_ERROR_ARCHIVE_REPORT';
export const NOTIFICATION_SUCCESS_DELETE_REPORT = 'NOTIFICATION_SUCCESS_DELETE_REPORT';
export const NOTIFICATION_WARNING_DELETE_REPORT = 'NOTIFICATION_WARNING_DELETE_REPORT';
export const NOTIFICATION_ERROR_DELETE_REPORT = 'NOTIFICATION_ERROR_DELETE_REPORT';
export const SEARCH_INPUT_DEFAULT_PLACEHOLDER = 'SEARCH_INPUT_DEFAULT_PLACEHOLDER';
export const DC_PAGE_TABLE_HEADER_MODEL = 'DC_PAGE_TABLE_HEADER_MODEL';
export const DC_PAGE_TABLE_HEADER_NAME = 'DC_PAGE_TABLE_HEADER_NAME';
export const DC_PAGE_TABLE_HEADER_ADDRESS = 'DC_PAGE_TABLE_HEADER_ADDRESS';
export const DC_PAGE_TABLE_HEADER_NUMBER = 'DC_PAGE_TABLE_HEADER_NUMBER';
export const DC_PAGE_TABLE_HEADER_SIM_NUMBER = 'DC_PAGE_TABLE_HEADER_SIM_NUMBER';
export const DC_PAGE_TABLE_HEADER_IP_ADDRESS = 'DC_PAGE_TABLE_HEADER_IP_ADDRESS';
export const DC_PAGE_TABLE_HEADER_HANDLER = 'DC_PAGE_TABLE_HEADER_HANDLER';
export const DC_PAGE_TABLE_HEADER_SOURCE = 'DC_PAGE_TABLE_HEADER_SOURCE';
export const DC_PAGE_TABLE_HEADER_DATE = 'DC_PAGE_TABLE_HEADER_DATE';
export const DC_PAGE_TABLE_HEADER_DEVICES_AMOUNT = 'DC_PAGE_TABLE_HEADER_DEVICES_AMOUNT';
export const DC_PAGE_HANDLER_TYPE_SMART = 'DC_PAGE_HANDLER_TYPE_SMART';
export const DC_PAGE_HANDLER_TYPE_PROTOCOL = 'DC_PAGE_HANDLER_TYPE_PROTOCOL';
export const NO_DATA_TEXT = 'NO_DATA_TEXT';
export const DC_PAGE_FORM_TITLE_CREATE = 'DC_PAGE_FORM_TITLE_CREATE';
export const DC_PAGE_FORM_TITLE_EDIT = 'DC_PAGE_FORM_TITLE_EDIT';
export const DC_PAGE_FORM_RADIO_REQUIRED_ERROR = 'DC_PAGE_FORM_RADIO_REQUIRED_ERROR';
export const DC_PAGE_FORM_FIELD_LABEL_NAME = 'DC_PAGE_FORM_FIELD_LABEL_NAME';
export const DC_PAGE_FORM_FIELD_LABEL_NUMBER = 'DC_PAGE_FORM_FIELD_LABEL_NUMBER';
export const DC_PAGE_FORM_FIELD_LABEL_BUILDING = 'DC_PAGE_FORM_FIELD_LABEL_BUILDING';
export const DC_PAGE_FORM_FIELD_LABEL_DC_TYPE = 'DC_PAGE_FORM_FIELD_LABEL_DC_TYPE';
export const DC_PAGE_FORM_FIELD_LABEL_IP_ADDRESS = 'DC_PAGE_FORM_FIELD_LABEL_IP_ADDRESS';
export const DC_PAGE_FORM_FIELD_LABEL_SIM_NUMBER = 'DC_PAGE_FORM_FIELD_LABEL_SIM_NUMBER';
export const DC_PAGE_FORM_FIELD_LABEL_DATA_TRANSFER = 'DC_PAGE_FORM_FIELD_LABEL_DATA_TRANSFER';
export const DC_PAGE_FORM_FIELD_VALUE_DATA_TRANSFER_ENABLED = 'DC_PAGE_FORM_FIELD_VALUE_DATA_TRANSFER_ENABLED';
export const DC_PAGE_FORM_FIELD_VALUE_DATA_TRANSFER_DISABLED = 'DC_PAGE_FORM_FIELD_VALUE_DATA_TRANSFER_DISABLED';
export const DC_PAGE_FORM_FIELD_LABEL_HANDLER_TYPE = 'DC_PAGE_FORM_FIELD_LABEL_HANDLER_TYPE';
export const DC_PAGE_FORM_FIELD_LABEL_FTP_ADDRESS = 'DC_PAGE_FORM_FIELD_LABEL_FTP_ADDRESS';
export const DC_PAGE_FORM_FIELD_LABEL_FOLDER_PATH = 'DC_PAGE_FORM_FIELD_LABEL_FOLDER_PATH';
export const DC_PAGE_FORM_FIELD_LABEL_LOGIN = 'DC_PAGE_FORM_FIELD_LABEL_LOGIN';
export const DC_PAGE_FORM_FIELD_LABEL_PASSWORD = 'DC_PAGE_FORM_FIELD_LABEL_PASSWORD';
export const DC_PAGE_FORM_FIELD_LABEL_PROTOCOL = 'DC_PAGE_FORM_FIELD_LABEL_PROTOCOL';
export const DC_PAGE_FORM_PROTOCOL_FTP = 'DC_PAGE_FORM_PROTOCOL_FTP';
export const DC_PAGE_FORM_PROTOCOL_EMAIL = 'DC_PAGE_FORM_PROTOCOL_EMAIL';
export const DC_PAGE_FORM_FIELD_LABEL_FORMAT = 'DC_PAGE_FORM_FIELD_LABEL_FORMAT';
export const DC_PAGE_CREATE_FORM_SUBMIT_TEXT = 'DC_PAGE_CREATE_FORM_SUBMIT_TEXT';
export const DC_PAGE_EDIT_FORM_SUBMIT_TEXT = 'DC_PAGE_EDIT_FORM_SUBMIT_TEXT';
export const DC_PAGE_FORM_CANCEL_TEXT = 'DC_PAGE_FORM_CANCEL_TEXT';
export const DC_PAGE_FILTER_DC_MODEL = 'DC_PAGE_FILTER_DC_MODEL';
export const DC_PAGE_STATUS = 'DC_PAGE_STATUS';
export const DC_PAGE_TRANSFER_STATUS = 'DC_PAGE_TRANSFER_STATUS';
export const DC_PAGE_FILTER_HANDLER = 'DC_PAGE_FILTER_HANDLER';
export const DC_PAGE_FILTER_SOURCE = 'DC_PAGE_FILTER_SOURCE';
export const DC_PAGE_FORM_FIELD_REQUIRED_ERROR = 'DC_PAGE_FORM_FIELD_REQUIRED_ERROR';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_SIM_NUMBER_ERROR = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_SIM_NUMBER_ERROR';
export const DC_PAGE_STATUS_ACTIVE = 'DC_PAGE_STATUS_ACTIVE';
export const DC_PAGE_STATUS_BLOCKED = 'DC_PAGE_STATUS_BLOCKED';
export const DC_EDIT_NOTIFICATION_SUCCESS = 'DC_EDIT_NOTIFICATION_SUCCESS';
export const DC_CREATE_NOTIFICATION_SUCCESS = 'DC_CREATE_NOTIFICATION_SUCCESS';
export const DC_BLOCK_NOTIFICATION_SUCCESS = 'DC_BLOCK_NOTIFICATION_SUCCESS';
export const DC_BLOCK_NOTIFICATION_WARNING = 'DC_BLOCK_NOTIFICATION_WARNING';
export const DC_BLOCK_NOTIFICATION_ERROR = 'DC_BLOCK_NOTIFICATION_ERROR';
export const DC_UNBLOCK_NOTIFICATION_SUCCESS = 'DC_UNBLOCK_NOTIFICATION_SUCCESS';
export const DC_UNBLOCK_NOTIFICATION_WARNING = 'DC_UNBLOCK_NOTIFICATION_WARNING';
export const DC_UNBLOCK_NOTIFICATION_ERROR = 'DC_UNBLOCK_NOTIFICATION_ERROR';
export const DC_OPTIONS_MENU_EDIT = 'DC_OPTIONS_MENU_EDIT';
export const DC_OPTIONS_MENU_DELETE = 'DC_OPTIONS_MENU_DELETE';
export const DC_OPTIONS_MENU_BLOCK = 'DC_OPTIONS_MENU_BLOCK';
export const DC_OPTIONS_MENU_COPY = 'DC_OPTIONS_MENU_COPY';
export const DC_OPTIONS_MENU_UNBLOCK = 'DC_OPTIONS_MENU_UNBLOCK';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_NAME = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_NAME';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_NUMBER = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_NUMBER';
export const DC_PAGE_FORM_FIELD_LABEL_CITY = 'DC_PAGE_FORM_FIELD_LABEL_CITY';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_CITY = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_CITY';
export const DC_PAGE_FORM_FIELD_LABEL_COMPANY = 'DC_PAGE_FORM_FIELD_LABEL_COMPANY';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_COMPANY = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_COMPANY';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_BUILDING = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_BUILDING';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_DC_TYPE = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_DC_TYPE';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_IP_ADDRESS = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_IP_ADDRESS';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_SIM_NUMBER = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_SIM_NUMBER';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_FTP_ADDRESS = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_FTP_ADDRESS';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_FOLDER_PATH = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_FOLDER_PATH';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_LOGIN = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_LOGIN';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_PASSWORD = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_PASSWORD';
export const DC_PAGE_FORM_FIELD_PLACEHOLDER_FORMAT = 'DC_PAGE_FORM_FIELD_PLACEHOLDER_FORMAT';
export const BUILDING_FORM_EDIT_TITLE = 'BUILDING_FORM_EDIT_TITLE';
export const BUILDING_FORM_COPY_TITLE = 'BUILDING_FORM_COPY_TITLE';
export const BUILDING_FORM_ADD_TITLE = 'BUILDING_FORM_ADD_TITLE';
export const BUILDING_FORM_ADD_SUBMIT_BTN = 'BUILDING_FORM_ADD_SUBMIT_BTN';
export const BUILDING_FORM_COPY_SUBMIT_BTN = 'BUILDING_FORM_COPY_SUBMIT_BTN';
export const BUILDING_FORM_EDIT_SUBMIT_BTN = 'BUILDING_FORM_EDIT_SUBMIT_BTN';
export const BUILDING_FORM_CANCEL_BTN = 'BUILDING_FORM_CANCEL_BTN';
export const NOTIFICATION_SUCCESS_ADD_ROOM_TYPE = 'NOTIFICATION_SUCCESS_ADD_ROOM_TYPE';
export const NOTIFICATION_SUCCESS_EDIT_ROOM_TYPE = 'NOTIFICATION_SUCCESS_EDIT_ROOM_TYPE';
export const NOTIFICATION_SUCCESS_DELETE_ROOM_TYPE = 'NOTIFICATION_SUCCESS_DELETE_ROOM_TYPE';
export const SETTINGS_ROOM_TYPE_TAB_TITLE = 'SETTINGS_ROOM_TYPE_TAB_TITLE';
export const SETTINGS_ROOM_TYPE_TAB_ADD_BUTTON = 'SETTINGS_ROOM_TYPE_TAB_ADD_BUTTON';
export const SETTINGS_ROOM_TYPE_ADD_FORM_CANCEL_BUTTON = 'SETTINGS_ROOM_TYPE_ADD_FORM_CANCEL_BUTTON';
export const SETTINGS_ROOM_TYPE_ADD_FORM_TITLE = 'SETTINGS_ROOM_TYPE_ADD_FORM_TITLE';
export const SETTINGS_ROOM_TYPE_EDIT_FORM_TITLE = 'SETTINGS_ROOM_TYPE_EDIT_FORM_TITLE';
export const SETTINGS_ROOM_TYPE_ADD_FORM_SUBMIT_BUTTON = 'SETTINGS_ROOM_TYPE_ADD_FORM_SUBMIT_BUTTON';
export const SETTINGS_ROOM_TYPE_EDIT_FORM_SUBMIT_BUTTON = 'SETTINGS_ROOM_TYPE_EDIT_FORM_SUBMIT_BUTTON';
export const SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_LABEL = 'SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_LABEL';
export const SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_PLACEHOLDER = 'SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_PLACEHOLDER';
export const SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_ERROR_MESSAGE = 'SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_ERROR_MESSAGE';
export const SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_LABEL = 'SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_LABEL';
export const SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_PLACEHOLDER =
    'SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_PLACEHOLDER';
export const SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE =
    'SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE';
export const SETTING_ROOM_TYPE_TAB_OPTIONS_MENU_EDIT = 'SETTING_ROOM_TYPE_TAB_OPTIONS_MENU_EDIT';
export const SETTING_ROOM_TYPE_TAB_OPTIONS_MENU_DELETE = 'SETTING_ROOM_TYPE_TAB_OPTIONS_MENU_DELETE';
export const SETTINGS_DC_MODEL_FORM_CANCEL_BUTTON = 'SETTINGS_DC_MODEL_FORM_CANCEL_BUTTON';
export const SETTINGS_DC_MODEL_ADD_FORM_SUBMIT_BUTTON = 'SETTINGS_DC_MODEL_ADD_FORM_SUBMIT_BUTTON';
export const SETTINGS_DC_MODEL_ADD_FORM_TITLE = 'SETTINGS_DC_MODEL_ADD_FORM_TITLE';
export const SETTINGS_DC_MODEL_EDIT_FORM_SUBMIT_BUTTON = 'SETTINGS_DC_MODEL_EDIT_FORM_SUBMIT_BUTTON';
export const SETTINGS_DC_MODEL_EDIT_FORM_TITLE = 'SETTINGS_DC_MODEL_EDIT_FORM_TITLE';
export const SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE =
    'SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE';
export const SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_LABEL = 'SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_LABEL';
export const SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_PLACEHOLDER =
    'SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_PLACEHOLDER';
export const SETTINGS_DC_MODEL_FORM_NAME_FIELD_ERROR_MESSAGE = 'SETTINGS_DC_MODEL_FORM_NAME_FIELD_ERROR_MESSAGE';
export const SETTINGS_DC_MODEL_FORM_NAME_FIELD_LABEL = 'SETTINGS_DC_MODEL_FORM_NAME_FIELD_LABEL';
export const SETTINGS_DC_MODEL_FORM_NAME_FIELD_PLACEHOLDER = 'SETTINGS_DC_MODEL_FORM_NAME_FIELD_PLACEHOLDER';
export const SETTINGS_DC_MODEL_TAB_ADD_BUTTON = 'SETTINGS_DC_MODEL_TAB_ADD_BUTTON';
export const SETTINGS_DC_MODEL_TAB_TITLE = 'SETTINGS_DC_MODEL_TAB_TITLE';
export const SETTING_DC_MODEL_TAB_OPTIONS_MENU_DELETE = 'SETTING_DC_MODEL_TAB_OPTIONS_MENU_DELETE';
export const SETTING_DC_MODEL_TAB_OPTIONS_MENU_EDIT = 'SETTING_DC_MODEL_TAB_OPTIONS_MENU_EDIT';
export const SETTING_DEVICE_GROUP_ADD_BUTTON = 'SETTING_DEVICE_GROUP_ADD_BUTTON';
export const SETTING_DEVICE_GROUP_MODAL_TAB_OPTIONS_MENU_EDIT = 'SETTING_DEVICE_GROUP_MODAL_TAB_OPTIONS_MENU_EDIT';
export const SETTING_DEVICE_GROUP_MODAL_TAB_OPTIONS_MENU_DELETE = 'SETTING_DEVICE_GROUP_MODAL_TAB_OPTIONS_MENU_DELETE';
export const SETTING_DEVICE_MODEL_MODAL_TAB_OPTIONS_MENU_EDIT = 'SETTING_DEVICE_MODEL_MODAL_TAB_OPTIONS_MENU_EDIT';
export const SETTING_DEVICE_MODEL_MODAL_TAB_OPTIONS_MENU_DELETE = 'SETTING_DEVICE_MODEL_MODAL_TAB_OPTIONS_MENU_DELETE';
export const SETTING_DEVICE_MODEL_ADD_FORM_SUBMIT_BUTTON = 'SETTING_DEVICE_MODEL_ADD_FORM_SUBMIT_BUTTON';
export const SETTING_DEVICE_MODEL_EDIT_FORM_SUBMIT_BUTTON = 'SETTING_DEVICE_MODEL_EDIT_FORM_SUBMIT_BUTTON';
export const SETTINGS_DEVICE_MODEL_TAB_TITLE = 'SETTINGS_DEVICE_MODEL_TAB_TITLE';
export const SETTINGS_DEVICE_MODEL_TAB_ADD_BUTTON = 'SETTINGS_DEVICE_MODEL_TAB_ADD_BUTTON';
export const SETTING_DEVICE_MODEL_ADD_FORM_TITLE = 'SETTING_DEVICE_MODEL_ADD_FORM_TITLE';
export const SETTING_DEVICE_MODEL_EDIT_FORM_TITLE = 'SETTING_DEVICE_MODEL_EDIT_FORM_TITLE';
export const SETTING_DEVICE_MODEL_FORM_CANCEL_BUTTON = 'SETTING_DEVICE_MODEL_FORM_CANCEL_BUTTON';
export const SETTING_DEVICE_MODEL_FORM_NAME_FIELD_LABEL = 'SETTING_DEVICE_MODEL_FORM_NAME_FIELD_LABEL';
export const SETTING_DEVICE_MODEL_FORM_NAME_FIELD_PLACEHOLDER = 'SETTING_DEVICE_MODEL_FORM_NAME_FIELD_PLACEHOLDER';
export const SETTING_DEVICE_MODEL_FORM_NAME_FIELD_ERROR_MESSAGE = 'SETTING_DEVICE_MODEL_FORM_NAME_FIELD_ERROR_MESSAGE';
export const SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_LABEL = 'SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_LABEL';
export const SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_PLACEHOLDER =
    'SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_PLACEHOLDER';
export const SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE =
    'SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE';
export const SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_FIELD_LABEL =
    'SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_FIELD_LABEL';
export const SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_FIELD_PLACEHOLDER =
    'SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_FIELD_PLACEHOLDER';
export const SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_REQUIRED_ERROR =
    'SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_REQUIRED_ERROR';
export const SETTING_DEVICE_GROUPS_ADD_FORM_SUBMIT_BUTTON = 'SETTING_DEVICE_GROUPS_ADD_FORM_SUBMIT_BUTTON';
export const SETTING_DEVICE_GROUPS_EDIT_FORM_SUBMIT_BUTTON = 'SETTING_DEVICE_GROUPS_EDIT_FORM_SUBMIT_BUTTON';
export const SETTING_DEVICE_GROUPS_ADD_FORM_TITLE = 'SETTING_DEVICE_GROUPS_ADD_FORM_TITLE';
export const SETTING_DEVICE_GROUPS_EDIT_FORM_TITLE = 'SETTING_DEVICE_GROUPS_EDIT_FORM_TITLE';
export const SETTING_DEVICE_GROUPS_FORM_CANCEL_BUTTON = 'SETTING_DEVICE_GROUPS_FORM_CANCEL_BUTTON';
export const SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_LABEL = 'SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_LABEL';
export const SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_PLACEHOLDER = 'SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_PLACEHOLDER';
export const SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_ERROR_MESSAGE =
    'SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_ERROR_MESSAGE';
export const SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_LABEL =
    'SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_LABEL';
export const SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_PLACEHOLDER =
    'SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_PLACEHOLDER';
export const SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE =
    'SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE';
export const SETTINGS_DEVICE_GROUPS_FORM_TYPES_FIELD_LABEL = 'SETTINGS_DEVICE_GROUPS_FORM_TYPES_FIELD_LABEL';
export const SETTINGS_DEVICE_GROUPS_FORM_TYPES_FIELD_PLACEHOLDER =
    'SETTINGS_DEVICE_GROUPS_FORM_TYPES_FIELD_PLACEHOLDER';
export const SETTINGS_DEVICE_GROUPS_FORM_TYPES_REQUIRED_ERROR = 'SETTINGS_DEVICE_GROUPS_FORM_TYPES_REQUIRED_ERROR';
export const SETTINGS_DEVICE_MODEL_ERRORS = 'SETTINGS_DEVICE_MODEL_ERRORS';
export const NOTIFICATION_SUCCESS_ADD_DEVICE_MODEL = 'NOTIFICATION_SUCCESS_ADD_DEVICE_MODEL';
export const NOTIFICATION_SUCCESS_EDIT_DEVICE_MODEL = 'NOTIFICATION_SUCCESS_EDIT_DEVICE_MODEL';
export const NOTIFICATION_SUCCESS_DELETE_DEVICE_MODEL = 'NOTIFICATION_SUCCESS_DELETE_DEVICE_MODEL';
export const NOTIFICATION_SUCCESS_ADD_DEVICE_GROUP = 'NOTIFICATION_SUCCESS_ADD_DEVICE_GROUP';
export const NOTIFICATION_SUCCESS_EDIT_DEVICE_GROUP = 'NOTIFICATION_SUCCESS_EDIT_DEVICE_GROUP';
export const NOTIFICATION_SUCCESS_DELETE_DEVICE_GROUP = 'NOTIFICATION_SUCCESS_DELETE_DEVICE_GROUP';
export const NOTIFICATION_SUCCESS_ADD_DC_MODEL = 'NOTIFICATION_SUCCESS_ADD_DC_MODEL';
export const NOTIFICATION_SUCCESS_EDIT_DC_MODEL = 'NOTIFICATION_SUCCESS_EDIT_DC_MODEL';
export const NOTIFICATION_SUCCESS_DELETE_DC_MODEL = 'NOTIFICATION_SUCCESS_DELETE_DC_MODEL';
export const RESTORE_PASS_FORM_EMAIL_INPUT_LABEL = 'RESTORE_PASS_FORM_EMAIL_INPUT_LABEL';
export const RESTORE_PASS_FORM_EMAIL_INPUT_PLACEHOLDER = 'RESTORE_PASS_FORM_EMAIL_INPUT_PLACEHOLDER';
export const RESTORE_PASS_FORM_EMAIL_INPUT_ERROR = 'RESTORE_PASS_FORM_EMAIL_INPUT_ERROR';
export const RESTORE_PASS_FORM_VERIFICATION_CODE_INPUT_LABEL = 'RESTORE_PASS_FORM_VERIFICATION_CODE_INPUT_LABEL';
export const RESTORE_PASS_FORM_VERIFICATION_CODE_INPUT_PLACEHOLDER =
    'RESTORE_PASS_FORM_VERIFICATION_CODE_INPUT_PLACEHOLDER';
export const RESTORE_PASS_FORM_VERIFICATION_CODE_INPUT_ERROR = 'RESTORE_PASS_FORM_VERIFICATION_CODE_INPUT_ERROR';
export const RESTORE_PASS_FORM_PASSWORD_INPUT_LABEL = 'RESTORE_PASS_FORM_PASSWORD_INPUT_LABEL';
export const RESTORE_PASS_FORM_PASSWORD_INPUT_PLACEHOLDER = 'RESTORE_PASS_FORM_PASSWORD_INPUT_PLACEHOLDER';
export const RESTORE_PASS_FORM_PASSWORD_INPUT_ERROR = 'RESTORE_PASS_FORM_PASSWORD_INPUT_ERROR';
export const RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_LABEL = 'RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_LABEL';
export const RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_PLACEHOLDER =
    'RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_PLACEHOLDER';
export const RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_ERROR = 'RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_ERROR';
export const RESTORE_PASS_FORM_PASSWORD_INPUT_FEEDBACK_RULE_1 = 'RESTORE_PASS_FORM_PASSWORD_INPUT_FEEDBACK_RULE_1';
export const RESTORE_PASS_FORM_PASSWORD_INPUT_FEEDBACK_RULE_2 = 'RESTORE_PASS_FORM_PASSWORD_INPUT_FEEDBACK_RULE_2';
export const RESTORE_PASS_FORM_PASSWORD_INPUT_FEEDBACK_RULE_3 = 'RESTORE_PASS_FORM_PASSWORD_INPUT_FEEDBACK_RULE_3';
export const RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_FEEDBACK_RULE_1 =
    'RESTORE_PASS_FORM_CONFIRM_PASSWORD_INPUT_FEEDBACK_RULE_1';
export const NOTIFICATION_SUCCESS_RESTORE_USER_PASSWORD = 'NOTIFICATION_SUCCESS_RESTORE_USER_PASSWORD';
export const RESTORE_PASS_FORM_NEXT_BUTTON = 'RESTORE_PASS_FORM_NEXT_BUTTON';
export const RESTORE_PASS_FORM_PREV_BUTTON = 'RESTORE_PASS_FORM_PREV_BUTTON';
export const RESTORE_PASS_FORM_SUBMIT_BUTTON = 'RESTORE_PASS_FORM_SUBMIT_BUTTON';
export const USERS_SETTINGS_CLAIMS_ROLE_ADMIN = 'USERS_SETTINGS_CLAIMS_ROLE_ADMIN';
export const USERS_SETTINGS_CLAIMS_ROLE_MANAGER = 'USERS_SETTINGS_CLAIMS_ROLE_MANAGER';
export const USERS_SETTINGS_CLAIMS_ROLE_ROOMER = 'USERS_SETTINGS_CLAIMS_ROLE_ROOMER';
export const USERS_SETTINGS_CLAIMS_FORM_SUCCESS_NOTIFICATION = 'USERS_SETTINGS_CLAIMS_FORM_SUCCESS_NOTIFICATION';
export const USERS_SETTINGS_CLAIMS_FORM_SUBMIT_BUTTON = 'USERS_SETTINGS_CLAIMS_FORM_SUBMIT_BUTTON';
export const SETTINGS_TAB_TITLE_COMMON = 'SETTINGS_TAB_TITLE_COMMON';
export const SETTINGS_TAB_TITLE_ROOMS = 'SETTINGS_TAB_TITLE_ROOMS';
export const SETTINGS_TAB_TITLE_DATA_COLLECTORS = 'SETTINGS_TAB_TITLE_DATA_COLLECTORS';
export const SETTINGS_TAB_TITLE_COUNTERS = 'SETTINGS_TAB_TITLE_COUNTERS';
export const SETTINGS_TAB_TITLE_USERS = 'SETTINGS_TAB_TITLE_USERS';
export const SETTINGS_COMMON_LANGUAGE_UA = 'SETTINGS_COMMON_LANGUAGE_UA';
export const SETTINGS_COMMON_COLLECT_DATA_BUTTON = 'SETTINGS_COMMON_COLLECT_DATA_BUTTON';
export const SETTINGS_COMMON_COLLECT_DATA_BUTTON_IN_PROGRESS = 'SETTINGS_COMMON_COLLECT_DATA_BUTTON_IN_PROGRESS';
export const SETTINGS_COMMON_COLLECT_DATA_LABEL = 'SETTINGS_COMMON_COLLECT_DATA_LABEL';
export const SETTINGS_COMMON_COLLECT_DATA_STATUS_LABEL = 'SETTINGS_COMMON_COLLECT_DATA_STATUS_LABEL';
export const SETTINGS_COMMON_DATA_COLLECTION_INTERVAL_LABEL = 'SETTINGS_COMMON_DATA_COLLECTION_INTERVAL_LABEL';
export const SETTINGS_COMMON_DATA_COLLECTION_INTERVAL_BTN_TEXT = 'SETTINGS_COMMON_DATA_COLLECTION_INTERVAL_BTN_TEXT';
export const SETTINGS_COMMON_LANGUAGE_SELECT_LABEL = 'SETTINGS_COMMON_LANGUAGE_SELECT_LABEL';
export const SETTINGS_COMMON_ADD_CITY_LABEL = 'SETTINGS_COMMON_ADD_CITY_LABEL';
export const SETTINGS_COMMON_ADD_CITY_LABEL_BTN_TEXT = 'SETTINGS_COMMON_ADD_CITY_LABEL_BTN_TEXT';
export const SETTINGS_CITY_ADD_FORM_TITLE = 'SETTINGS_CITY_ADD_FORM_TITLE';
export const SETTINGS_CITY_ADD_FORM_SUBMIT_BUTTON = 'SETTINGS_CITY_ADD_FORM_SUBMIT_BUTTON';
export const SETTINGS_CITY_ADD_FORM_CANCEL_BUTTON = 'SETTINGS_CITY_ADD_FORM_CANCEL_BUTTON';
export const SETTINGS_CITY_FORM_NAME_FIELD_LABEL = 'SETTINGS_CITY_FORM_NAME_FIELD_LABEL';
export const SETTINGS_CITY_FORM_NAME_FIELD_PLACEHOLDER = 'SETTINGS_CITY_FORM_NAME_FIELD_PLACEHOLDER';
export const SETTINGS_CITY_FORM_NAME_FIELD_ERROR_MESSAGE = 'SETTINGS_CITY_FORM_NAME_FIELD_ERROR_MESSAGE';
export const SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_1 = 'SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_1';
export const SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_2 = 'SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_2';
export const SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_3 = 'SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_3';
export const SETTINGS_PAGE_TITLE = 'SETTINGS_PAGE_TITLE';
export const TIME_UNIT_MIN = 'TIME_UNIT_MIN';
export const BUILDING_CREATION_YEAR = 'BUILDING_CREATION_YEAR';
export const TEMPERATURE_LOW_BOUND = 'TEMPERATURE_LOW_BOUND';
export const HEAT_SQUARE = 'HEAT_SQUARE';
export const METERS_UNIT = 'METERS_UNIT';
export const COMPANY_ROOMS_BUTTON = 'COMPANY_ROOMS_BUTTON';
export const COMPANY_DIAGNOSIS_BUTTON = 'COMPANY_DIAGNOSIS_BUTTON';
export const COMPANY_DATA_COLLECTOR_TEXT = 'COMPANY_DATA_COLLECTOR_TEXT';
export const COMPANY_NO_USERS = 'COMPANY_NO_USERS';
export const COMPANY_ACTIVE_USERS = 'COMPANY_ACTIVE_USERS';
export const COMPANY_INACTIVE_USERS = 'COMPANY_INACTIVE_USERS';
export const COMPANY_NOT_APPROVED_USERS = 'COMPANY_NOT_APPROVED_USERS';
export const COMPANY_MODULES_SECTION_TITLE = 'COMPANY_MODULES_SECTION_TITLE';
export const COMPANY_MODULES_REPORTS_CREATION_BUTTON = 'COMPANY_MODULES_REPORTS_CREATION_BUTTON';
export const COMPANY_MODULES_REPORTS_AUTO_SEND_BUTTON = 'COMPANY_MODULES_REPORTS_AUTO_SEND_BUTTON';
export const COMPANY_MODULES_COLLECT_AND_HANDLE_BUTTON = 'COMPANY_MODULES_COLLECT_AND_HANDLE_BUTTON';
export const BUILDING_MENU_OPTION_EDIT = 'BUILDING_MENU_OPTION_EDIT';
export const BUILDING_MENU_OPTION_COPY = 'BUILDING_MENU_OPTION_COPY';
export const BUILDING_MENU_OPTION_DELETE = 'BUILDING_MENU_OPTION_DELETE';
export const COMPANY_TABLE_COL_TITLE_ADDRESS = 'COMPANY_TABLE_COL_TITLE_ADDRESS';
export const COMPANY_TABLE_COL_TITLE_NAME = 'COMPANY_TABLE_COL_TITLE_NAME';
export const COMPANY_TABLE_COL_TITLE_DATA_COLLECTOR = 'COMPANY_TABLE_COL_TITLE_DATA_COLLECTOR';
export const COMPANY_TABLE_COL_TITLE_STATUS = 'COMPANY_TABLE_COL_TITLE_STATUS';
export const COMPANY_TABLE_COL_TITLE_COUNTERS = 'COMPANY_TABLE_COL_TITLE_COUNTERS';
export const BUILDING_CREATE_NOTIFICATION_SUCCESS = 'BUILDING_CREATE_NOTIFICATION_SUCCESS';
export const BUILDING_EDIT_NOTIFICATION_SUCCESS = 'BUILDING_EDIT_NOTIFICATION_SUCCESS';
export const BUILDING_DELETE_NOTIFICATION_SUCCESS = 'BUILDING_DELETE_NOTIFICATION_SUCCESS';
export const BUILDING_FORM_ITEM_LABEL_NAME = 'BUILDING_FORM_ITEM_LABEL_NAME';
export const BUILDING_FORM_ITEM_PLACEHOLDER_NAME = 'BUILDING_FORM_ITEM_PLACEHOLDER_NAME';
export const BUILDING_FORM_ITEM_LABEL_ADDRESS = 'BUILDING_FORM_ITEM_LABEL_ADDRESS';
export const BUILDING_FORM_ITEM_PLACEHOLDER_ADDRESS = 'BUILDING_FORM_ITEM_PLACEHOLDER_ADDRESS';
export const BUILDING_FORM_ITEM_LABEL_CITY = 'BUILDING_FORM_ITEM_LABEL_CITY';
export const BUILDING_FORM_ITEM_PLACEHOLDER_CITY = 'BUILDING_FORM_ITEM_PLACEHOLDER_CITY';
export const BUILDING_FORM_ITEM_LABEL_COMPANY = 'BUILDING_FORM_ITEM_LABEL_COMPANY';
export const BUILDING_FORM_ITEM_PLACEHOLDER_COMPANY = 'BUILDING_FORM_ITEM_PLACEHOLDER_COMPANY';
export const BUILDING_FORM_ITEM_LABEL_LATITUDE = 'BUILDING_FORM_ITEM_LABEL_LATITUDE';
export const BUILDING_FORM_ITEM_PLACEHOLDER_LATITUDE = 'BUILDING_FORM_ITEM_PLACEHOLDER_LATITUDE';
export const BUILDING_FORM_ITEM_LABEL_LONGITUDE = 'BUILDING_FORM_ITEM_LABEL_LONGITUDE';
export const BUILDING_FORM_ITEM_PLACEHOLDER_LONGITUDE = 'BUILDING_FORM_ITEM_PLACEHOLDER_LONGITUDE';
export const BUILDING_FORM_ITEM_LABEL_YEAR = 'BUILDING_FORM_ITEM_LABEL_YEAR';
export const BUILDING_FORM_ITEM_PLACEHOLDER_YEAR = 'BUILDING_FORM_ITEM_PLACEHOLDER_YEAR';
export const BUILDING_FORM_ITEM_LABEL_HEAT_SQUARE = 'BUILDING_FORM_ITEM_LABEL_HEAT_SQUARE';
export const BUILDING_FORM_ITEM_PLACEHOLDER_HEAT_SQUARE = 'BUILDING_FORM_ITEM_PLACEHOLDER_HEAT_SQUARE';
export const BUILDING_FORM_ITEM_LABEL_ACCOUNT = 'BUILDING_FORM_ITEM_LABEL_ACCOUNT';
export const BUILDING_FORM_ITEM_PLACEHOLDER_ACCOUNT = 'BUILDING_FORM_ITEM_PLACEHOLDER_ACCOUNT';
export const BUILDING_FORM_ITEM_LABEL_CONSUMER = 'BUILDING_FORM_ITEM_LABEL_CONSUMER';
export const BUILDING_FORM_ITEM_PLACEHOLDER_CONSUMER = 'BUILDING_FORM_ITEM_PLACEHOLDER_CONSUMER';
export const BUILDING_FORM_REQUIRED_CONSUMER = 'BUILDING_FORM_REQUIRED_CONSUMER';
export const BUILDING_FORM_REQUIRED_ERROR = 'BUILDING_FORM_REQUIRED_ERROR';
export const BUILDING_FILTERS_TRANSFER_STATUS = 'BUILDING_FILTERS_TRANSFER_STATUS';
export const BUILDING_FILTERS_DC_MODEL = 'BUILDING_FILTERS_DC_MODEL';
export const BUILDING_FILTERS_DC_STATUS = 'BUILDING_FILTERS_DC_STATUS';
export const BUILDING_FILTERS_YEAR = 'BUILDING_FILTERS_YEAR';
export const BUILDING_FILTERS_DC_ACTIVE = 'BUILDING_FILTERS_DC_ACTIVE';
export const BUILDING_FILTERS_DC_BLOCKED = 'BUILDING_FILTERS_DC_BLOCKED';
export const BUILDINGS_PAGE_SEARCH_PLACEHOLDER = 'BUILDINGS_PAGE_SEARCH_PLACEHOLDER';
export const PROFILE_PAGE_TITLE = 'PROFILE_PAGE_TITLE';
export const PROFILE_PAGE_PROFILE_TAB = 'PROFILE_PAGE_PROFILE_TAB';
export const PROFILE_PAGE_SECURITY_TAB = 'PROFILE_PAGE_SECURITY_TAB';
export const PROFILE_PAGE_ROOMS_TAB = 'PROFILE_PAGE_ROOMS_TAB';
export const NOTIFICATION_SUCCESS_SAVE = 'NOTIFICATION_SUCCESS_SAVE';
export const PROFILE_PAGE_EDIT_USER_FORM_TITLE = 'PROFILE_PAGE_EDIT_USER_FORM_TITLE';
export const PROFILE_PAGE_FORM_SUBMIT = 'PROFILE_PAGE_FORM_SUBMIT';
export const BUILDING_PAGE_FORM_SUBMIT = 'BUILDING_PAGE_FORM_SUBMIT';
export const PROFILE_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_BUILDING_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_BUILDING_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_USER_FORM_BUILDING_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_COMPANY_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_COMPANY_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_USER_FORM_COMPANY_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_1 =
    'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_1';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_2 =
    'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_2';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_3 =
    'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_3';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_4 =
    'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_FEEDBACK_RULE_4';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_USER_FORM_LOGIN_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_LAEL = 'PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_LAEL';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_PLACEHOLDER = 'PROFILE_PAGE_EDIT_USER_FORM_NAME_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_INPUT_FEEDBACK_RULE_1 =
    'PROFILE_PAGE_EDIT_USER_FORM_NAME_INPUT_FEEDBACK_RULE_1';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_INPUT_FEEDBACK_RULE_2 =
    'PROFILE_PAGE_EDIT_USER_FORM_NAME_INPUT_FEEDBACK_RULE_2';
export const PROFILE_PAGE_EDIT_USER_FORM_NAME_INPUT_FEEDBACK_RULE_3 =
    'PROFILE_PAGE_EDIT_USER_FORM_NAME_INPUT_FEEDBACK_RULE_3';
export const PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_IMAGE_FIELD_FEEDBACK = 'PROFILE_PAGE_EDIT_USER_FORM_IMAGE_FIELD_FEEDBACK';
export const PROFILE_PAGE_EDIT_IMAGE_EXCEED_SIZE = 'PROFILE_PAGE_EDIT_IMAGE_EXCEED_SIZE';
export const PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1 =
    'PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_1';
export const PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2 =
    'PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_FEEDBACK_RULE_2';
export const PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_USER_FORM_PHONE_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1 =
    'PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_1';
export const PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2 =
    'PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_2';
export const PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3 =
    'PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_FEEDBACK_RULE_3';
export const PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_USER_FORM_SURNAME_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_PASS_FORM_CURRENT_PASS_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_PASS_FORM_CURRENT_PASS_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_PASS_FORM_CURRENT_PASS_FIELD_LABEL =
    'PROFILE_PAGE_EDIT_PASS_FORM_CURRENT_PASS_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_PASS_FORM_CURRENT_PASS_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_PASS_FORM_CURRENT_PASS_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_LABEL = 'PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_FEEDBACK_RULE_1 =
    'PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_FEEDBACK_RULE_1';
export const PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_FEEDBACK_RULE_2 =
    'PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_FEEDBACK_RULE_2';
export const PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_FEEDBACK_RULE_3 =
    'PROFILE_PAGE_EDIT_PASS_FORM_NEW_PASS_FIELD_FEEDBACK_RULE_3';
export const PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_LABEL =
    'PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_FEEDBACK_RULE_1 =
    'PROFILE_PAGE_EDIT_PASS_FORM_CONFIRM_PASS_FIELD_FEEDBACK_RULE_1';
export const PROFILE_PAGE_EDIT_PASS_FORM_TITLE = 'PROFILE_PAGE_EDIT_PASS_FORM_TITLE';
export const SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_IN_PROGRESS =
    'SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_IN_PROGRESS';
export const SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_SUCCESS_1 =
    'SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_SUCCESS_1';
export const SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_PARTIAL_FAILED_2 =
    'SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_PARTIAL_FAILED_2';
export const SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_FAILED_3 = 'SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_FAILED_3';
export const SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_NO_NEW_DATA_4 =
    'SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_NO_NEW_DATA_4';
export const SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_WORKING_TRY_LATER_5 =
    'SETTINGS_COMMON_NOTIFICATION_DATA_COLLECT_WORKING_TRY_LATER_5';
export const SETTINGS_COMMON_COLLECT_DATA_STATUS_ENABLED = 'SETTINGS_COMMON_COLLECT_DATA_STATUS_ENABLED';
export const SETTINGS_COMMON_COLLECT_DATA_STATUS_DISABLED = 'SETTINGS_COMMON_COLLECT_DATA_STATUS_DISABLED';
export const SETTINGS_COMMON_NOTIFICATION_SETTINGS_STATUS_CHANGED_SUCCESS =
    'SETTINGS_COMMON_NOTIFICATION_SETTINGS_STATUS_CHANGED_SUCCESS';
export const SETTINGS_COMMON_NOTIFICATION_SETTINGS_CHANGED_SUCCESS =
    'SETTINGS_COMMON_NOTIFICATION_SETTINGS_CHANGED_SUCCESS';
export const BUILDINGS_TABLE_INDICATORS_MORE_BUTTON_TEXT = 'BUILDINGS_TABLE_INDICATORS_MORE_BUTTON_TEXT';
export const BUILDINGS_TABLE_INDICATORS_TITLE = 'BUILDINGS_TABLE_INDICATORS_TITLE';
export const BUILDINGS_TABLE_POPOVER_MODEMS_TITLE = 'BUILDINGS_TABLE_POPOVER_MODEMS_TITLE';
export const USERS_TABLE_POPOVER_MODEMS_TITLE = 'USERS_TABLE_POPOVER_MODEMS_TITLE';
export const DIAGNOSTIC_PAGE_TITLE = 'DIAGNOSTIC_PAGE_TITLE';
export const YEAR = 'YEAR';
export const SQUARE = 'SQUARE';
export const SQUARE_MEASURE = 'SQUARE_MEASURE';
export const ROOM = 'ROOM';
export const CLAIM_READ_TEXT = 'CLAIM_READ_TEXT';
export const CLAIM_WRITE_TEXT = 'CLAIM_WRITE_TEXT';
export const CLAIM_EDIT_TEXT = 'CLAIM_EDIT_TEXT';
export const CLAIM_DELETE_TEXT = 'CLAIM_DELETE_TEXT';
export const BUILDING_PROFILE_PAGE_PROFILE_SECTION_ORG_INFO_ITEM =
    'BUILDING_PROFILE_PAGE_PROFILE_SECTION_ORG_INFO_ITEM';
export const BUILDING_PROFILE_PAGE_PROFILE_SECTION_BUILDING_YEAR_INFO_ITEM =
    'BUILDING_PROFILE_PAGE_PROFILE_SECTION_BUILDING_YEAR_INFO_ITEM';
export const BUILDING_PROFILE_PAGE_PROFILE_SECTION_SQUARE_INFO_ITEM =
    'BUILDING_PROFILE_PAGE_PROFILE_SECTION_SQUARE_INFO_ITEM';
export const BUILDING_PROFILE_PAGE_PROFILE_SECTION_ACCOUNT_INFO_ITEM =
    'BUILDING_PROFILE_PAGE_PROFILE_SECTION_ACCOUNT_INFO_ITEM';
export const BUILDING_PROFILE_PAGE_PROFILE_SECTION_BUILDING_REPORT_PATH_INFO_ITEM =
    'BUILDING_PROFILE_PAGE_PROFILE_SECTION_BUILDING_REPORT_PATH_INFO_ITEM';
export const BUILDING_PROFILE_PAGE_PROFILE_SECTION_BUILDING_TEMPERATURE_INFO_ITEM =
    'BUILDING_PROFILE_PAGE_PROFILE_SECTION_BUILDING_TEMPERATURE_INFO_ITEM';
export const BUILDING_PROFILE_PAGE_CTA_BLOCK_ROOMS_MENU_ITEM = 'BUILDING_PROFILE_PAGE_CTA_BLOCK_ROOMS_MENU_ITEM';
export const BUILDING_PROFILE_PAGE_CTA_BLOCK_PZPD_MENU_ITEM = 'BUILDING_PROFILE_PAGE_CTA_BLOCK_PZPD_MENU_ITEM';
export const BUILDING_PROFILE_PAGE_CTA_BLOCK_USER_MENU_ITEM = 'BUILDING_PROFILE_PAGE_CTA_BLOCK_USER_MENU_ITEM';
export const BUILDING_PROFILE_PAGE_CTA_BLOCK_DEVICE_MENU_ITEM = 'BUILDING_PROFILE_PAGE_CTA_BLOCK_DEVICE_MENU_ITEM';
export const BUILDING_PROFILE_PAGE_CTA_BLOCK_IMPORT_FILE_ITEM = 'BUILDING_PROFILE_PAGE_CTA_BLOCK_IMPORT_FILE_ITEM';
export const BUILDING_PROFILE_PAGE_UPLOAD_FILE_TITLE = 'BUILDING_PROFILE_PAGE_UPLOAD_FILE_TITLE';
export const BUILDING_PROFILE_PAGE_UPLOAD_FILE_SUBTITLE = 'BUILDING_PROFILE_PAGE_UPLOAD_FILE_SUBTITLE';
export const BUILDING_PROFILE_PAGE_UPLOAD_FILE_DESCRIPTION = 'BUILDING_PROFILE_PAGE_UPLOAD_FILE_DESCRIPTION';
export const BUILDING_PROFILE_PAGE_CTA_BLOCK_ADD_BUTTON = 'BUILDING_PROFILE_PAGE_CTA_BLOCK_ADD_BUTTON';
export const BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_EDIT_IMAGE_BUTTON =
    'BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_EDIT_IMAGE_BUTTON';
export const BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_EDIT_BUTTON = 'BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_EDIT_BUTTON';
export const BUILDING_PROFILE_IMAGE_UPLOAD_SUCCESS = 'BUILDING_PROFILE_IMAGE_UPLOAD_SUCCESS';
export const BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_SETTING_BUTTON =
    'BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_SETTING_BUTTON';
export const BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_DELETE_BUTTON = 'BUILDING_ROOM_PROFILE_PAGE_CTA_BLOCK_DELETE_BUTTON';
export const BUILDING_PROFILE_PAGE_ROOMS_SECTION_FLOOR_COLUMN_TITLE =
    'BUILDING_PROFILE_PAGE_ROOMS_SECTION_FLOOR_COLUMN_TITLE';
export const BUILDING_PROFILE_PAGE_ROOMS_SECTION_SECTION_TAB_TITLE =
    'BUILDING_PROFILE_PAGE_ROOMS_SECTION_SECTION_TAB_TITLE';
export const BUILDING_PROFILE_PAGE_ROOMS_SECTION_ROOM_SHORTCUT = 'BUILDING_PROFILE_PAGE_ROOMS_SECTION_ROOM_SHORTCUT';
export const BUILDING_PROFILE_PAGE_ROOMS_SECTION_SQUARE_UNIT = 'BUILDING_PROFILE_PAGE_ROOMS_SECTION_SQUARE_UNIT';
export const BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_ELECTRICITY =
    'BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_ELECTRICITY';
export const BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_HOT_WATER =
    'BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_HOT_WATER';
export const BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_COLD_WATER =
    'BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_COLD_WATER';
export const BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_HEAT = 'BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB_SELECT_HEAT';
export const BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB = 'BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB';
export const BUILDING_PROFILE_PAGE_CHART_TAB = 'BUILDING_PROFILE_PAGE_CHART_TAB';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_COMMON_DEVICES =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_COMMON_DEVICES';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_NEW_DEVICES = 'BUILDING_PROFILE_PAGE_DEVICES_SECTION_NEW_DEVICES';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_MODEMS = 'BUILDING_PROFILE_PAGE_DEVICES_SECTION_MODEMS';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_USERS = 'BUILDING_PROFILE_PAGE_DEVICES_SECTION_USERS';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_NUMBER_LABEL =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_NUMBER_LABEL';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_PZPD_LABEL =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_PZPD_LABEL';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_PZPD_MODEL_LABEL =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_PZPD_MODEL_LABEL';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_MODEL_LABEL =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_MODEL_LABEL';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_DATE_LABEL =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_DEVICE_CARD_DATE_LABEL';
export const BUILDING_PROFILE_PAGE_DEVICES_SECTION_NOT_ACTIVE_DEVICE =
    'BUILDING_PROFILE_PAGE_DEVICES_SECTION_NOT_ACTIVE_DEVICE';
export const ROOM_PROFILE_PAGE_DEVICES_SECTION_EXISTING_DEVICES = 'ROOM_PROFILE_PAGE_DEVICES_SECTION_EXISTING_DEVICES';
export const ROOM_PROFILE_PAGE_FLOOR_INFO_TITLE = 'ROOM_PROFILE_PAGE_FLOOR_INFO_TITLE';
export const ROOM_PROFILE_PAGE_SECTION_INFO_TITLE = 'ROOM_PROFILE_PAGE_SECTION_INFO_TITLE';
export const ROOM_PROFILE_PAGE_SQUARE_INFO_TITLE = 'ROOM_PROFILE_PAGE_SQUARE_INFO_TITLE';
export const ROOM_EDIT_NOTIFICATION_SUCCESS = 'ROOM_EDIT_NOTIFICATION_SUCCESS';
export const ROOM_DELETE_NOTIFICATION_SUCCESS = 'ROOM_DELETE_NOTIFICATION_SUCCESS';
export const ROOM_FORM_EDIT_SUBMIT_BTN = 'ROOM_FORM_EDIT_SUBMIT_BTN';
export const ROOM_FORM_EDIT_TITLE = 'ROOM_FORM_EDIT_TITLE';
export const ROOM_FORM_CANCEL_BTN = 'ROOM_FORM_CANCEL_BTN';
export const ROOM_FORM_REQUIRED_ERROR = 'ROOM_FORM_REQUIRED_ERROR';
export const ROOM_PROFILE_PAGE_ACCOUNT_NUMBER_INFO_TITLE = 'ROOM_PROFILE_PAGE_ACCOUNT_NUMBER_INFO_TITLE';
export const ROOM_PROFILE_PAGE_DEVICES_SECTION_DISTRIBUTED_DEVICES =
    'ROOM_PROFILE_PAGE_DEVICES_SECTION_DISTRIBUTED_DEVICES';
export const NOTIFICATION_SUCCESS_DELETE_DEVICE = 'NOTIFICATION_SUCCESS_DELETE_DEVICE';
export const NOTIFICATION_SUCCESS_EDIT_DEVICE = 'NOTIFICATION_SUCCESS_EDIT_DEVICE';
export const BUILDING_ROOM_PROFILE_PAGE_DEVICES_SECTION_DEFAULT_UNIT_GCAL =
    'BUILDING_ROOM_PROFILE_PAGE_DEVICES_SECTION_DEFAULT_UNIT_GCAL';
export const BUILDING_ROOM_PROFILE_PAGE_DEVICES_SECTION_DEFAULT_UNIT_WH =
    'BUILDING_ROOM_PROFILE_PAGE_DEVICES_SECTION_DEFAULT_UNIT_WH';
export const BUILDING_ROOM_PROFILE_PAGE_DEVICES_SECTION_DEFAULT_UNIT_M3 =
    'BUILDING_ROOM_PROFILE_PAGE_DEVICES_SECTION_DEFAULT_UNIT_M3';
export const ADD_BUILDING_ROOM_MODAL_TITLE = 'ADD_BUILDING_ROOM_MODAL_TITLE';
export const ADD_BUILDING_ROOM_MODAL_CANCEL = 'ADD_BUILDING_ROOM_MODAL_CANCEL';
export const ADD_BUILDING_ROOM_MODAL_SUBMIT = 'ADD_BUILDING_ROOM_MODAL_SUBMIT';
export const ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_LABEL = 'ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_LABEL';
export const ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_PLACEHOLDER =
    'ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_PLACEHOLDER';
export const ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_LABEL = 'ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_LABEL';
export const ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_PLACEHOLDER = 'ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_PLACEHOLDER';
export const ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_LABEL = 'ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_LABEL';
export const ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_PLACEHOLDER = 'ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_PLACEHOLDER';
export const ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_LABEL = 'ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_LABEL';
export const ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_PLACEHOLDER =
    'ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_PLACEHOLDER';
export const ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_LABEL = 'ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_LABEL';
export const ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_PLACEHOLDER = 'ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_PLACEHOLDER';
export const ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_LABEL = 'ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_LABEL';
export const ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_PLACEHOLDER =
    'ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_PLACEHOLDER';
export const BUILDING_FORM_ITEM_LABEL_HEAT = 'BUILDING_FORM_ITEM_LABEL_HEAT';
export const INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_LABEL = 'INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_PLACEHOLDER = 'INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_FEEDBACK_RULE_1 =
    'INDICATOR_MODAL_FORM_MANUAL_ID_FIELD_FEEDBACK_RULE_1';
export const INDICATOR_MODAL_FORM_FACTORY_ID_FIELD_LABEL = 'INDICATOR_MODAL_FORM_FACTORY_ID_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_FACTORY_ID_FIELD_PLACEHOLDER = 'INDICATOR_MODAL_FORM_FACTORY_ID_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_ADDITIONAL_MANUAL_ID_FIELD_LABEL =
    'INDICATOR_MODAL_FORM_ADDITIONAL_MANUAL_ID_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_ADDITIONAL_MANUAL_ID_FIELD_PLACEHOLDER =
    'INDICATOR_MODAL_FORM_ADDITIONAL_MANUAL_ID_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_DEVICE_TYPE_FIELD_LABEL = 'INDICATOR_MODAL_FORM_DEVICE_TYPE_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_DEVICE_TYPE_FIELD_PLACEHOLDER = 'INDICATOR_MODAL_FORM_DEVICE_TYPE_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_GROUPING_TYPE_FIELD_LABEL = 'INDICATOR_MODAL_FORM_GROUPING_TYPE_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_GROUPING_TYPE_FIELD_PLACEHOLDER =
    'INDICATOR_MODAL_FORM_GROUPING_TYPE_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_PARENT_DEVICE_ID_FIELD_LABEL = 'INDICATOR_MODAL_FORM_PARENT_DEVICE_ID_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_PARENT_DEVICE_ID_FIELD_PLACEHOLDER =
    'INDICATOR_MODAL_FORM_PARENT_DEVICE_ID_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_SERVICE_DATE_FIELD_LABEL = 'INDICATOR_MODAL_FORM_SERVICE_DATE_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_SERVICE_DATE_FIELD_PLACEHOLDER =
    'INDICATOR_MODAL_FORM_SERVICE_DATE_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_ROOM_ID_FIELD_LABEL = 'INDICATOR_MODAL_FORM_ROOM_ID_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_ROOM_ID_FIELD_PLACEHOLDER = 'INDICATOR_MODAL_FORM_ROOM_ID_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_PZPD_FIELD_LABEL = 'INDICATOR_MODAL_FORM_PZPD_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_PZPD_FIELD_PLACEHOLDER = 'INDICATOR_MODAL_FORM_PZPD_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_DEVICE_MODEL_ID_FIELD_LABEL = 'INDICATOR_MODAL_FORM_DEVICE_MODEL_ID_FIELD_LABEL';
export const INDICATOR_MODAL_FORM_DEVICE_MODEL_ID_FIELD_PLACEHOLDER =
    'INDICATOR_MODAL_FORM_DEVICE_MODEL_ID_FIELD_PLACEHOLDER';
export const INDICATOR_MODAL_FORM_CANCEL_BUTTON = 'INDICATOR_MODAL_FORM_CANCEL_BUTTON';
export const INDICATOR_MODAL_ADD_FORM_SUBMIT_BUTTON = 'INDICATOR_MODAL_ADD_FORM_SUBMIT_BUTTON';
export const INDICATOR_MODAL_EDIT_FORM_SUBMIT_BUTTON = 'INDICATOR_MODAL_EDIT_FORM_SUBMIT_BUTTON';
export const INDICATOR_MODAL_ADD_FORM_TITLE = 'INDICATOR_MODAL_ADD_FORM_TITLE';
export const INDICATOR_MODAL_EDIT_FORM_TITLE = 'INDICATOR_MODAL_EDIT_FORM_TITLE';
export const NOTIFICATION_SUCCESS_ADD = 'NOTIFICATION_SUCCESS_ADD';
export const DIAGNOSTIC_PAGE_ROOM_NUMBER_TABLE_COLUMN_TITLE = 'DIAGNOSTIC_PAGE_ROOM_NUMBER_TABLE_COLUMN_TITLE';
export const DIAGNOSTIC_PAGE_MODEL_TABLE_COLUMN_TITLE = 'DIAGNOSTIC_PAGE_MODEL_TABLE_COLUMN_TITLE';
export const DIAGNOSTIC_PAGE_RED_COLOR_VALUE_STATUS = 'DIAGNOSTIC_PAGE_RED_COLOR_VALUE_STATUS';
export const DIAGNOSTIC_PAGE_ORANGE_COLOR_VALUE_STATUS = 'DIAGNOSTIC_PAGE_ORANGE_COLOR_VALUE_STATUS';
export const DIAGNOSTIC_PAGE_WHITE_COLOR_VALUE_STATUS = 'DIAGNOSTIC_PAGE_WHITE_COLOR_VALUE_STATUS';
export const DIAGNOSTIC_PAGE_GREEN_COLOR_VALUE_STATUS = 'DIAGNOSTIC_PAGE_GREEN_COLOR_VALUE_STATUS';
export const DIAGNOSTIC_PAGE_GROUPING_TYPE_TABLE_COLUMN_TITLE = 'DIAGNOSTIC_PAGE_GROUPING_TYPE_TABLE_COLUMN_TITLE';
export const DIAGNOSTIC_PAGE_DEVICE_TYPE_TABLE_COLUMN_TITLE = 'DIAGNOSTIC_PAGE_DEVICE_TYPE_TABLE_COLUMN_TITLE';
export const PROFILE_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_CITY_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER = 'PROFILE_PAGE_EDIT_USER_FORM_CITY_FIELD_PLACEHOLDER';
export const PROFILE_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_CITY_FIELD_ERROR_MESSAGE';
export const HOUR_PERIOD_KEY = 'HOUR_PERIOD_KEY';
export const DAY_PERIOD_KEY = 'DAY_PERIOD_KEY';
export const MONTH_PERIOD_KEY = 'MONTH_PERIOD_KEY';
export const ENERGY_PARAMETER_VALUE = 'ENERGY_PARAMETER_VALUE';
export const HEAT_PARAMETER_VALUE = 'HEAT_PARAMETER_VALUE';
export const HEAT_POWER_PARAMETER_VALUE = 'HEAT_POWER_PARAMETER_VALUE';
export const HEAT_LOSS_PARAMETER_VALUE = 'HEAT_LOSS_PARAMETER_VALUE';
export const SERVING_TEMPERATURE_PARAMETER_VALUE = 'SERVING_TEMPERATURE_PARAMETER_VALUE';
export const PROCESSING_TEMPERATURE_PARAMETER_VALUE = 'PROCESSING_TEMPERATURE_PARAMETER_VALUE';
export const DOWNTIME_PARAMETER_VALUE = 'DOWNTIME_PARAMETER_VALUE';
export const OPERATION_TIME_PARAMETER_VALUE = 'OPERATION_TIME_PARAMETER_VALUE';
export const VOLUME_PARAMETER_VALUE = 'VOLUME_PARAMETER_VALUE';
export const PROFILE_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE =
    'PROFILE_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_ERROR_MESSAGE';
export const PROFILE_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL = 'PROFILE_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_LABEL';
export const PROFILE_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER =
    'PROFILE_PAGE_EDIT_USER_FORM_APARTMENT_FIELD_PLACEHOLDER';
export const BUILDING_PROFILE_DEVICE_TABLE_NUMBER_COLUMN_TITLE = 'BUILDING_PROFILE_DEVICE_TABLE_NUMBER_COLUMN_TITLE';
export const BUILDING_PROFILE_DEVICE_TABLE_PZPD_COLUMN_TITLE = 'BUILDING_PROFILE_DEVICE_TABLE_PZPD_COLUMN_TITLE';
export const BUILDING_PROFILE_DEVICE_TABLE_VALUE_COLUMN_TITLE = 'BUILDING_PROFILE_DEVICE_TABLE_VALUE_COLUMN_TITLE';
export const BUILDING_PROFILE_DEVICE_TABLE_UNIT_COLUMN_TITLE = 'BUILDING_PROFILE_DEVICE_TABLE_UNIT_COLUMN_TITLE';
export const BUILDING_PROFILE_DEVICE_TABLE_TYPE_COLUMN_TITLE = 'BUILDING_PROFILE_DEVICE_TABLE_TYPE_COLUMN_TITLE';
export const BUILDING_PROFILE_ROOM_NUMBERS_UNIT_COLUMN_TITLE = 'BUILDING_PROFILE_ROOM_NUMBERS_UNIT_COLUMN_TITLE';
export const CHART_TABLE_DATA_VALUE = 'CHART_TABLE_DATA_VALUE';
export const CHART_FILTER_DIFFERENCE_VALUE = 'CHART_FILTER_DIFFERENCE_VALUE';
export const CHART_FILTER_FALLING_VALUE = 'CHART_FILTER_FALLING_VALUE';
export const CHART_Q1_UNIFIED_VALUE = 'CHART_Q1_UNIFIED_VALUE';
export const CHART_Q2_VALUE = 'CHART_Q2_VALUE';
export const CHART_V_VALUE = 'CHART_V_VALUE';
export const CHART_P_VALUE = 'CHART_P_VALUE';
export const CHART_G_VALUE = 'CHART_G_VALUE';
export const CHART_T1_VALUE = 'CHART_T1_VALUE';
export const CHART_T2_VALUE = 'CHART_T2_VALUE';
export const CHART_dT_VALUE = 'CHART_dT_VALUE';
export const CHART_Bd_VALUE = 'CHART_Bd_VALUE';
export const CHART_Fd_VALUE = 'CHART_Fd_VALUE';
export const CHART_Pd_VALUE = 'CHART_Pd_VALUE';
export const CHART_E_VALUE = 'CHART_E_VALUE';
export const INFO_MODAL_BUTTON_TEXT = 'INFO_MODAL_BUTTON_TEXT';
export const CONFIRM_MODAL_BUTTON_TEXT_SUBMIT = 'CONFIRM_MODAL_BUTTON_TEXT_SUBMIT';
export const CONFIRM_MODAL_BUTTON_TEXT_CANCEL = 'CONFIRM_MODAL_BUTTON_TEXT_CANCEL';
export const CONFIRM_MODAL_DEFAULT_TITLE_TEXT = 'CONFIRM_MODAL_DEFAULT_TITLE_TEXT';
export const REPORT_TYPE_GROUP = 'REPORT_TYPE_GROUP';
export const REPORT_TYPE_BUILDING = 'REPORT_TYPE_BUILDING';
export const REPORT_TYPE_INDIVIDUAL = 'REPORT_TYPE_INDIVIDUAL';
export const REPORT_TYPE_CURRENT = 'REPORT_TYPE_CURRENT';
