import {useSelector} from 'react-redux';

import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {makeSelectorDeviceGroups} from '../../../../state/selectors/device';
import {makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {makeSelectKyivEnergoSettingsData} from '../../../../state/selectors/services';
import helpers from '../../../../utils/helpers';
import regexUtils from '../../../../utils/regexUtils';

const getFormContent = () => {
    const localizedStrings = getLocalizedStrings();

    const settings = useSelector(makeSelectKyivEnergoSettingsData()) || {};

    const initialValues = {
        ...settings,

        [formInputNames.COMPANY]: settings?.organizationID,
        app: settings?.app || '',
        secretKey: settings?.secretKey || '',
        baseUrl: settings?.baseUrl || '',
        getActivePersonalAccountsUrl: settings?.getActivePersonalAccountsUrl || '',
        sendCountersDataUrl: settings?.sendCountersDataUrl || '',
        dayOfMonth: settings?.dayOfMonth ? String(settings?.dayOfMonth) : '',
        [formInputNames.DEVICE_GROUPS_TYPES]: settings?.indicators,
    };

    const formFields = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            name: formInputNames.COMPANY,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },

        {
            key: helpers.guid(),
            required: true,
            name: 'app',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_ERROR],
        },

        {
            key: helpers.guid(),
            required: true,
            name: 'secretKey',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_ERROR],
        },

        {
            key: helpers.guid(),
            required: true,
            name: 'baseUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            row: true,
            maxLength: 100,
            name: 'getActivePersonalAccountsUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            row: true,
            maxLength: 100,
            name: 'sendCountersDataUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            maxLength: 5,
            name: 'dayOfMonth',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_ERROR],
            pattern: regexUtils.DAY_OF_MONTH_REGEX,
        },

        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_GROUPS_TYPES,
            fieldLabel: 'value',
            fieldValue: 'key',
            formItemType: formItemTypes.MULTIPLE_SELECT,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_ERROR],
            withInitialRequest: true,
            requestOptions: {
                selectRequestData: makeSelectorDeviceGroups,
            },
        },
    ];

    return {
        formFields,
        initialValues,
    };
};

export default {
    getFormContent,
};
